@import '../../static/scss/colours';
@import '../../static/scss/mixin';

.layoutManagement {
  height: 0;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.marketsPage {
  flex-grow: 1;
  display: flex;
  overflow: scroll;
  .pageWrap {
    width: 0;
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    position: relative;
    .assetClassWrap {
      height: 90px;
      flex: 0 0 90px;
      border-bottom: 1px solid $border-color;
      display: flex;
      .profileSearch {
        display: flex;
        align-items: center;
        .profile {
          height: 100%;
          width: unset;
          display: flex;
          justify-content: center;
          align-items: center;
          padding-left: 80px;
          img {
            height: 48px;
            width: 48px;
            transition: all 400ms ease;
            margin-right: 20px;
            border-radius: 24px;
          }
          &:hover {
            img {
              transform: scale(1.1);
            }
          }
        }
        .assetFinder {
          height: 40px;
          background: rgba(238, 238, 238, 0.42);
          border: 1px solid $border-color;
          border-radius: 20px;
          margin: 0;
          margin-left: 2rem;
          padding: 0 25px;
          width: 200px;
          display: flex;
          align-items: center;
          img {
            height: 18px;
            margin-right: 5px;
          }
          input {
            width: 0;
            flex: 1;
            height: 100%;
            background: none;
            border: none;
          }
        }
      }
      .assetClass {
        width: 0;
        flex: 1;
        display: flex;
        overflow-x: scroll;
        .assetClassCard {
          display: flex;
          align-items: center;
          padding: 0 80px;
          opacity: 0.25;
          transition: all 400ms ease;
          img {
            transition: all 400ms ease;
            height: 35%;
            width: auto;
            transform-origin: center center;
          }
          &:hover {
            opacity: 0.7;
            img {
              transform: scale(1.08);
            }
          }
          &.true {
            opacity: 1;
            img {
              transform: scale(1.2);
            }
          }
        }
      }
      .streamOpen {
        background: white;
        width: 60px;
        height: 60px;
        position: fixed;
        bottom: 60px;
        right: 60px;
        z-index: 4;
        border-radius: 50%;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.4);
        display: flex;
        justify-content: center;
        align-items: center;
        transition: all 400ms ease;
        img,
        svg {
          height: 45%;
        }
        &:hover {
          img {
            transform: scale(1.1);
          }
        }
        &.true {
          right: calc(35% + 60px);
        }
      }
    }
    .pageContent {
      height: 0;
      flex: 1;
      display: flex;
      .tableArea {
        width: 0;
        flex: 1;
        display: flex;
        flex-direction: column;
        .topBlank {
          flex: 0 0 30%;
          display: flex;
          .assetClassCard {
            padding: 0 80px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            margin-right: 5%;
            .assetLogo {
              height: 25%;
              margin-bottom: 30px;
            }
            .assetActions {
              display: flex;
              .action {
                display: flex;
                align-items: center;
                padding: 0 26px;
                height: 34px;
                border-radius: 17px;
                font-size: 13px;
                font-weight: 600;
                border: 1px solid $border-color;
                margin-right: 8px;
                @include btn-animate(whitesmoke);
              }
            }
          }
          .cardList {
            flex: 1;
            overflow: scroll;
            display: flex;
            align-items: center;
            // margin-right: 80px;
            padding: 0 2rem;
            .card {
              // padding: 2rem 1rem 2rem 1rem;
              flex: 0 0 22%;
              height: 60%;
              border: 1px solid $border-color;
              margin-right: 2%;
              border-radius: 20px;
              @include btn-animate;
              &:hover {
                // padding: 2rem;
              }

              & > img {
                width: 100%;
                height: 100%;
              }
            }
            .cardWrapper {
              flex-direction: column;
              height: 100%;
              justify-content: space-between;
            }
            .bondHead {
              font-weight: 700;
              font-size: 16px;
              line-height: 18px;
              margin-bottom: 5px;
            }
            .bondSubHead {
              font-weight: 400;
              font-size: 10px;
              line-height: 10px;
            }
            .bottomContent {
              .values {
                font-weight: 700;
                font-size: 14px;
                line-height: 17px;
                margin-bottom: 5px;
              }
              .leftLabels {
                font-weight: 400;
                font-size: 10px;
                line-height: 10px;
                text-align: left;
              }
              .rightLabel {
                font-weight: 400;
                font-size: 10px;
                line-height: 10px;
                text-align: right;
              }
            }
          }
        }
      }
    }
  }
  .sidebarArea {
    flex: 0 0 40%;
    max-width: 0%;
    transition: all 400ms ease;
    display: flex;
    flex-direction: column;
    border-left: 1px solid $border-color;
    overflow: hidden;
    max-height: 100%;
    position: fixed;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 5;
    width: 35%;
    background: white;
    .sidebarContent {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      .fullLogo {
        width: 75%;
        height: auto;
      }
    }
    .sidebarFooter {
      flex: 0 0 90px;
      border-top: 1px solid $border-color;
      display: flex;
      .footerMenu {
        flex: 0 0 20%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        * {
          transition: all 400ms ease;
        }
        img {
          height: 35px;
        }
        span {
          font-size: 0;
          font-weight: 600;
          line-height: 1.8;
          margin: 0 -10px;
          text-align: center;
        }
        &.true {
          img {
            height: 45px;
          }
          span {
            font-size: 14px;
          }
        }
        &.disable {
          opacity: 0.25;
        }
      }
    }
    &.true {
      max-width: 40%;
    }
  }
}

.marketTable {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 0 2rem;
  overflow: scroll;
  color: #18191d;
  .header {
    border-top: 1px solid $border-color;
    border-bottom: 1px solid $border-color;
    height: 50px;
    background: white;
    padding: 0 40px 0 20px;
    font-size: 12px;
    font-weight: 600;
    justify-content: space-between;
    align-items: center;
    top: 0;
    position: sticky;
    z-index: 1;
    display: flex;
    .btnFilter {
      position: absolute;
      height: 15px;
      width: 15px;
      right: 4%;
      top: 0;
      bottom: 0;
      margin: auto;
      cursor: pointer;
      transition: all 400ms ease;
      &.true {
        transform: rotate(180deg);
      }
    }
    .no,
    .assets,
    .price,
    .roiPercent,
    .roiInr,
    .marCap,
    .volume,
    .supply {
      display: flex;
      align-items: center;
      height: 50px;
    }
    .price,
    .roiPercent,
    .roiInr,
    .marCap,
    .volume,
    .supply {
      justify-content: flex-start;
    }
    .assets {
      flex: 0 0 30%;
    }
    .price {
      flex: 0 0 15%;
    }
    .roiPercent {
      flex: 0 0 12%;
    }
    .roiInr {
      flex: 0 0 18%;
    }
    .marCap {
      flex: 0 0 25%;
    }
    .volume,
    .supply {
      flex: 0 0 25%;
    }
    &.active {
      border-top: 1px solid $primary-color;
      background: $primary-color;
      color: white;
      margin: 0 -80px;
      padding: 0 120px 0 100px;
    }
    &.true {
      .assets {
        flex: 0 0 17%;
      }
      .volume,
      .supply {
        display: flex;
      }
      .price {
        flex: 0 0 13%;
      }
      .roiPercent {
        flex: 0 0 10%;
      }
      .roiInr {
        flex: 0 0 13%;
      }
      .marCap {
        flex: 0 0 18%;
      }
      .volume {
        flex: 0 0 14%;
      }
      .supply {
        flex: 0 0 15%;
      }
    }
  }
  .filtersWrap {
    flex: 0 0 75px;
    border-bottom: 1px solid $border-color;
    padding-left: 20px;
    display: flex;
    .filters {
      width: 0;
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      .filter {
        border-radius: 8px;
        padding: 0 15px;
        height: 36px;
        border: 1px solid $border-color;
        display: flex;
        font-size: 22px;
        margin-right: 20px;
        align-items: center;
        .label {
          font-size: 13px;
          padding-right: 15px;
        }
        .value {
          display: flex;
          align-items: center;
          height: 100%;
          border-left: 1px solid $border-color;
          padding-left: 15px;
          @include btn-animate;
        }
        img {
          height: 18px;
          width: 18px;
          margin-right: 10px;
        }
        span {
          font-size: 14px;
          font-weight: 600;
        }
      }
    }
    .input {
      display: none;
      background: none;
      border: none;
      border-left: 1px solid $border-color;
      flex: 0 0 28%;
      padding: 0 60px;
    }
  }
  .marketsList {
    flex: 1;
    display: flex;
    flex-direction: column;
    .marketItem {
      flex: 0 0 120px;
      display: flex;
      padding: 0 40px 0 20px;
      font-size: 15px;
      font-weight: 600;
      border-bottom: 1px solid $border-color;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;
      transition: all 400ms ease;
      @include btn-animate(whitesmoke);
      &:hover {
        background: whitesmoke;
      }
      .no,
      .assets,
      .price,
      .roiPercent,
      .roiInr,
      .marCap,
      .volume,
      .supply {
        display: flex;
        align-items: center;
        span {
          font-weight: 400;
        }
      }
      .price,
      .roiPercent,
      .roiInr,
      .marCap,
      .volume,
      .supply {
        justify-content: flex-start;
      }
      .assets {
        flex: 0 0 30%;
        display: flex;
        img {
          height: 25px;
          width: 25px;
          margin-right: 8px;
        }
        .name {
          font-weight: 600;
        }
        .names {
          flex: 1;
          line-height: 1;
          font-size: 17px;
          .name {
            font-weight: 600;
          }
          .symbol {
            margin-top: 2px;
            font-size: 10px;
            display: flex;
            align-items: center;
            img {
              height: 11px;
              width: 11px;
              margin: 0 3px;
            }
            strong {
              font-weight: 700;
            }
          }
        }
      }

      .price {
        flex: 0 0 15%;
      }
      .roiPercent {
        flex: 0 0 12%;
        color: #4caf50;
        &.true {
          color: rgb(238, 50, 50);
        }
      }
      .roiInr {
        flex: 0 0 18%;
        color: #4caf50;
        &.true {
          color: rgb(238, 50, 50);
        }
      }
      .marCap {
        flex: 0 0 25%;
      }
      .volume,
      .supply {
        flex: 0 0 25%;
      }
      &.true {
        .volume,
        .supply {
          display: flex;
        }
        .assets {
          flex: 0 0 17%;
        }
        .price {
          flex: 0 0 13%;
        }
        .roiPercent {
          flex: 0 0 10%;
        }
        .roiInr {
          flex: 0 0 13%;
        }
        .marCap {
          flex: 0 0 18%;
        }
        .volume {
          flex: 0 0 14%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;
          .value {
            font-size: 17px;
          }
          .symbol {
            font-size: 12px;
          }
        }
        .supply {
          flex: 0 0 15%;
        }
      }
    }
    .marketItemBond {
      flex: 0 0 160px;
      display: flex;
      padding: 0 40px 0 20px;
      font-size: 19px;
      font-weight: 600;
      border-bottom: 1px solid $border-color;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;
      transition: all 400ms ease;
      overflow: visible;

      .no,
      .assets,
      .price,
      .roiPercent,
      .roiInr,
      .marCap,
      .volume,
      .supply {
        display: flex;
        align-items: center;
        line-height: 1.1;
        span {
          font-weight: 400;
          font-size: 15px;
        }
      }
      .price,
      .roiPercent,
      .roiInr,
      .marCap,
      .volume,
      .supply {
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
      }
      .assets {
        flex: 0 0 30%;
        display: flex;
        justify-content: flex-start;
        img {
          height: 45px;
          width: 45px;
          margin-right: 8px;
        }
        .names {
          flex: 1;
          font-size: 17px;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: center;
        }
      }

      .price {
        flex: 0 0 15%;
      }
      .roiPercent {
        flex: 0 0 12%;
        color: #4caf50;
        &.true {
          color: rgb(238, 50, 50);
        }
      }
      .roiInr {
        flex: 0 0 18%;
        color: #4caf50;
        &.true {
          color: rgb(238, 50, 50);
        }
      }
      .marCap {
        flex: 0 0 25%;
      }
      .volume,
      .supply {
        flex: 0 0 25%;
      }
      &.true {
        .volume,
        .supply {
          display: flex;
        }
        .assets {
          flex: 0 0 17%;
        }
        .price {
          flex: 0 0 13%;
        }
        .roiPercent {
          flex: 0 0 10%;
        }
        .roiInr {
          flex: 0 0 13%;
        }
        .marCap {
          flex: 0 0 18%;
        }
        .volume {
          flex: 0 0 14%;
        }
        .supply {
          flex: 0 0 15%;
        }
      }
    }
  }
}

.bondContractsWrapper {
  overflow: hidden;
  height: 100%;
  flex: 1 1;
  display: flex;
  flex-direction: column;
  position: relative;
}

.bondContractImg {
  min-height: 90px;
  max-height: 90px;
  flex: 0 0 90px;
  border-bottom: 1px solid #eeeeee;
  display: flex;
  justify-content: center;
  align-items: center;
}

.bondFilter {
  min-height: 5%;
  max-height: 5%;
  border-bottom: 1px solid #eee;
  display: flex;
  align-items: center;
  padding: 0 40px;
  justify-content: space-between;
  font-size: 11px;
  font-weight: 600;
}

.bondContract {
  min-height: 75%;
  max-height: 75%;
  overflow-y: scroll;
  height: 100vh;
  .bondData {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 3rem;
    .label {
      font-size: 11px;
    }
    .subLabel {
      font-weight: 500;
    }
    .value {
      font-weight: 700;
    }
  }
}

.pill {
  background: #ffffff;
  border: 0.5px solid #ebebeb;
  border-radius: 15px;
  padding: 5px;
  opacity: 0.25;
  font-weight: 700;
  font-size: 10px;
  &.true {
    opacity: 1;
    cursor: pointer;
    &:hover {
      transition: all 400ms ease;
      transform: scale(1.1);
    }
  }
}

.actionWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
  padding-left: 2rem;
  padding-right: 2rem;
}

.pillAction {
  background: #ffffff;
  border: 0.5px solid #ebebeb;
  border-radius: 25px;
  padding: 10px;
  opacity: 0.25;
  font-weight: 700;
  font-size: 10px;
  &.true {
    opacity: 1;
    cursor: pointer;
    &:hover {
      background-color: black;
      color: white;
      transition: all 400ms ease;
      transform: scale(1.1);
    }
  }
  margin: 1rem;
}

.coinData {
  text-align: left;
  line-height: normal;
  margin-left: 5px;
}

.bankerData {
  margin-top: 8px;
}

.rightElement {
  text-align: right;
}

.sidebarSpacer {
  border-bottom: 0.5px solid #e5e5e5;
  width: 90%;
  margin: 0 auto;
}

.splitSpacer {
  border-bottom: 0.5px solid #e5e5e5;
  width: 100%;
  margin: 0 auto;
}

.bondSidebarFooter {
  flex: 0 0 90px;
  min-height: 10%;
  max-height: 10%;
  border-top: 1px solid $border-color;
  display: flex;
  .footerMenu {
    flex: 0 0 20%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    * {
      transition: all 400ms ease;
    }
    img {
      height: 35px;
    }
    span {
      font-size: 0;
      font-weight: 600;
      line-height: 1.8;
      margin: 0 -10px;
      text-align: center;
    }
    &.true {
      img {
        height: 45px;
      }
      span {
        font-size: 14px;
      }
    }
    &.disable {
      opacity: 0.25;
    }
  }
}
