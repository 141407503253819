.influencemarket {
  width: 100%;
  overflow-y: hidden;
  position: absolute;
  inset: 0;
  overflow-x: hidden;

  .influencemarket-navbar {
    width: 100%;
    height: 8%;
    border-bottom: 0.5px solid #e7e7e7;
    display: flex;
    align-items: center;
    padding: 1rem 3rem;
    position: relative;

    .influencemarket-icon {
      width: 15%;
      height: 100%;
      display: flex;
      align-items: center;
      gap: 5px;
      font-size: 1.35rem;
      font-weight: 650;
      color: #18191d;
      img {
        width: 80%;
        height: 80%;
      }
    }

    .search-influencemarket {
      width: 42%;
      height: calc(100% - 2rem);
      border: 0.5px solid #e7e7e7;
      background: white;
      border-radius: 25px;
      padding: 0.5rem 2rem;
      display: flex;
      align-items: center;
      position: absolute;
      transform: translate(-50%, -50%);
      top: 50%;
      left: 50%;

      .all {
        width: 32%;
        height: 100%;
        border-right: 3px solid #e7e7e7;
        display: flex;
        align-items: center;
        font-size: 1rem;
        color: #18191d;
        font-weight: 700;
        opacity: 0.25;
        padding-right: 20px;
        justify-content: space-between;
        p {
          margin-bottom: 0;
        }
        .arroww {
          display: flex;
          align-items: center;
          img {
            width: 15px;
            height: 15px;
            cursor: pointer;
          }
        }
      }
      .im {
        width: 100%;
        height: 100%;
        // padding-left: 1rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 1rem;
        color: #18191d;
        font-weight: 700;
        // opacity: 0.25;
        gap: 1rem;
        input {
          border: none;
          font-size: 1rem;
          color: #18191d;
          font-weight: 700;
          background: transparent;
        }
        img {
          width: 16px;
          height: 16px;
        }
      }
    }

    .influencemarket-listBtn {
      padding: 0.5rem 1.5rem;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #9fd6df;
      border: 0.5px solid #9fd6df;
      border-radius: 25px;
      cursor: pointer;
      font-weight: 700;
      color: white;
      font-size: 1rem;
      margin-left: 20vw;
      white-space: nowrap;
    }
  }

  .w-space {
    width: 100%;
    height: 5.5%;
    padding: 0 3rem;
    border-bottom: 0.5px solid #e7e7e7;
    .optionss {
      width: 30%;
      height: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 5px 0;
      .each-option-selected {
        background: rgba(229, 229, 229, 0.25);
        font-weight: 600;
        padding: 5px 1.75rem;
        border-radius: 25px;
      }
      .each-option {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        p {
          margin-bottom: 0;
          color: #18191d;
          font-size: 0.9rem;
        }
        div {
          display: flex;
          gap: 5px;
          transition: all ease 0.3s;
          cursor: pointer;
          &:hover {
            scale: 1.1;
          }
        }
      }
      .top-icons {
        display: flex;
        align-items: center;
        height: 100%;
        div {
          height: 100%;
          padding: 0 2rem;
          display: flex;
          border-right: 0.25px solid #e7e7e7;
          align-items: center;
          img {
            height: 50%;
          }
        }
      }
    }
  }

  .influencemarket-content {
    width: 100%;
    height: calc(100% - 13.5%);
    display: flex;

    .left-ai-container {
      // width: 67%;
      width: -webkit-fill-available;
      height: 100%;
      background: #ffffff;
      // background: rgba(237, 237, 237, 0.37);
      padding: 2.5rem 2.5rem 0;

      .ai-card {
        width: 100%;
        height: 19rem;
        background: #ffffff;
        border-radius: 15px;
        display: flex;
        flex-direction: column;
        padding: 1.5rem 2.25rem 2.25rem;
        color: #18191d;
        gap: 2rem;
        margin-top: 2rem;
        background: #ffffff;
        border: 0.5px solid #e7e7e7;
        border-radius: 15px;

        .top-data {
          width: 100%;
          height: 70%;
          min-height: 70%;
          max-height: 70%;
          display: flex;

          .imgg-div {
            width: 18%;
            height: 92%;
            background: #ffffff;
            border: 0.5px solid #e7e7e7;
            border-radius: 15px;
            display: flex;
            justify-content: center;
            align-items: center;
            // border: 1px solid;

            & > img {
              width: 50%;
              max-height: 100%;
              object-fit: contain;
            }
          }

          .top-rightt-divv {
            width: 82%;
            padding-left: 1.5rem;
            display: flex;
            flex-direction: column;
            gap: 1.5rem;
            // justify-content: space-between;

            .top-txt {
              display: flex;
              width: 100%;
              justify-content: space-between;

              .txtt1 {
                font-size: 1.3rem;
                font-weight: 600;
              }

              .top-right-txt {
                display: flex;
                width: 43%;
                justify-content: space-between;
                align-items: center;
                position: relative;
                gap: 5px;

                // & > :nth-child(1) {
                //   // padding-right: 1.3rem;
                //   // border-right: 1px solid #e7e7e7;
                // }

                & > div {
                  width: 50%;
                  white-space: nowrap;
                }

                & > .center-line {
                  width: 1px;
                  height: 100%;
                  background: #e7e7e7;
                  position: absolute;
                  top: 50%;
                  left: 41.5%;
                  transform: translate(-50%, -50%);
                }

                .gs-btn {
                  color: #ffffff;
                  background: #9fd6df;
                  padding: 0.7rem 2rem;
                  border-radius: 10px;
                  height: 2.75rem;
                  font-size: 0.8rem;
                  font-weight: 600;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  cursor: pointer;
                  transition: all ease 0.3s;

                  &:hover {
                    scale: 1.07;
                  }
                }
              }
            }

            .bottom-txt {
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 3; /* number of lines to show */
              line-clamp: 3;
              -webkit-box-orient: vertical;
            }
          }
        }

        .bottom-data {
          width: 100%;
          height: 30%;
          display: flex;
          align-items: flex-end;
          justify-content: space-between;
          font-size: 0.8rem;
          font-weight: 300;
          padding-right: 2rem;

          .txtt2 {
            color: #04af76;
            font-size: 1.25rem;
            font-weight: 650;
          }

          .txtt3 {
            font-size: 1.35rem;
            font-weight: 650;
          }
        }
      }
    }

    .right-ai-container {
      // width: 30%;
      // max-width: 550px;
      height: 100%;
      border-right: 0.5px solid #e7e7e7;
      background: #ffffff;

      .right-ai-items {
        height: 100%;
        padding: 1.5rem 0;
        overflow-y: scroll;
        padding-top: 0;

        .filterItemR {
          padding: 2rem 3.5rem;
          font-size: 1rem;
          font-weight: 500;
          color: #18191d;
          border-bottom: 0.5px solid #ebebeb;

          & > div {
            display: flex;
            justify-content: space-between;
            align-items: center;
            // cursor: pointer;

            .btn {
              padding: 0.6rem 0.75rem;
              background: rgba(237, 237, 237, 0.37);
              border: 0.5px solid rgba(237, 237, 237, 0.37);
              border-radius: 35px;
              font-weight: 500;
              cursor: pointer;
              transition: all ease-in 0.4s;
              min-width: 35%;
              width: max-content;
              // max-width: 50%;

              &:hover {
                font-weight: 700;
              }
            }
          }

          .filterItemOptions {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 1.5rem;
            margin-top: 1.5rem;

            & > div {
              background: rgba(255, 255, 255, 0.37);
              border: 1px solid #ededed;
              border-radius: 5px;
              color: #18191d;
              padding: 0.5rem;
              cursor: pointer;
              transition: all ease-in 0.4s;
              overflow-x: hidden;
              text-align: center;

              &:hover {
                scale: 1.1;
              }
            }
          }
        }
      }

      .filterOption {
        padding: 2rem;
        width: 100%;
        height: 100%;

        .headerTxt {
          font-size: 1.5rem;
          font-weight: 500;
          width: 100%;
          height: 3rem;
          display: flex;
          align-items: center;
        }

        .scrollableDiv {
          padding: 2rem 0;
          display: flex;
          flex-direction: column;
          grid-gap: 1rem;
          gap: 1rem;
          width: 100%;
          height: calc(100% - 11.5rem);
          overflow-y: scroll;

          .eachDiv {
            width: 100%;
            border: 0.5px solid #e5e5e5;
            border-radius: 10px;
            min-height: 4rem;
            font-weight: 500;
            display: flex;
            align-items: center;
            padding: 0 1.5rem;
            cursor: pointer;
            transition: all ease 0.2s;

            &:hover {
              transform: scale(1.05);
            }
          }
        }

        .BtnsDiv {
          width: 100%;
          height: 8.5rem;
          display: flex;
          flex-direction: column;
          justify-content: space-between;

          div {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 25px;
            cursor: pointer;
            transition: all ease 0.2s;
            color: #ffffff;
            font-weight: 700;
            padding: 1.15rem 0;

            &:hover {
              transform: scale(1.05);
            }
          }
        }
      }
    }
  }
}
