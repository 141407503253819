.footer{
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 60px;
    // background-color: #111;
    // margin-bottom: 40px;
    padding: 40px 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;;
}
.backBtn{
    width: 48%;
    border-radius: 35px;
    border: 1px solid #e7e7e7;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    font-weight: 400;
    &:hover{
        cursor: pointer;
        background: linear-gradient(270deg, #8EA5D5 34.82%, #ECB5CC 161.19%);
        color: white;
    }
}
.submitBtn{
    width: 48%;
    border-radius: 35px;
    border: 1px solid #e7e7e7;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    font-weight: 400;
    background: linear-gradient(270deg, #8EA5D5 34.82%, #ECB5CC 161.19%);
    color: white;
    &:hover{
        cursor: pointer;
        background: white;
        color: #8EA5D5;
    }
}
.inputWrapper{
    height: 56px;
    width: 100%;
    border-radius: 35px;
    border: 1px solid #e7e7e7;
    display: flex;
    align-items: center;
    padding: 0px 20px;
}
.successMsg{
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 22px;
    font-weight: 600;
    height: 100vh;
}
.inputTitle{
    font-size: 14px;
    font-weight: 400;
    padding-bottom: 17px;
}


.headTxt {
    font-size: 1.75rem;
    font-weight: 550;
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    height: 3rem;
    padding-bottom: 3rem;

    .close-div {
      cursor: pointer;
      transition: all ease 0.2s;

      &:hover {
        transform: scale(1.07);
      }
    }
  }

  .editUsername {
    position: fixed;
    height: calc(100% - 20%);
    top: 20%;
    right: 0;
    // border-left: 0.5px solid #e5e5e5;
    // border-top: 0.5px solid #e5e5e5;
    width: 30%;
    // max-width: 550px;
    background: white;
    z-index: 3;
    padding: 1rem 3rem 2rem;
    transition: all ease-in 0.4s;

    .updatingName {
      position: absolute;
      bottom: 20px;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 1.5rem;
      color: #18191d;
      display: flex;
      white-space: nowrap;

      & > span {
        font-weight: 600;
      }
    }

    .affiliateLogo {
      width: 50%;
      object-fit: contain;
    }

    .heading {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 40px;

      & > span {
        font-size: 1.75rem;
        font-weight: 700;
        color: #18191d;
      }

      & > div {
        font-size: 1.2rem;
        font-weight: 700;
        color: white;
        // background: #e73625;
        // border: 0.5px solid #e7e7e7;
        border-radius: 5px;
        width: 35px;
        height: 35px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
      }
    }

    .otherFields {
      margin: 30px 0;
      & > div {
        font-size: 1rem;
        font-weight: 700;
        margin-bottom: 10px;
        position: relative;

        & > input {
          padding: 20px;
          background: #ffffff;
          border: 0.5px solid #e7e7e7;
          border-radius: 10px;
          width: 100%;
          font-size: 1rem;
          font-weight: 500;
          color: #18191d;

          &::placeholder {
            font-size: 1rem;
            font-weight: 700;
            color: #18191d;
            opacity: 0.25;
          }
        }

        .usernameIndicator {
          position: absolute;
          top: 50%;
          right: 20px;
          transform: translate(-50%, -50%);
          width: 10px;
          height: 10px;
          border-radius: 50%;
          background: linear-gradient(0deg, #d80027, #d80027),
            linear-gradient(0deg, #d80027, #d80027), #d80027;
        }
      }
    }

    .confirmBtn {
      position: absolute;
      bottom: 2rem;
      left: 2rem;
      right: 2rem;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 1.2rem;
      font-weight: 700;
      cursor: pointer;
      color: #ffffff;
      padding: 20px;
      background: #18191d;
      border-radius: 10px;
    }
  }

  .usernameLoading {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .overlay {
    position: fixed;
    inset: 0;
    z-index: 2;
    background: transparent;
  }

  .goback {
    width: 50%;
    border-right: 0.5px solid #e7e7e7;
    color: #18191d;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.25rem;
    font-weight: 650;
    cursor: pointer;
    transition: all ease 0.3s;

    &:hover {
      font-size: 1.4rem;
    }
  }

  .addd {
    width: 50%;
    color: #18191d;
    opacity: 0.25;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.25rem;
    font-weight: 650;
    cursor: not-allowed;
    background: white;
  }

  .addd1 {
    width: 50%;
    color: #ffffff;
    opacity: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.25rem;
    font-weight: 650;
    background: #18191d;
    cursor: pointer;
    transition: all ease 0.3s;

    &:hover {
      font-size: 1.4rem;
    }
  }
  .eachApp {
    width: 100%;
    padding: 2rem 3rem;
    border-bottom: 0.5px solid #e7e7e7;
    display: flex;
    gap: 15px;
    align-items: center;
    cursor: pointer;

    p {
      margin-bottom: 0;
      color: #18191d;
    }
  }

  .eachApp1 {
    width: 90%;
    padding: 15px 0px;
    display: flex;
    flex-direction: column;
    border-bottom: 0.5px solid #e7e7e7;
    cursor: pointer;
    justify-content: center;

    p {
      margin-bottom: 0;
      color: #18191d;
    }
  }