.addForexAmountWrapper {
  margin-left: 3rem;
  overflow: scroll;
  height: calc(95% - 16px - 5rem);
}
.header {
  font-weight: 700;
  font-size: 14px;
  line-height: 30px;
}
.menuItem1 {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
}
.subhead {
  margin-top: 25px;
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
}
.transaction {
  margin: 20px 0px 20px 0px;
  background: #ffffff;
  border: 0.5px solid #e5e5e5;
  border-radius: 15px;
  padding: 15px;
  display: flex;
  justify-content: space-between;
}
.transc {
  border: none;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: #18191d;
  opacity: 1;
  width: 130px;
}
.transc::placeholder {
  opacity: 0.25;
}

.transc:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  opacity: 0.25;
}

.perc {
  cursor: not-allowed;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 75px;
  height: 32px;
  left: 39px;
  top: 382px;
  background: #ffffff;
  border: 0.5px solid #e5e5e5;
  border-radius: 15px;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  opacity: 0.25;
}
.perc:hover {
  color: #ffffff;
  background: black;
}
.menu2 {
  padding: 50px 50px 0px 43px;
  padding-bottom: 0px;
  height: calc(95% - 16px);
  justify-content: space-between;
  overflow-y: scroll;
}
.coin {
  display: flex;
  justify-content: center;
  cursor: pointer;
  width: 100px;
  height: 32px;
  left: 206px;
  top: 304px;
  background: #ffffff;
  border: 0.5px solid #e5e5e5;
  border-radius: 15px;
  padding: 10px;
}
.coin1 {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 120px;
  height: 32px;
  left: 206px;
  top: 304px;
  background: #ffffff;
  border: 0.5px solid #e5e5e5;
  border-radius: 15px;
  padding: 10px;
}
.coin:hover,
.coin1:hover {
  background-color: black;
  color: white;
  transform: scale(1.05);
  transition: all 0.3s ease-in-out;
}

.coinimgsmall {
  height: 15px;
  width: 15px;
}
.coinsymbolsmall {
  font-weight: 800;
  font-size: 11px;
  line-height: 13px;
  margin-left: 5px;
  margin-bottom: 0;
}

.add-amount-box {
  padding: 0px;
  border: 0.5px solid #e7e7e7;
  width: 77%;
  border-radius: 15px;
  margin: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin-left: 50px;
  margin-top: 2rem;
  height: 4rem;
}

.div-amount-input1 {
  display: flex;
  height: 100%;
  justify-content: space-between;
  padding-top: 0px;
  margin: 0;
  align-items: center;
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type='number'] {
    -moz-appearance: textfield;
  }
}

.add-input {
  border: none;
  width: 100%;
  height: 100%;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  padding-left: 10px;
  padding-right: 15px;
  border-radius: 15px;
  color: #18191d;
  margin-top: 0;
  text-align: end;
  &:disabled {
    background-color: white;
  }
}

.add-input::placeholder {
  opacity: 0.25;
}

.add-input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  opacity: 0.25;
}

.add-input::-ms-input-placeholder {
  /* Microsoft Edge */
  opacity: 0.25;
}

.add-from-coin {
  display: flex;
  justify-content: center;
  cursor: pointer;
  width: 30%;
  height: 100%;
  background: #ffffff;
  border: none;
  border-radius: 0;
  padding: 0;
  align-items: center;
  border-right: 0.5px solid #e7e7e7;
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
}

.sending-txt {
  min-height: 5%;
}
