.footer{
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 60px;
    // background-color: #111;
    // margin-bottom: 40px;
    padding: 40px 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;;
}
.backBtn{
    width: 48%;
    border-radius: 35px;
    border: 1px solid #e7e7e7;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    font-weight: 400;
    &:hover{
        cursor: pointer;
        background: linear-gradient(270deg, #8EA5D5 34.82%, #ECB5CC 161.19%);
        color: white;
    }
}
.submitBtn{
    width: 48%;
    border-radius: 35px;
    border: 1px solid #e7e7e7;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    font-weight: 400;
    background: linear-gradient(270deg, #8EA5D5 34.82%, #ECB5CC 161.19%);
    color: white;
    &:hover{
        cursor: pointer;
        background: white;
        color: #8EA5D5;
    }
}
.inputWrapper{
    height: 56px;
    width: 100%;
    border-radius: 35px;
    border: 1px solid #e7e7e7;
    display: flex;
    align-items: center;
    padding: 0px 20px;
}
.successMsg{
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 22px;
    font-weight: 600;
    height: 100vh;
}
.inputTitle{
    font-size: 14px;
    font-weight: 400;
    padding-bottom: 17px;
}


.headTxt {
    font-size: 1.75rem;
    font-weight: 550;
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    height: 3rem;
    padding-bottom: 3rem;

    .close-div {
      cursor: pointer;
      transition: all ease 0.2s;

      &:hover {
        transform: scale(1.07);
      }
    }
  }

  .adminInput{
    border: 1px solid #7899CF;
    border-radius: 10px;
    height: 40px;
    padding: 0px 10px;
    margin: 0px 10px;
  }
  .adminSubmit{
    border: 1px solid #7899CF;
    background-color: #7899CF;
    color: white;
    height: 40px;
    width: 100px;
    border-radius: 10px;
    margin-right: 10px;
    font-weight: 600;
  }
  .adminClear{
    border: 1px solid #7899CF;
    height: 40px;
    width: 100px;
    border-radius: 10px;
    font-weight: 600;
  }
  .selectedAdmin{
    height: 40px;
    background-color: #e7e7e7;
    border: 1px solid #e7e7e7;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px 10px;
    font-weight: 600;
  }