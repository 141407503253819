.affiliatePage {
  width: 100%;
  overflow-y: hidden;
  position: absolute;
  inset: 0;
  overflow-x: hidden;

  .affiliatePage-navbar {
    width: 100%;
    height: 8%;
    border-bottom: 0.5px solid #e7e7e7;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 3rem;
    position: relative;

    .hamIcon {
      cursor: pointer;
      transition: all ease 0.2s;

      &:hover {
        transform: scale(1.1);
      }
    }

    .affiliatePage-icon {
      width: 10%;
      height: 100%;
      img {
        width: 100%;
        height: 100%;
      }
    }

    .search-affiliate {
      width: 43%;
      height: calc(100% - 2rem);
      border: 0.5px solid #ededed;
      background: white;
      border-radius: 25px;
      padding: 0.5rem 2rem;
      display: flex;
      align-items: center;
      position: absolute;
      transform: translate(-50%, -50%);
      top: 50%;
      left: 50%;

      .all {
        width: 32%;
        height: 100%;
        border-right: 3px solid #e7e7e7;
        display: flex;
        align-items: center;
        font-size: 1rem;
        color: #18191d;
        font-weight: 700;
        opacity: 0.25;
        gap: 1rem;
        p {
          margin-bottom: 0;
        }
        .arroww {
          display: flex;
          align-items: center;
          img {
            width: 15px;
            height: 15px;
            cursor: pointer;
          }
        }
      }
      .im {
        width: 100%;
        height: 100%;
        // padding-left: 1rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 1rem;
        color: #18191d;
        font-weight: 700;
        // opacity: 0.25;
        gap: 1rem;
        input {
          border: none;
          font-size: 1rem;
          color: #18191d;
          font-weight: 700;
          background: transparent;
        }
      }
    }

    .right-topa {
      // width: 37%;
      height: 100%;
      display: flex;
      .divv {
        // width: 50%;
        height: 100%;
        display: flex;
        gap: 1rem;
        align-items: center;
        p {
          margin-bottom: 0;
          font-size: 1rem;
          color: #18191d;
          font-weight: 700;
        }
      }
    }

    .w-btn {
      padding: 0.65rem 1.75rem;
      color: #ffffff;
      background: linear-gradient(
        270deg,
        rgba(129, 203, 215, 0.7) 0%,
        rgba(61, 67, 148, 0.7) 100%
      );
      font-size: 0.75rem;
      font-weight: 520;
      border-radius: 25px;
      cursor: pointer;
      transition: all ease 0.2s;
      &:hover {
        transform: scale(1.07);
      }
    }
  }

  .w-space {
    width: 100%;
    height: 5.5%;
    padding: 0 3rem;
    .optionss {
      width: 30%;
      height: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 5px 0;
      .each-option-selected {
        background: rgba(229, 229, 229, 0.25);
        font-weight: 600;
        padding: 5px 1.75rem;
        border-radius: 25px;
      }
      .each-option {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        p {
          margin-bottom: 0;
          color: #18191d;
          font-size: 0.9rem;
        }
        div {
          display: flex;
          gap: 5px;
          transition: all ease 0.3s;
          cursor: pointer;
          &:hover {
            scale: 1.1;
          }
        }
      }
      .hamburger {
        color: #18191d;
        font-weight: 700;
        display: flex;
        p {
          margin-bottom: 0;
        }
        .hov-animate {
          transition: all ease 0.3s;
          &:hover {
            scale: 1.08;
          }
        }
      }
      .top-icons {
        display: flex;
        align-items: center;
        height: 100%;
        div {
          height: 100%;
          padding: 0 2rem;
          display: flex;
          border-right: 0.25px solid #e7e7e7;
          align-items: center;
          img {
            height: 50%;
          }
        }
      }
    }
  }

  .affiliatePage-content {
    width: 100%;
    height: calc(100% - 13.5%);

    .listDataGrid {
      display: grid;
      // grid-template-columns: 1fr 1fr 1fr 1fr 1fr ;
      padding: 10px 4%;
      border-bottom: solid 0.5px #e7e7e7;
      height: 90px;
      .title {
        font-size: 0.9rem;
        font-weight: 700;
        color: #18191d;
      }
      .subtitle {
        font-size: 0.65rem;
        font-weight: 400;
        color: #18191d;
      }

      .subtitle1 {
        font-size: 10%;
        font-weight: 400;
        color: #18191d;
      }
      & > div:not(:first-child) {
        /* text-align: right; */
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: end;
      }
      &:hover {
        & > div {
          -ms-transform: scale(1.02); /* IE 9 */
          -webkit-transform: scale(1.02); /* Safari 3-8 */
          transform: scale(1.02);
          transition: 0.3s all ease-in-out;
          opacity: 1;
        }
        cursor: pointer;
        background: whitesmoke;
        transition: 0.3s all ease-in-out;
      }
    }

    .desktopWrapper {
      width: 100%;
      height: 100%;
      overflow-y: scroll;

      .listGrid {
        display: grid;
        // grid-template-columns: 1fr 1fr 1fr 1fr 1fr ;
        padding: 10px 4%;
        height: 45px;
        background-color: #fafafa;
        border-bottom: solid 0.5px #e7e7e7;
        font-size: 0.8rem;
        // font-size: 1.6vh;
        // font-size: clamp(0.8rem, 0.8rem + 12 * (100vw - 400px) / 800, 0.9rem);
        & > div:not(:first-child) {
          text-align: right;
          font-weight: 600;
        }
        & > div {
          font-weight: 600;
        }
      }

      .listDataGrid {
        display: grid;
        // grid-template-columns: 1fr 1fr 1fr 1fr 1fr ;
        padding: 10px 4%;
        border-bottom: solid 0.5px #e7e7e7;
        height: 90px;
        .customtitle {
          font-size: 16px !important;
          font-weight: 600;
        }
        .title {
          font-size: 0.9rem;
          font-weight: 700;
          color: #18191d;
        }
        .subtitle {
          font-size: 0.65rem;
          font-weight: 400;
          color: #18191d;
        }

        .subtitle1 {
          font-size: 10%;
          font-weight: 400;
          color: #18191d;
        }
        & > div:not(:first-child) {
          /* text-align: right; */
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: end;
        }
        &:hover {
          & > div {
            -ms-transform: scale(1.02); /* IE 9 */
            -webkit-transform: scale(1.02); /* Safari 3-8 */
            transform: scale(1.02);
            transition: 0.3s all ease-in-out;
            opacity: 1;
          }
          cursor: pointer;
          background: whitesmoke;
          transition: 0.3s all ease-in-out;
        }
      }
    }
  }
}
