.sidebarwithdraw {
  color: #18191d;
  height: 100vh;
  background-color: white;
  .sidebarhead {
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 1;
    width: 356px;
    .mainlogo {
      margin: 85px 65px 30px 0;
      width: 57%;
    }
  }
  .breadcrumb {
    width: 90%;
    padding-top: 12px;
    padding-left: 43px;
    cursor: pointer;
    background-color: white;
    font-size: 13px;
    .active-crumb {
      margin-top: 5px;
      font-weight: 700;
      font-size: 11px;
      cursor: pointer;
    }
  }
  .breadcrumbText:hover {
    font-weight: 700;
    transform: scale(1.05);
    transition: all 0.3s ease-in-out;
  }
}

.withdraw-footer {
  background-color: white;
  border-top: 1px solid #e7e7e7;
  border-right: 1px solid #e7e7e7;
  position: fixed;
  bottom: 0;
  height: 4rem;
  width: 22%;
  display: flex;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  .back1 {
    height: 4rem;
    width: 50%;
    padding: 20px;
    color: #18191d;
    background-color: white;
    text-align: center;
    cursor: pointer;
    h6 {
      &:hover {
        transform: scale(1.3);
        transition: all 0.3s ease-in-out;
      }
    }
  }

  .nextbtn {
    height: 4rem;
    width: 50%;
    padding: 20px;
    color: rgba(209, 206, 206, 0.782);
    text-align: center;
    cursor: not-allowed;
    opacity: 0.3;
    h6 {
      color: white;
      &:hover {
        transform: scale(1.3);
        transition: all 0.3s ease-in-out;
      }
    }
  }

  .nextbtn1 {
    height: 4rem;
    width: 50%;
    padding: 20px;
    color: rgba(209, 206, 206, 0.782);
    text-align: center;
    cursor: not-allowed;
    opacity: 0.3;
  }
}

.step-div {
  width: 90%;
  padding-left: 43px;

  .w-nextBtn {
    width: 100%;
    padding: 0 1.5rem;
    position: absolute;
    height: 3.25rem;
    bottom: 9.5rem;
    left: 0;

    div {
      width: 100%;
      height: 100%;
      border-radius: 35px;
      background: #78d0b1;
      color: #fff;
      font-size: 1.1rem;
      font-weight: 500;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      transition: all 0.2s ease;

      &:hover {
        transform: scale(1.04);
      }
    }
  }

  .withdrawFooter {
    width: 100%;
    height: 8.5rem;
    padding: 1.5rem 1.5rem 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: absolute;
    bottom: 0;
    left: 0;
    border-top: 0.5px solid #e5e5e5;

    .backclose-btns {
      display: flex;
      width: 100%;
      justify-content: space-between;

      div {
        color: #fff;
        font-size: 1.1rem;
        font-weight: 500;
        padding: 0.75rem 2.2rem;
        width: 48%;
        border-radius: 35px;
        display: flex;
        justify-content: center;
        cursor: pointer;
        transition: all 0.2s ease;

        &:hover {
          transform: scale(1.04);
        }
      }
    }

    .action-btn {
      display: flex;
      width: 100%;
      padding: 0.75rem 2.2rem;
      color: #fff;
      justify-content: center;
      border-radius: 35px;
      font-weight: 500;
      cursor: pointer;
      transition: all 0.2s ease;

      &:hover {
        transform: scale(1.04);
      }
    }
  }
}

.withdraw-success {
  padding-top: 20px;
  padding-left: 43px;
  width: 90%;
  font-weight: 700;
  font-size: 15px;
  line-height: 30px;
}
.balance {
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  margin-top: 50px;
}

.step-head {
  font-weight: 800;
  font-size: 18px;
  line-height: 22px;
}

.step-para {
  margin-top: 10px;
  font-weight: 400;
  font-size: 0.9rem;
  line-height: 27px;
}

.address-div {
  display: flex;
  width: 100%;
  height: 74px;
  border-radius: 15px;
}

.address-input {
  border: none;
  width: 100%;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  padding-left: 10px;
  border-radius: 15px;
  color: #18191d;
}

.address-btn {
  position: relative;
  right: 20px;
  top: 10px;
}

.address-input::placeholder {
  opacity: 0.25;
}

.address-input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  opacity: 0.25;
}

.address-input::-ms-input-placeholder {
  /* Microsoft Edge */
  opacity: 0.25;
}

.each-paste {
  width: 200px;
  display: flex;
  margin-top: 40px;
  cursor: pointer;
}

.each-paste:hover {
  transform: scale(1.05);
  transition: 0.3s ease;
}

.paste-text {
  font-weight: 600;
  font-size: 13px;
  line-height: 22px;
  margin-left: 10px;
}

.checkbook-text {
  font-weight: 600;
  font-size: 13px;
  line-height: 22px;
  color: #1a6bb4;
  margin-left: 10px;
}

.from-details {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
}

.from-text {
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
}

.from-to-inputs {
  padding: 0px 10px 10px 10px;
  border: 0.5px solid #e7e7e7;
  width: 100%;
  height: 154px;
  border-radius: 15px;
  margin: 20px 0;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.withdraw-success-box {
  padding: 0px 10px 10px 10px;
  border: 0.5px solid #e7e7e7;
  width: 100%;
  border-radius: 15px;
  margin: 20px 0;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.from-to-inputs1 {
  padding: 0px 10px 10px 10px;
  border: 0.5px solid #e7e7e7;
  width: 100%;
  height: 77px;
  border-radius: 15px;
  margin: 20px 0;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.div-from-input {
  display: flex;
  height: 40%;
  justify-content: space-between;
  padding-top: 8px;
  align-items: center;
}
.div-to-input {
  display: flex;
  height: 40%;
  justify-content: space-between;
  padding-top: 8px;
  align-items: center;
}

.div-success-input {
  display: flex;
  height: 40%;
  justify-content: space-between;
  padding-top: 8px;
  margin: 10px;
}

.coinimgsmall {
  height: 15px;
  width: 15px;
}
.coinsymbolsmall {
  font-weight: 800;
  font-size: 11px;
  line-height: 13px;
  margin-left: 5px;
}

.div-border {
  border-top: 0.25px solid #e5e5e5;
}

.from-input {
  border: none;
  width: 170px;
  height: 70px;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  padding-left: 10px;
  border-radius: 15px;
  color: #18191d;
  height: 50%;
  margin-top: 8px;
  &:disabled {
    background-color: white;
  }
}

.from-input::placeholder {
  opacity: 0.25;
}

.from-input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  opacity: 0.25;
}

.from-input::-ms-input-placeholder {
  /* Microsoft Edge */
  opacity: 0.25;
}

.from-coin {
  display: flex;
  justify-content: center;
  cursor: pointer;
  width: 80px;
  height: 32px;
  background: #ffffff;
  border: 0.5px solid #e5e5e5;
  border-radius: 15px;
  padding: 10px;
  margin-top: 8px;
}

.post-withdraw-from-coin {
  display: flex;
  justify-content: center;
  cursor: pointer;
  width: 80px;
  height: 32px;
  background: #ffffff;
  border: 0.5px solid #e5e5e5;
  border-radius: 15px;
  padding: 10px;
  margin-top: 2px;
}

.withdraw-perc {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 75px;
  height: 32px;
  left: 39px;
  top: 382px;
  background: #ffffff;
  border: 0.5px solid #e5e5e5;
  border-radius: 15px;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
}
.withdraw-perc:hover {
  color: #ffffff;
  background: black;
}

.picelock {
  display: flex;
  justify-content: flex-start;
}

.pice-text {
  font-weight: 600;
  font-size: 13px;
  line-height: 50px;
}

.withdraw-toggle {
  margin-left: 10px;
  margin-top: 6px;
}

//////toggle css

.withdraw-tgl1[type="checkbox"] {
  height: 0;
  width: 0;
  visibility: hidden;
}

.withdraw-tgl2 {
  border: 3px solid #e5e5e5;
  cursor: pointer;
  text-indent: -9999px;
  width: 30px;
  height: 18px;
  background: white;
  display: block;
  border-radius: 100px;
  position: relative;
}

.withdraw-tgl2:after {
  content: "";
  position: absolute;
  top: -1px;
  left: 1px;
  width: 14px;
  height: 14px;
  background: #5f6163;
  border-radius: 90px;
  transition: 0.3s;
}

.withdraw-tgl1:checked + .withdraw-tgl2 {
  background: white;
}

.withdraw-tgl1:checked + .withdraw-tgl2:after {
  left: calc(100%);
  transform: translateX(-100%);
}

.withdraw-tgl2:active:after {
  width: 20px;
}

/// toggle css end

.step-3-scroll-div {
  overflow-x: hidden;
  overflow-y: scroll;
  height: 80vh;

  .w-nextBtn1 {
    width: 100%;
    padding: 0 1.5rem;
    height: 3.25rem;
    margin-bottom: 1rem;

    div {
      width: 100%;
      height: 100%;
      border-radius: 35px;
      background: #78d0b1;
      color: #fff;
      font-size: 1.1rem;
      font-weight: 500;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      transition: all 0.2s ease;

      &:hover {
        transform: scale(1.04);
      }
    }
  }

  .withdrawFooter1 {
    width: 100%;
    height: 8.5rem;
    padding: 1.5rem 1.5rem 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-top: 0.5px solid #e5e5e5;

    .backclose-btns1 {
      display: flex;
      width: 100%;
      justify-content: space-between;

      div {
        color: #fff;
        font-size: 1.1rem;
        font-weight: 500;
        padding: 0.75rem 2.2rem;
        width: 48%;
        border-radius: 35px;
        display: flex;
        justify-content: center;
        cursor: pointer;
        transition: all 0.2s ease;

        &:hover {
          transform: scale(1.04);
        }
      }
    }

    .action-btn1 {
      display: flex;
      width: 100%;
      padding: 0.75rem 2.2rem;
      color: #fff;
      justify-content: center;
      border-radius: 35px;
      font-weight: 500;
      cursor: pointer;
      transition: all 0.2s ease;

      &:hover {
        transform: scale(1.04);
      }
    }
  }
}

.step3-each-card-head {
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  margin-top: 25px;
}

.step3-each-card-head1 {
  font-weight: 400;
  font-size: 13px;
  padding: 1.85rem 1.85rem 1rem 1.85rem;
  height: 5rem;
  display: flex;
  align-items: flex-end;
}

.step3-each-card {
  display: flex;
  padding-left: 20px;
  align-items: center;
  width: 100%;
  height: 70px;
  border: 0.5px solid #e5e5e5;
  border-radius: 15px;
  margin: 10px 0;
}

.step3-each-card1 {
  display: flex;
  flex-direction: column;
  padding: 20px;
  width: 100%;
  height: max-content;
  border: 0.5px solid #e5e5e5;
  border-radius: 15px;
  margin: 10px 0;
}

.step3-each-card2 {
  display: flex;
  align-items: center;
  width: 100%;
  height: 6.25rem;
  border: 0.5px solid #e5e5e5;
  padding: 0 1.85rem;
  border-left: none;
  border-right: none;
}

.step3-each-card-img {
  height: 25px;
  width: 25px;
}

.step3-each-card-text {
  font-weight: 500;
  font-size: 15px;
  // margin-left: 10px;
  // margin-top: 15px;
  margin: 0;
}

.step3-each-card-text1 {
  font-weight: 700;
  font-size: 15px;
  margin-left: 10px;
  margin-top: 15px;
}

.step3-each-card-text2 {
  margin-left: 10px;
  font-weight: 300;
  font-size: 1rem;
  line-height: 10px;
  margin-top: -15px;
}

.step3-each-card-text-2 {
  font-weight: 700;
  font-size: 15px;
  line-height: 10px;
  margin-top: -15px;
}

.step3-each-card-text3 {
  font-weight: 400;
  font-size: 0.9rem;
  line-height: 25px;
}

.step3-each-card-text4 {
  font-weight: 700;
}

.step3-subhead {
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
}

.feescard-1 {
  padding: 20px;
  border: 0.5px solid #e5e5e5;
  border-radius: 15px;
}

.fees-card-content {
  display: flex;
  justify-content: space-between;
  padding: 20px 0 20px 0;
  border-bottom: 0.5px solid #e5e5e5;
}

.fee-text1 {
  font-weight: 400;
  font-size: 16px;
  line-height: 13px;
}

.fee-text2 {
  font-weight: 600;
  font-size: 0.9rem;
  line-height: 13px;
}

.timebox-div {
  animation: rotate 5s infinite linear;
}

// Rotate using @keyframes
@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.check-div {
  font-weight: 300;
  font-size: 11px;
  line-height: 25px;
  margin-top: 20px;
}

.check-div p {
  font-size: 0.9rem;
}

.check-box-withdraw {
  top: 0;
  height: 20px;
  width: 20px;
  margin-right: 10px;
}

.check-box-withdraw-2 {
  top: 0;
  height: 20px;
  width: 20px;
  margin-right: 10px;
}

@keyframes heartbeat {
  0% {
    transform: scale(0.75);
  }
  20% {
    transform: scale(1);
  }
  40% {
    transform: scale(0.75);
  }
  60% {
    transform: scale(1);
  }
  80% {
    transform: scale(0.75);
  }
  100% {
    transform: scale(0.75);
  }
}

.withdrawotclogo {
  margin-top: -70vh;
  padding: 70px;
  width: 280px;
  background-color: white;
  border-radius: 25px;
  .otclogoimg {
    animation: heartbeat 1.3s infinite;
  }
}

.email-code-wrapper {
  margin-top: 50px;
  margin-bottom: 50px;
  .email-code {
    width: 100%;
    input {
      height: 60px;
      width: 90% !important;
      border: 0.5px solid #e5e5e5;
      border-radius: 5px;
    }
  }
}

.reload-otp {
  margin-top: 18px;
  margin-left: 8px;
}

.withdraw-loading {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
