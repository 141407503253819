.commercial {
  width: 100%;
  overflow-y: hidden;
  position: absolute;
  inset: 0;
  overflow-x: hidden;

  .commercial-navbar {
    width: 100%;
    height: 8%;
    border-bottom: 0.5px solid #e7e7e7;
    display: flex;
    align-items: center;
    padding: 1rem 3rem;
    position: relative;
    justify-content: space-between;

    .commercial-hamburger {
      width: 30px;
      height: 30px;
      margin-right: 2rem;

      & > svg {
        width: 100%;
        height: 100%;
        cursor: pointer;
      }

      & > div {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 2rem;
        font-weight: 600;
        color: #18191d;
        cursor: pointer;
      }
    }

    .commercial-icon {
      width: 60%;
      height: 100%;
      display: flex;
      align-items: center;
      gap: 5px;
      font-size: 1.35rem;
      font-weight: 650;
      color: #18191d;
      img {
        width: 80%;
        height: 80%;
      }
    }

    .search-commercial {
      width: 43%;
      height: calc(100% - 2rem);
      border: 0.5px solid #e7e7e7;
      background: white;
      border-radius: 25px;
      padding: 0.5rem 2rem;
      display: flex;
      align-items: center;
      position: absolute;
      transform: translate(-50%, -50%);
      top: 50%;
      left: 50%;

      .all {
        width: 32%;
        height: 100%;
        border-right: 3px solid #e7e7e7;
        display: flex;
        align-items: center;
        font-size: 1rem;
        color: #18191d;
        font-weight: 700;
        opacity: 0.25;
        padding-right: 20px;
        justify-content: space-between;
        p {
          margin-bottom: 0;
        }
        .arroww {
          display: flex;
          align-items: center;
          img {
            width: 15px;
            height: 15px;
            cursor: pointer;
          }
        }
      }
      .im {
        width: 100%;
        height: 100%;
        // padding-left: 1rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 1rem;
        color: #18191d;
        font-weight: 700;
        // opacity: 0.25;
        gap: 1rem;
        input {
          border: none;
          font-size: 1rem;
          color: #18191d;
          font-weight: 700;
          background: transparent;
        }
      }
    }

    .commercial-listBtn {
      padding: 0.65rem 1.5rem;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #18191d;
      border: 0.5px solid #18191d;
      border-radius: 25px;
      cursor: pointer;
      font-weight: 550;
      color: white;
      font-size: 0.8rem;
      // margin-left: 62vw;
      white-space: nowrap;
    }
  }

  .w-space {
    width: 100%;
    height: 5.5%;
    padding: 0 3rem;
    border-bottom: 0.5px solid #e7e7e7;
    .optionss {
      width: 30%;
      height: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 5px 0;
      .each-option-selected {
        background: rgba(229, 229, 229, 0.25);
        font-weight: 600;
        padding: 5px 1.75rem;
        border-radius: 25px;
      }
      .each-option {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        p {
          margin-bottom: 0;
          color: #18191d;
          font-size: 0.9rem;
        }
        div {
          display: flex;
          gap: 5px;
          transition: all ease 0.3s;
          cursor: pointer;
          &:hover {
            scale: 1.1;
          }
        }
      }
      .top-icons {
        display: flex;
        align-items: center;
        height: 100%;
        div {
          height: 100%;
          padding: 0 2rem;
          display: flex;
          border-right: 0.25px solid #e7e7e7;
          align-items: center;
          img {
            height: 50%;
          }
        }
      }
    }

    .business-option {
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      p {
        margin-bottom: 0;
        color: #18191d;
        font-size: 0.9rem;
      }
      div {
        display: flex;
        gap: 5px;
        transition: all ease 0.3s;
        cursor: pointer;
        &:hover {
          scale: 1.1;
        }
      }
    }
  }

  .commercial-content1 {
    width: 100%;
    height: calc(100% - 13.5%);
    display: flex;
    flex-direction: column;

    .header {
      width: 100%;
      display: flex;
      padding: 1rem 3rem;
      background: rgba(237, 237, 237, 0.37);
      font-size: 0.8rem;
      border-bottom: 0.5px solid #ebebeb;
      height: 3rem;
      align-items: center;
      font-weight: 400;
      line-height: unset;

      .userDiv {
        width: 40%;
        height: 100%;
      }

      .brandDiv {
        width: 40%;
        height: 100%;
      }

      .campaignsDiv {
        width: 20%;
        height: 100%;
      }
    }

    .contentData {
      width: 100%;
      height: calc(100% - 3rem);
      overflow-y: scroll;

      .eachData {
        width: 100%;
        display: flex;
        height: 6rem;
        border-bottom: 0.5px solid #ebebeb;
        padding: 0 3rem;
        align-items: center;
        cursor: pointer;
        transition: all ease 0.2s;

        &:hover {
          padding-left: 4rem;
        }

        .userDiv1 {
          width: 40%;
          height: 100%;
          display: flex;
          gap: 10px;
          align-items: center;

          img {
            width: 30px;
            height: 30px;
          }
        }

        .brandDiv1 {
          width: 40%;
          height: 100%;
          font-size: 0.9rem;
          font-weight: 600;
          display: flex;
          align-items: center;
        }

        .campaignsDiv1 {
          width: 20%;
          height: 100%;
          font-size: 0.9rem;
          font-weight: 600;
          display: flex;
          align-items: center;
        }
      }
    }
  }

  .commercial-content2 {
    width: 100%;
    height: calc(100% - 13.5%);
    display: flex;
    flex-wrap: wrap;
    background: rgba(237, 237, 237, 0.37);
    padding: 2rem 3rem;
    justify-content: flex-start;
    column-gap: 2.75%;
    overflow-y: scroll;

    .videoCards {
      width: 31.5%;
      background: #ffffff;
      border: 1px solid #e7e7e7;
      border-radius: 15px;
      padding: 1.5rem 1.5rem 2rem;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin-top: 2rem;
      height: 30rem;
      transition: all ease 0.2s;
      cursor: pointer;

      &:hover {
        box-shadow: 0 12px 28px 0 rgba(0, 0, 0, 0.2),
          0 2px 4px 0 rgba(0, 0, 0, 0.1),
          inset 0 0 0 1px hsla(0, 0%, 100%, 0.05);
      }

      .thumbnailPic {
        height: 15rem;
        width: 100%;
        border: 0.5px solid #e7e7e7;
        border-radius: 15px;
        img {
          width: 100%;
          height: 100%;
          border-radius: 15px;
        }
      }

      .cardHeader {
        width: 100%;
        font-size: 0.95rem;
        font-weight: 600;
        margin-top: 1.5rem;
        margin-bottom: 1rem;
      }

      .cardContent {
        font-size: 0.8rem;
        margin-bottom: 2.5rem;
      }

      .btnsDiv1 {
        width: 100%;
        display: flex;
        gap: 1rem;
        height: 3rem;

        div {
          width: calc(50% - 0.5rem);
          border-radius: 25px;
          color: #ffffff;
          display: flex;
          justify-content: center;
          font-size: 0.9rem;
          font-weight: 600;
          cursor: pointer;
          align-items: center;
          transition: all ease 0.2s;

          &:hover {
            transform: scale(1.05);
          }
        }
      }
    }
  }

  .popularC {
    position: fixed;
    height: calc(100% - 13.5%);
    top: 13.5%;
    border-left: 0.5px solid #e5e5e5;
    // border-top: 0.5px solid #e5e5e5;
    width: 30%;
    max-width: 550px;
    background: white;
    z-index: 3;
    padding: 1rem 3rem 2rem;
    transition: all ease-in 0.4s;
    right: 0;

    .head-txt {
      font-size: 1.75rem;
      font-weight: 550;
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;
      height: 6rem;

      .close-div {
        cursor: pointer;
        transition: all ease 0.2s;

        &:hover {
          transform: scale(1.07);
        }
      }
    }

    .overall-div {
      width: 100%;
      height: calc(100% - 11rem);
      display: flex;
      flex-direction: column;
      overflow-y: scroll;

      .subbTxt {
        font-size: 1.2rem;
        font-weight: 550;
      }

      .upload {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        padding: 2rem 0rem;
        margin-top: 1rem;

        .imgUpload {
          border: 0.5px solid #e7e7e7;
          padding: 1rem;
          border-radius: 5px;
          cursor: pointer;
          transition: all ease 0.2s;
          &:hover {
            transform: scale(1.05);
          }
        }
      }

      .each-action1 {
        display: flex;
        gap: 10px;
        align-items: center;
        border: 1px solid #e5e5e5;
        border-radius: 15px;
        cursor: pointer;
        padding: 2rem 1.5rem;
        margin-top: 1rem;
        transition: all ease 0.2s;

        &:hover {
          background: #e7e7e7;
        }

        div {
          font-weight: 500;
        }

        img {
          width: 25px;
          height: 25px;
        }

        input {
          width: 100%;
          height: 100%;
          border: none;
          background: transparent;
          &::placeholder {
            font-weight: 550;
          }
        }

        .separatorDiv {
          display: flex;
          justify-content: space-between;
          width: 100%;
          height: 100%;
        }

        .hiddenDIv {
          margin-top: 2rem;
          display: flex;
          flex-direction: column;
          width: 100%;
        }
      }

      .each-action2 {
        display: flex;
        gap: 10px;
        align-items: center;
        border: 1px solid #e5e5e5;
        border-radius: 15px;
        cursor: pointer;
        margin-top: 1rem;
        transition: all ease 0.2s;

        &:hover {
          background: #e7e7e7;
        }

        input {
          width: 80%;
          height: 100%;
          border: none;
          padding: 2rem 1.5rem;
          background: transparent;
          &::placeholder {
            font-weight: 550;
          }
        }

        .currencyDiv {
          width: 20%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          border-left: 0.5px solid #e5e5e5;
          font-weight: 700;
        }
      }

      .successMsg {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 600;
        font-size: 1.5rem;
        text-align: center;
      }
    }

    .stepBtns {
      width: 100%;
      height: 5rem;
      display: flex;
      justify-content: space-between;
      padding: 0.5rem;

      div {
        width: 49%;
        border-radius: 35px;
        color: #ffffff;
        font-weight: 700;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        transition: all ease 0.2s;
        &:hover {
          transform: scale(1.05);
        }
      }
    }
  }
}
