.app {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f0f0f0;
  }
  
  .destinations {
    position: relative;
    width: 500px;
    height: 30px;
    display: flex;
    justify-content: space-between;
  
    .destination {
      width: 30px;
      height: 30px;
      background-color: green;
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      font-size: 18px;
    }
  }
  .container1{
    background: linear-gradient(270deg, #8EA5D5 34.82%, #ECB5CC 161.19%);
    height: 87%;
    display: flex;
    justify-content: space-between;
    padding-left: 40px;
    padding-right: 27px;
  }
  .pageTitle{
    color: #FFF;
    font-family: Montserrat;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 27px; /* 67.5% */
  }
  .pageSubTitle{
    color: #FFF;
    font-family: Montserrat;
    font-size: 25px;
    font-style: normal;
    font-weight: 500;
    line-height: 27px; /* 108% */
    padding-top: 20px;
  }
  .progressBarWrapper{
    width: 182px;
    // height: 85%;
    margin: 40px 0px;
    border-radius: 15px;
    background: rgba(255, 255, 255, 0.50);
    // margin-top: 40px;
    padding: 9px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .leftSide{
    padding-top: 50px;
    // overflow-y: scroll;
  }
  .topHalf{
    border-radius: 15px 15px 0px 0px;
    background: linear-gradient(270deg, #8EA5D5 34.82%, #ECB5CC 161.19%);
    height: 25%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: '100%';
    padding: 20px;
    font-size: 14px;
    font-weight: 700;
    color: white;
    text-align: center;
  }
  .bottomHalf{
    border-radius: 0px 0px 15px 15px;
    background: linear-gradient(270deg, #8EA5D5 34.82%, #ECB5CC 161.19%);
    height: 74%;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: end;
  }
  .progressBar{
    border-radius: 0px 0px 10px 10px;
    background: rgba(255, 255, 255, 0.50);
    height: 100%;
    
  }
.responsive-svg{
    width: 100%;
    height: 100%;
}

  /* Add these styles to your game.scss or a separate CSS file */

.popup {
    z-index: 9999;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); /* semi-transparent black overlay */
    display: flex;
    justify-content: center;
    align-items: center;  
  }
  
  .popup-content {
    background: #fff; /* white background for the popup */
    padding: 20px;
    // border-radius: 8px;
    // box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    // text-align: center;
    border: 16px solid #333; /* frame color */
    border-radius: 20px; /* rounded corners */
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    width: 30vw;
    height: 80vh;
    position: relative;
  }
  .popup-title{
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 10px;
  }
  
  .popup p {
    font-size: 18px;
    margin-bottom: 10px;
  }
  
  .popup button {
    position: absolute;
    bottom: 20px;
    left: 0;
    right: 0;
    margin: 0px 20px;
    padding: 10px 20px;
    background-color: #3498db; /* blue color, you can change it */
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .popup button:hover {
    background-color: #2980b9; /* darker blue on hover */
  }
  .popup-list{
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 10px;
  }
  