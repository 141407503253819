$primary-color: #18191d;
$app-color: #4caf50;
$text-color: #383c41;
$vault-border-color: #e7e7e7;
$border-color: #eeeeee;
$primary-green: #3ea254;
$primary-red: #ea0f0f;
$primary-blue: #182542;
$overlay-color: #000d;
$bond-color: #464b4e;
