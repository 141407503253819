.buttonlists{
    width: 121.87px;
height: 36px;
font-weight: 700;
font-size: 12px;
line-height: 15px;
display: flex;
align-items: center;
text-align: center;

color: #18191D;
margin-bottom: 0;
justify-content: center;

background: #FFFFFF;
border: 0.5px solid #E7E7E7;
border-radius: 15px;
transition: transform 0.5s ease;
&:hover{
    transition: transform 0.5s ease;
    transform: scale(1.1);
}
}
.loader-fee{

    width: 50%;
   // background-color: red;\
   display: flex;
   border-left: 0.5px solid #EBEBEB;

   justify-content: center;
   align-items: center;
   position: relative;
   .close-section{
    background-color: red;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 34px;
    position: absolute;
    right: 1%;
    top: 0%;
    height: 34px;
    img{
width: 80%;
    }
   }
  .updatefee{
    font-weight: 600;
font-size: 16px;
line-height: 20px;
display: flex;
align-items: center;
text-align: center;
position: absolute;
bottom: 3%;

color: #18191d;
  }
}
.sideDrawer
{
    
   padding: 3%;
    width: 50%;
   // background-color: red;\
   display: flex;
   border-left: 0.5px solid #EBEBEB;
   flex-direction: column;
   justify-content: space-between;
   position: relative;
   .close-section{

    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 34px;
    position: absolute;
    right: 1%;
    top: 0%;
    height: 34px;
    img{
        transition: transform 0.5s ease;
width: 80%;
&:hover{
    transition: transform 0.5s ease;
    transform: scale(1.1);
}
    }
}
   .title-tag-for-fee{
    font-style: normal;
font-weight: 700;
font-size: 16px;
line-height: 20px;
display: flex;
align-items: center;
margin-bottom: 1rem;
color: #18191d;
position: relative;

   }
   input{
    font-weight: 700;
font-size: 18px;
line-height: 22px;
display: flex;
align-items: center;
width: 100%;
height: 65.21px;
padding-left: 1.2rem;
background: #FFFFFF;
border: 0.5px solid #EBEBEB;
border-radius: 10px;
color: #18191d;
   }
   .labelupdateallpair{
    font-weight: 600;
font-size: 18px;
line-height: 22px;
display: flex;
align-items: center;
text-align: center;
height: 65.21px;
margin-bottom: 1rem;
cursor: pointer;
justify-content: center;
background: #FFFFFF;
border: 0.5px solid #18191d;
border-radius: 10px;
color: #18191d;
transition: transform 0.5s ease;
&:hover{    
    transform: scale(1.1);
    transition: transform 0.5s ease;
}
   }
   .labelupdateSelectedpair{
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    display: flex;
    align-items: center;
    text-align: center;
    height: 65.21px;
    height: 65.21px;
    margin-bottom: 0rem;
    cursor: pointer;
background: #18191d;
border: 0.5px solid #18191d;
border-radius: 10px;
color: #fff;
justify-content: center;
transition: transform 0.5s ease;
&:hover{    
    transition: transform 0.5s ease;
    transform: scale(1.1);

}
   }
}