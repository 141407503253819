@import '../../../static/scss/mixin';

.cardsbanking {
  margin-top: 25px;
  padding: 20px;
  border: 1px solid #e7e7e7;
  border-radius: 15px;
  display: flex;
  justify-content: space-between;
}

.cards {
  padding: 2rem 0;
  padding-left: 3rem;
  padding-right: 4rem;
  border-bottom: 0.5px solid #e5e5e5;
  display: flex;
  justify-content: space-between;
}

.first-column {
  display: flex;
  flex-direction: row;
  .child-skeleton {
    height: 40px;
    width: 40px;
    border-radius: 50%;
  }
  .child-div {
    display: flex;
    flex-direction: column;
    margin-left: 15px;
    width: 200px;
  }
}
.second-column {
  display: flex;
  flex-direction: column;
  margin-left: 5px;
  width: 100px;
}
.third-skeleton {
  margin-left: 5px;
  height: 40px;
  width: 120px;
}
