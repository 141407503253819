.containerWrapper{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.container{
    width: 500px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}




.mobileLogo {
    // border-bottom: 1px solid #e5e5e5;
    // padding: 0 40%;
    background-color: white;
    // height: 9vh;
    margin-bottom: 5vh;
    img {
      width: 100%;
    }
  }

  .desc{
    color: #18191D;
    text-align: center;
    font-family: Montserrat;
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: 35px;
  }

  .logoutBtn{
    margin-top: 20vh;
    width: 377px;
    height: 67px;
    background: linear-gradient(90deg, #8EA5D5 34.82%, #ECB5CC 161.19%);
    border: none;
    border-radius: 10px;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    color: #fff;
  }

  @media screen and (max-width: 767px) {
    .container{
        width: 320px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .logoutBtn{
        margin-top: 20vh;
        width: 95%;
        height: 67px;
        background: linear-gradient(90deg, #8EA5D5 34.82%, #ECB5CC 161.19%);
        border: none;
        border-radius: 10px;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        color: #fff;
      }
  }