.notshowDrawer {
  display: none;
  transition: all 400ms ease;
}
.showDrawer {
  display: block;
  transition: all 400ms ease;
  right: 0px !important;
}
.slowfunddrawer {
  transition: all 400ms ease;
}
.rightfundContiner {
  width: 32%;
  max-width: 32%;
  border-left: 0.5px solid #e7e7e7;
  padding: 30px;
  position: absolute;
  right: -100px;
  background-color: white;
  z-index: 9;
  transition: all 400ms ease;
  height: 100%;
  .ajustheightfund {
    height: 100%;
  }
  .handingdrawer {
    transition: all 400ms ease;
  }
  .texthandler {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .priceCoinFundsList {
    margin-top: 1rem;

    // overflow: scroll;

    height: calc(100% - 349px);
    margin-bottom: 2rem;
  }

  .labelfundscoin {
    height: 68px;
    cursor: pointer;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    display: flex;
    align-items: center;
    text-align: center;

    color: #ffffff;
    justify-content: center;

    background: #9fd6df;
    border-radius: 10px;
    &:hover {
      transform: translateY(-5px);
    }
  }
  .Quote {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 34px;
    display: flex;
    align-items: center;

    color: #18191d;
  }
  .boxInputFunds {
    height: 78px;

    border: 0.5px solid #ebebeb;
    display: flex;
    align-items: center;
    margin-top: 1rem;
    margin-bottom: 3rem;
    .leftsideCoing {
      border-left: 0.5px solid #ebebeb;
      height: 78px;

      padding-left: 1.5rem;
      padding-right: 1.5rem;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        height: 26.01px;
        margin-right: 10px;
      }
      .seletedCoinname {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 24px;
        display: flex;
        align-items: center;

        color: #18191d;
      }
    }
    input {
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 500;
      font-size: 22px;
      width: 100%;
      height: 100%;
      border: none;
      line-height: 27px;
      display: flex;
      align-items: center;
      padding-left: 1rem;
      color: #18191d;

      &::placeholder {
        opacity: 0.75;
      }
    }
  }
  .textcoin {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    display: flex;
    align-items: center;

    color: #18191d;
    margin-top: 3rem;
  }

  h1 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 34px;

    color: #18191d;
  }
  p {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 30px;

    color: #18191d;
  }
  .priceCoinFunds {
    margin-top: 2rem;

    overflow: scroll;

    height: calc(100% - 311px);
    margin-bottom: 2rem;
    margin-bottom: 2rem;
  }
  .labelfundscoin {
    height: 68px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    display: flex;
    align-items: center;
    text-align: center;

    color: #ffffff;
    justify-content: center;

    background: #9fd6df;
    border-radius: 10px;
  }
  .imgpricelist {
    display: flex;
    align-items: center;
    .sectionskeletonloger {
      width: 7rem;
      height: 1.5rem;
    }
  }
  .listofCoinPrice {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 81px;
    padding-left: 23px;
    cursor: pointer;
    padding-right: 23px;
    background: #ffffff;
    border: 0.5px solid #e7e7e7;
    border-radius: 15px;
    cursor: pointer;
    margin-bottom: 1rem;
    &:hover {
      transform: translateY(-5px);
    }
    img {
      height: 26.01px;
      margin-right: 10px;
    }
    h2 {
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 24px;

      margin-bottom: 0;

      color: #18191d;
    }
    h5 {
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 24px;
      margin-bottom: 0;
      color: #18191d;
    }
  }
}
.funds {
  width: 100%;
  overflow-y: hidden;
  position: absolute;
  inset: 0;
  overflow-x: hidden;
  label {
    background: linear-gradient(90deg, #81cbd7 0%, #3d4394 100%);
    opacity: 0.9;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 18px;
    display: flex;
    align-items: center;
    text-align: center;

    color: #ffffff;
    justify-content: center;
    border-radius: 25px;
    width: 171px;
    height: 47px;
    &:hover {
      transform: scale(1.1);
    }
  }
  .navhandling {
    width: 100%;
    display: flex;
    align-items: center;
  }
  .aiindex-navbar {
    width: 100%;
    height: 8%;
    justify-content: space-between;
    border-bottom: 0.5px solid #e7e7e7;
    display: flex;
    align-items: center;
    padding: 1rem 3rem;

    .aiindex-icon {
      width: 10%;
      height: 100%;
      display: flex;
      align-items: center;
      gap: 5px;
      font-size: 1.35rem;
      font-weight: 650;
      color: #18191d;
      img {
        width: 80%;
        height: 80%;
      }
    }

    .search-aiindex {
      width: 21rem;
      height: 100%;
      border: 0.5px solid #ededed;
      background: white;
      border-radius: 5px;
      padding: 0.5rem 2rem;
      display: flex;
      align-items: center;
     
      .all {
        width: 32%;
        height: 100%;
        border-right: 3px solid #e7e7e7;
        display: flex;
        align-items: center;
        font-size: 1rem;
        color: #18191d;
        font-weight: 700;
        opacity: 0.25;
        padding-right: 20px;
        justify-content: space-between;
        p {
          margin-bottom: 0;
        }
        .arroww {
          display: flex;
          align-items: center;
          img {
            width: 15px;
            height: 15px;
            cursor: pointer;
          }
        }
      }
      .im {
        width: 100%;
        height: 100%;
        // padding-left: 1rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 1rem;
        color: #18191d;
        font-weight: 700;
        // opacity: 0.25;
        gap: 1rem;
        input {
          border: none;
          font-size: 1rem;
          color: #18191d;
          font-weight: 700;
          background: transparent;
        }
      }
    }

    .aiindex-listBtn {
      padding: 0.5rem 1.5rem;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #9fd6df;
      border: 0.5px solid #9fd6df;
      border-radius: 25px;
      cursor: pointer;
      font-weight: 700;
      color: white;
      font-size: 1rem;
      margin-left: 20vw;
      white-space: nowrap;
    }
  }

  .w-space {
    width: 100%;
    height: 4%;
    padding: 0 3rem;
    border-bottom: 0.5px solid #e7e7e7;
    .optionss {
      width: 30%;
      height: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .each-option {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        p {
          margin-bottom: 0;
          color: #18191d;
          font-size: 0.9rem;
        }
        div {
          display: flex;
          gap: 5px;
          transition: all ease 0.3s;
          cursor: pointer;
          &:hover {
            scale: 1.1;
          }
        }
      }
      .top-icons {
        display: flex;
        align-items: center;
        height: 100%;
        div {
          height: 100%;
          padding: 0 2rem;
          display: flex;
          border-right: 0.25px solid #e7e7e7;
          align-items: center;
          img {
            height: 50%;
          }
        }
      }
    }
  }

  .funds-content {
    width: 100%;
    height: 88%;
    display: flex;
    position: relative;

    .left-fund-container {
      width: 100%;
      height: 100%;
      background: #fff;
      padding: 2.5rem 2.5rem 0;

      .cards-funds {
        // display: flex;
        // flex-wrap: wrap;
        // align-items: flex-start;
        display: flex;
        flex-wrap: wrap;
        gap: 1%;
        // height: 100%;
        // overflow: scroll;
        .ItemCards {
          width: 32.63%;
          cursor: pointer;
          background: #ffffff;
          border: 0.5px solid #e5e5e5;
          border-radius: 15px;
          height: 320px;
          margin-bottom: 1rem;
          .sectionskeletoncard1 {
            height: 5rem;
            width: 70%;
          }
          .sectionskeletoncard {
            height: 2rem;
            width: 50%;
          }

          &:hover {
            transform: translateY(-5px);
            box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
          }
          .SectionBottomFunds {
            padding-left: 5%;
            padding-right: 5%;
          }
          .ImageSection {
            display: flex;
            align-items: center;
            justify-content: space-between;
            position: absolute;
            width: 100%;
            top: -29px;
            padding-left: 5%;
            padding-right: 5%;
            width: 100%;
            img {
              height: 38.23px;
            }
            .Rightbox {
              width: 82px;

              height: 30px;
              font-family: 'Montserrat';
              font-style: normal;
              font-weight: 700;
              font-size: 12px;
              line-height: 15px;
              display: flex;
              align-items: center;
              text-align: center;
              color: #18191d;
              justify-content: center;
              background: #ffffff;
              border: 0.5px solid #e5e5e5;
              border-radius: 5px;
              cursor: pointer;
              &:hover {
                transform: scale(1.1);
              }
            }
            .leftbox {
              width: 62px;
              height: 62px;
              display: flex;
              align-items: center;
              justify-content: center;
              background: #ffffff;
              border: 0.5px solid #e5e5e5;
              border-radius: 5px;
            }
          }
          .bottomcard {
            position: relative;
          }
          .topCard {
            width: 100%;
            height: 88px;
            border-radius: 15px 15px 0px 0px;
            // background: #1ba27a;
          }
          .lowerSection {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-top: 20px;
          }
          .contentSection {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding-top: 52px;
          }
          .BottomValue {
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 600;
            font-size: 20px;
            line-height: 24px;

            text-align: center;

            color: #18191d;
          }
          .retunsValue {
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 700;
            font-size: 20px;
            line-height: 24px;

            color: #4caf50;
          }
          .BottomDetail {
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 400;
            font-size: 9px;
            line-height: 11px;
            margin-bottom: 7px;

            color: #18191d;
          }
          .fundDes {
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 300;
            font-size: 11px;
            margin-top: 18px;
            line-height: 20px;
            color: #18191d;
          }
          .fundsName {
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 700;
            font-size: 18px;
            line-height: 22px;
            color: #18191d;
          }
          .fundsvalue {
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 600;
            font-size: 18px;
            line-height: 22px;

            text-align: right;

            color: #18191d;
          }
        }
      }
    }
  }
}
