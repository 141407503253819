.mainGrid {
  display: flex;
  // grid-template-columns: 1fr 1fr;
  // grid-template-rows: 1fr;
  // grid-template-areas: "left right";
  height: 100vh;
  width: 100vw;
}
.leftSide {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 50%;
}
.rightSide {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  min-width: 50%;
}
.input1 {
  width: 50%;
  height: 7%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #ccc;
  border-radius: 35px;
  padding: 10px 23px;
  margin-bottom: 2%;
  font-size: 1.9vh;
  input {
    border: none;
    width: 83%;
    &::placeholder {
      font-size: 1.9vh;
    }
  }
  .closeImg {
    cursor: pointer;
    &:hover {
      transform: scale(1.05);
      transition: all 0.3s ease-in-out;
    }
  }
  .indicator {
    width: 10px;
    height: 10px;
    background: #dc3027;
    border: none;
    border-radius: 50%;
  }
  .eyeImg {
    cursor: pointer;
    &:hover {
      transform: scale(1.05);
      transition: all 0.3s ease-in-out;
    }
  }
}

.conditions {
  width: 50%;
  margin-top: 1rem;
  margin-bottom: 2rem;
  background: #fffdf2;
  border-radius: 15px;
  display: flex;
  flex-direction: column;

  .eachCondition {
    display: flex;
    width: 100%;
    padding: 15px 23px;
    align-items: center;
    justify-content: space-between;
    font-size: 0.9rem;
    font-weight: 450;
    .indicator {
      width: 10px;
      height: 10px;
      background: rgba(220, 48, 39, 0.37);
      border: none;
      border-radius: 50%;
    }
  }
}

.input2 {
  width: 50%;
  height: 7%;
  border: 1px solid #ccc;
  border-radius: 35px;
  padding: 10px 23px;
  margin-bottom: 2%;
  font-size: 1.9vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
  input {
    border: none;
    width: 85%;
    &::placeholder {
      font-size: 1.9vh;
    }
  }
  .eyeImg {
    cursor: pointer;
    &:hover {
      transform: scale(1.05);
      transition: all 0.3s ease-in-out;
    }
  }
}

.forgotPassword {
  background: white;
  -webkit-background-clip: text;
  background-clip: text;
  text-fill-color: transparent;
  font-size: 1.9vh;
  width: 50%;
  text-align: right;

  div {
    color: #18191d;
    cursor: pointer;
    background: white;
    -webkit-background-clip: text;
    background-clip: text;
    text-fill-color: transparent;
    &:hover {
      transform: scale(1.05);
      transition: all 0.3s ease-in-out;
    }
  }
}

.loginButton {
  background: linear-gradient(90deg, rgba(236, 181, 204, 1) 0%, rgba(142, 165, 213, 1) 100%);
  width: 50%;
  padding: 2%;
  color: white;
  font-weight: 700;
  border-radius: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 8%;
  cursor: pointer;
  font-size: 1.9vh;
  transition: all ease 0.2s;

  &:hover {
    transform: scale(1.05);
  }
}

.buttonDiv {
  display: flex;
  width: 50%;
  margin-top: 1rem;
  justify-content: space-between;
  .gbBtn {
    width: 49%;
    padding: 3%;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-size: 1.9vh;
    color: #18191d;
    border: 1px solid #e5e5e5;
    font-weight: 600;
    &:hover {
      transform: scale(1.05);
      transition: all 0.3s ease-in-out;
    }
  }
}

.loginButtonDisabled {
  background: linear-gradient(90deg, #81cbd7 0%, #3d4394 100%);
  width: 50%;
  padding: 2%;
  color: white;
  font-weight: 700;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 85px;
  cursor: pointer;
  opacity: 0.5;
  font-size: 1.9vh;
}

.googleLogin {
  width: 50%;
  padding: 2%;
  font-weight: 600;
  border-radius: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  border: 1px solid #e5e5e5;
  margin-top: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #18191d;
  font-size: 1.9vh;
  cursor: pointer;
  transition: all ease 0.2s;

  &:hover {
    transform: scale(1.05);
  }
}

.rightlabel {
  background: linear-gradient(90deg, #3d4394 0%, #81cbd7 91.36%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  font-weight: 600;
  font-size: 2vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.rightCard {
  padding: 4% 4%;
  margin-top: 4%;
  border: double 1px transparent;
  border-radius: 25px;
  background-image: linear-gradient(white, white),
    radial-gradient(circle at top left, #81cbd7, #3d4394);
  background-origin: border-box;
  background-clip: padding-box, border-box;
  height: 18%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  &:hover {
    transform: scale(1.05);
    transition: all 0.3s ease-in-out;
    cursor: pointer;
  }
}

.rightCardnoHover {
  padding: 4% 4%;
  margin-top: 4%;
  border: double 1px transparent;
  border-radius: 25px;
  background-image: linear-gradient(white, white),
    radial-gradient(circle at top left, #81cbd7, #3d4394);
  background-origin: border-box;
  background-clip: padding-box, border-box;
}

.rightCardTitle {
  background: linear-gradient(90deg, #3d4394 0%, #81cbd7 91.36%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  font-weight: 600;
  font-size: 2vh;
}
.rightCardSubTitle {
  font-weight: 400;
  font-size: 1.6vh;
  color: #18191d;
  margin-top: 2%;
  line-height: 25px;
}
.rightCardActive {
  padding: 4% 4%;
  margin-top: 4%;
  border: double 1px transparent;
  border-radius: 25px;
  background-image: linear-gradient(white, white),
    radial-gradient(circle at top left, #81cbd7, #3d4394);
  background-origin: border-box;
  background-clip: padding-box, border-box;
  height: 18%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  &:hover {
    transform: scale(1.05);
    transition: all 0.3s ease-in-out;
    cursor: pointer;
  }
}

.options {
  height: 75px;
  display: flex;
  align-items: center;
  padding-left: 35px;
  border: 0.5px solid #e5e5e5;
  margin-bottom: 4%;
  border-radius: 15px;
  font-size: 1.7vh;
  &:hover {
    transform: scale(1.05);
    transition: all 0.3s ease-in-out;
    cursor: pointer;
  }
}

.optionsDisabled {
  height: 75px;
  display: flex;
  align-items: center;
  padding-left: 35px;
  border: 0.5px solid #e5e5e5;
  margin-bottom: 4%;
  border-radius: 15px;
  font-size: 1.7vh;
}

// Mobile UI Styles

.mobileLogo {
  border-bottom: 1px solid #e5e5e5;
  padding: 5% 20%;
  background-color: white;
  height: 9vh;
  margin-bottom: 10vh;
  img {
    width: 100%;
  }
}

.stepContainer {
  height: 65vh;
  position: relative;
  .AffiliatePrompt {
    width: 100%;
    display: flex;
    gap: 10px;
    padding: 1rem 1.5rem;
    background: #fffdf2;
    border-radius: 15px;
    margin-bottom: 2vh;
    font-size: 0.85rem;
  }
}
.pageTitle {
  font-size: 15px;
  font-weight: 400;
  color: #18191d;
  // margin-top: 10%;
  margin-bottom: 24px;
}
.optionCards {
  display: flex;
  align-items: center;
  margin-bottom: 5%;
  border: 1px solid #e5e5e5;
  border-radius: 15px;
  height: 84px;
  padding-left: 30px;
  font-size: 1.8vh;
  font-weight: 600;
  input {
    border: none;
    width: 90%;
    height: 100%;
  }
}
.optionCards1 {
  display: flex;
  align-items: center;
  margin-bottom: 5%;
  border: 1px solid #e5e5e5;
  border-radius: 15px;
  height: 67px;
  padding-left: 30px;
  font-size: 1.8vh;
  font-weight: 600;
  input {
    border: none;
    width: 90%;
    height: 100%;
  }
}
.buttonWrapper {
  position: absolute;
  bottom: 20px;
  width: 100%;
  // background-color: red;
}
.buttonWrapper1 {
  margin-top: 10vh;
}
.backButton {
  background-color: #7899cf;
  border-radius: 15px;
  width: 100%;
  height: 67px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-weight: 700;
  font-size: 20px;
}
.continueButton {
  background: #18191d;
  border-radius: 15px;
  width: 100%;
  height: 67px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-weight: 700;
  font-size: 20px;
  margin-bottom: 20px;
}
.loadingComponent {
  position: absolute;
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
  background-color: black;
  opacity: 0.4;
}
.redDot {
  width: 12px;
  height: 12px;
  background-color: red;
  border-radius: 50%;
}

.greenDot {
  width: 12px;
  height: 12px;
  background-color: green;
  border-radius: 50%;
}
.passwordChecklist {
  background-color: #fffdf2;
  border-radius: 15px;
  padding: 20px;
  position: relative;

  .eachChecklist {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    .indicator {
      width: 10px;
      height: 10px;
      background: rgba(220, 48, 39, 0.37);
      border: none;
      border-radius: 50%;
    }
  }
}
.checklistLabel {
  text-decoration: underline;
}
.otpInputWrapper {
  width: 75%;
  justify-content: space-between;
  > div {
    flex: 0 0 22%;
    height: 60px;
    .otpInput {
      width: 80% !important;
      height: 100%;
      border: 2px solid #e5e5e5;
      border-radius: 8px;
      font-size: 28px;
    }
  }
}
.gradientBrn{
  background: linear-gradient(90deg, rgba(236, 181, 204, 1) 0%, rgba(142, 165, 213, 1) 100%);
  height: 67px;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  font-weight: 700;
  color: white;
}
.refField{
  height: 49px;
  border-radius: 50px;
  font-size: 13px;
  font-weight: 400;
  background-color: #e7e7e7;
  display: flex;
  align-items: center;
  padding: 0px 18px;
}