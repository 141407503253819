.model-image-header {
  width: 416px;
  height: 71px;
  display: flex;
  align-items: center;
  justify-content: center;
  //background: #18191d;
  border-radius: 25px 25px 0px 0px;
  border-bottom: 0.5px solid #e7e7e7;
  img {
    height: 31.79px;
  }
}

.model-main {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 416px;
  height: 71px;
  border: 0.5px solid #e7e7e7;
  height: 296px;
  z-index: 9999;
  background: #fff;
  border-radius: 25px 25px 0px 0px;
  .inside-section {
    height: 222px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }
  h1 {
    width: 317px;

    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 35px;
    /* or 175% */

    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    color: #182542;
  }

  .close {
    width: 148px;
    height: 42px;
    margin-top: 2rem;
    margin-bottom: 0px;
    background: #18191d;
    border-radius: 15px;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 35px;
    /* or 194% */

    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    color: #ffffff;
    cursor: pointer;
    &:hover {
      transform: scale(1.1);
    }
  }
}
.playbutton {
  width: 121px;
  height: 36.41px;
  display: flex;
  align-items: center;
  justify-content: center;

  background: #ffffff;
  border: 0.5px solid #e7e7e7;
  border-radius: 15px;
}
.toggle-play {
  position: absolute;
  padding: 30px;

  width: 100%;
  height: 100vh;
  border: 1.5px solid #e7e7e7;
  z-index: 999;
  background-color: #fff9;
  top: 0;
  margin: auto;
  right: 0%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  font-size: 17px;
  line-height: 35px;

  color: #18191d;
  svg {
    height: 24rem !important;
    width: 34rem !important;
    position: absolute;
    left: 42%;
    top: 37%;
    z-index: 9;
  }
  div {
    height: auto !important;
    height: 24rem !important;
    width: 34rem;
    margin: 0;
    padding-left: 0px !important;
    padding: 0;
    padding-top: 0px !important;
  }
  .yes {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 95px;
    background: #18191d;
    color: white;
    font-size: 12px;
    border-radius: 8px;
    height: 34px;
    display: flex;
    align-items: center;
    justify-content: center;
    right: 150px;
    bottom: 30px;
    cursor: pointer;
    &:hover {
      transform: scale(1.1);
    }
  }
  .no {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 95px;
    right: 30px;
    background: #18191d;
    color: white;
    font-size: 12px;
    border-radius: 8px;
    height: 34px;
    display: flex;
    align-items: center;
    justify-content: center;
    bottom: 30px;
    cursor: pointer;
    &:hover {
      transform: scale(1.1);
    }
  }
}
.metaDashboard {
  overflow: scroll;
  height: calc(100vh - 122px);
  padding-bottom: 0.1rem;
  background: #f5f5f5;

  .sectionContainer {
    width: 100%;
    overflow: scroll;
    background: white;

    .tableHeader {
      display: flex;
      border-bottom: 0.5px solid #e7e7e7;
      padding-left: 30px;
      .section {
        width: 27%;
        background-color: #fafafa;
        padding: 13px 25px 13px 0px;

        &:nth-child(4) {
          width: 13%;
        }

        p {
          font-family: "Montserrat";
          font-style: normal;
          font-weight: 500;

          font-size: 10px;
          line-height: 18px;
          display: flex;
          align-items: center;
          margin-bottom: 0;
          color: #18191d;
        }
      }
    }

    .metaBody {
      .tableBody {
        display: flex;
        border-bottom: 0.5px solid #e7e7e7;

        padding: 25px;
        padding-left: 30px;
        background-color: #ffff;
        padding-right: 0;
        .content {
          width: 27%;

          &:nth-child(4) {
            width: 13%;
          }

          .info {
            display: flex;

            &:hover {
              transform: scale(1.1);
              transition-duration: 1s;
            }

            img {
              width: 32px;
              height: 32px;
              margin-right: 8px;
            }

            .App-detail {
              h1 {
                font-family: "Montserrat";
                font-style: normal;
                font-weight: 700;
                font-size: 16px;
                height: 20px;
                line-height: 20px;
                display: flex;
                align-items: center;
                color: #18191d;
                margin-bottom: 0;
              }

              h5 {
                font-family: "Montserrat";
                font-style: normal;
                font-weight: 300;
                font-size: 11px;
                line-height: 13px;

                display: flex;
                align-items: center;
                color: #18191d;
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: "600px") {
  .metaDashboard {
    .sectionContainer {
      .tableHeader {
        .section {
          width: 200px;

          &:nth-child(4) {
            width: 200px;
          }

          p {
            white-space: nowrap;
            width: 200px;
          }
        }
      }

      .metaBody {
        .tableBody {
          display: flex;
          border-bottom: 0.5px solid #e7e7e7;
          padding: 25px;
          background-color: #ffff;

          .content {
            width: 200px;

            &:nth-child(4) {
              width: 200px;
            }

            .info {
              width: 200px;
            }
          }
        }
      }
    }
  }
}
