.search-layout-container {
  display: flex;
  flex-direction: column;
  flex: 1;
  color: #18191d;
  .search-input-container {
    display: flex;
    border-bottom: 1px solid #e7e7e7;
    align-items: center;
    .back-button {
      width: 25px;
      height: 25px;
      cursor: pointer;
      margin-left: 25px;
    }
    .search-input {
      flex: 1;
      padding: 0 25px;
      height: 100%;
      border: none;
    }
    .action-container {
      border-left: 1px solid #e7e7e7;
      padding: 25px;
      .action-icon {
        width: 25px;
        height: 25px;
      }
    }
  }
  .list-container {
    height: 0;
    flex-grow: 1;
    overflow-y: auto;
    .list-item {
      display: flex;
      padding: 20px 50px;
      cursor: pointer;
      .item-details {
        margin-left: 10px;
        .item-name {
          font-weight: 600;
        }
        .item-email {
          font-size: 0.7rem;
        }
      }
    }
  }
}
