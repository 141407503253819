.transactionsContainer {
  border: 2px solid red;
  overflow-y: scroll;
  height: 100%;
  // display: none;
}
.vaults-itm {
  display: flex;
}
.vaults-itm > img {
  height: 100%;
  max-width: 30%;
  object-fit: contain;
}
