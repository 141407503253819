.transaction-layout {
  width: 100%;
  flex-grow: 1;
  height: 100%;
  .side-bar {
    max-width: 22%;
    width: 22%;
    min-width: 22%;
    height: 100%;
    border-right: 0.5px solid $vault-border-color;
    background: white;
    top: 0;
    left: 0;
    transition: all 400ms ease;

    .actionMenu-btmItm {
      background: #ffffff;
      width: 100%;
      padding: 0 1.85rem;
      height: 4rem;
      font-size: 1.1rem;
      font-weight: 700;
      display: flex;
      margin-bottom: 2rem;

      .closee-btn {
        background: #7899cf;
        color: #ffffff;
        width: 100%;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 25px;
        transition: all ease 0.2s;

        &:hover {
          transform: scale(1.05);
        }
      }
    }

    .toolTip {
      display: none;
    }
    .logoutBtn {
      display: flex;
      align-items: center;
      justify-content: center;
      color: $primary-color;
      border-top: 1px solid $border-color;
      height: 65px;
      min-height: 65px;
      font-size: 25px;
      font-weight: bold;
      svg {
        height: 24px;
        margin-right: 6px;
      }
    }
    .profile {
      height: 8%;
      // padding: 10%;
      padding: 1rem 1.85rem;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      // border-bottom: 0.5px solid #E5E5E5;
      img {
        width: 60%;
        height: 60%;
      }

      h5 {
        color: #18191d;
        margin-bottom: 6px;
        font-weight: bold;
        font-size: 26px;
      }
      .getStartedBtn {
        font-size: 16px;
        font-weight: 600;
        cursor: pointer;
        &:hover {
          color: black;
        }
      }
      .currencySelect {
        display: inline-block;
        width: fit-content;
        min-width: 75%;
        cursor: pointer;
        color: #18191d;
        border: 1px solid $vault-border-color;
        padding: 8px 10px;
        font-weight: normal;
        line-height: 1;
        margin: 0;
        display: flex;
        align-items: center;
        img {
          background: none;
          height: 16px;
          width: 16px;
          margin-right: 6px;
        }
        h6 {
          font-size: 14px;
          flex-grow: 1;
          display: flex;
          justify-content: space-between;
          margin: 0;
        }
      }
      .toggle {
        color: white;
        z-index: 1;
        height: 28px;
        width: 28px;
        position: absolute;
        top: 85%;
        right: 0;
        margin: auto;
        border-radius: 50%;
        border: 1px solid $border-color;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        transform: translateX(50%);
        transform-origin: center;
        transition: all 400ms ease;
        &:hover {
          transform: translateX(50%) scale(1.2);
        }
      }
    }

    .menu-scrl {
      // margin-top: 3rem;
      height: calc(100% - 8%) !important;
      .menu-side {
        justify-content: center;
        display: flex;
        flex-direction: column;
        overflow-y: auto;
        justify-content: flex-start;
        height: 100%;
        .spacer {
          height: 1px;
          min-height: 1px;
          width: 100%;
        }

        .header-boxx {
          width: 100%;
          // height: 3.5rem;
          height: 5.65%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 0 1rem 0 1.85rem;
          border-bottom: 0.5px solid #e7e7e7;
          cursor: pointer;
          transition: all ease 0.2s;

          &:hover {
            background: #e7e7e7;
          }

          & > :nth-child(1) {
            font-weight: 450;
            font-size: 0.9rem;
          }

          .text-container {
            display: flex;
            align-items: center;
            gap: 10px;

            img {
              cursor: pointer;
              transition: all ease 0.2s;

              &:hover {
                transform: scale(1.2);
              }
            }
          }
        }

        .menu-itm {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          img {
            width: 40px;
            height: 40px;
          }
          .sidebar-icon-label {
            // width: 115px;
            font-weight: 550;
            font-size: 12px;
            line-height: 20px;
            display: flex;
            align-items: center;
            color: #18191d;
            margin-top: 15px;
          }

          .sidebar-icon-label1 {
            // width: 115px;
            font-weight: 550;
            font-size: 12px;
            line-height: 20px;
            display: none;
            align-items: center;
            color: #18191d;
            margin-top: 15px;
          }

          .sidebar-icon-label1-collapsed {
            visibility: hidden;
            position: absolute;
            right: -90px;
            background: #ffffff;
            border: 1px solid #e5e5e5;
            border-radius: 25px;
            padding: 0.5rem;
            width: 110px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 0;
            transition: all ease-in 0.4s;

            &:hover {
              scale: 1.1;
              background: #18191d;
              color: white;
            }
          }
          &:hover .sidebar-icon-label1-collapsed {
            visibility: visible;
          }

          cursor: pointer;
          text-decoration: none !important;
          opacity: 1;
          width: calc(100% / 3);
          border-right: 0.5px solid #ebebeb;
          border-bottom: 0.5px solid #ebebeb;
          // height: calc(8rem + 1.9px);
          height: 14.4%;
          h5 {
            line-height: 1;
            font-weight: normal;
            color: #18191d;
            text-decoration: none;
            margin-bottom: 0;
            height: 60px;
            margin-top: -20px;
            img {
              margin-left: 20px;
              height: 30px;
              width: auto;
              display: flex;
              align-content: flex-start;
              margin-right: 1.5rem;
              transition: all 400ms ease;
              transform-origin: 0 center;
              margin-top: 20px;
            }
            span {
              display: none;
            }
          }
        }

        .bottomboxx {
          width: 100%;
          display: flex;
          flex-direction: column;
          height: 55%;
          color: #18191d;

          .tabss {
            width: 100%;
            height: 3rem;
            border-bottom: 0.5px solid #e7e7e7;
            display: flex;

            .tabss1 {
              width: 90%;
              height: 100%;
              display: flex;
              border-right: 0.5px solid #e7e7e7;
              justify-content: space-around;
              & > div {
                width: 40%;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 1.1rem;
                font-weight: 100;
                cursor: pointer;
                transition: all ease 0.2s;

                p {
                  margin-bottom: 0;
                  transition: all ease 0.2s;
                  &:hover {
                    scale: 1.1;
                  }
                }

                // .applyclass {
                //   font-size: 0.95rem;
                //   font-weight: 600;
                //   border-bottom: 0.5px solid #464b4e;
                //   opacity: 1;
                // }
              }
            }

            .filterrr {
              width: 10%;
              height: 100%;
              display: flex;
              justify-content: center;
              align-items: center;
              cursor: pointer;
              transition: all ease 0.2s;
              img {
                scale: 1;
              }
              &:hover {
                scale: 1.2;
              }
            }
          }

          .tabss-data {
            width: 100%;
            height: calc(100% - 3rem);

            .contennt {
              width: 100%;
              padding: 0 1.85rem;
              display: flex;
              flex-direction: column;
              height: calc(100% - 3rem);
              // justify-content: space-evenly;

              .tabss-data1 {
                width: 100%;
                display: flex;
                align-items: center;
                // justify-content: space-between;

                .coinndata {
                  padding-left: 5px;
                  font-size: 1.7rem;
                  font-weight: 700;
                }
              }

              .tabss-data2 {
                font-size: 0.95rem;
                .clickHere {
                  color: #18191d;
                  font-weight: 600;
                  cursor: pointer;
                  transition: all ease 0.2s;
                  text-decoration: underline;
                  &:hover {
                    font-weight: 800;
                    color: #212529;
                    text-decoration: underline;
                  }
                }
              }

              .tabss-data-custom {
                font-size: 1.1rem;
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 20px 0px;
                border-bottom: 1px solid #e7e7e7;
                .tabimg {
                  img {
                    height: 25px;
                  }
                  &:hover {
                    cursor: pointer;
                    transform: scale(1.1);
                    transition: all ease 0.2s;
                  }
                }
              }

              .tabss-data3 {
                width: 100%;
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                & > div {
                  width: 45%;
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                  border: 0.5px solid #ebebeb;
                  font-size: 0.7rem;
                  font-weight: 100;
                  padding: 0.5rem 0;

                  .fs {
                    font-size: 1.5rem;
                    font-weight: 700;
                  }
                }
              }

              .vault-contennt {
                /* height: 4rem; */
                width: 95%;
                border-bottom: 0.5px solid #e5e5e5;
                padding: 2.25rem 0;
                cursor: pointer;
                transition: all ease 0.2s;
                .heading-vault {
                  font-size: 1.25rem;
                  font-weight: 650;
                }
                .subb {
                  font-size: 0.9rem;
                }
                .fundcrypto {
                  font-size: 1.25rem;
                  font-weight: 650;
                  display: flex;
                  align-items: center;
                  gap: 10px;
                  img {
                    width: 30px;
                    height: 30px;
                  }
                }
                &:hover {
                  transform: scale(1.07);
                }
              }
            }

            .tabss-footer {
              display: flex;
              width: 100%;
              height: 3rem;
              & > div {
                width: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
                color: #ffffff;
                font-size: 1.15rem;
                font-weight: 650;
                cursor: pointer;
                transition: all ease 0.2s;

                &:hover {
                  font-size: 1.35rem;
                }
              }
            }
          }

          .profilee {
            padding: 2rem;
            display: flex;

            .profilee-txt1 {
              font-size: 1.25rem;
              font-weight: 700;
            }

            .profilee-txt2 {
              font-size: 0.9rem;
            }
          }
        }

        .submenu {
          padding-left: 20px;
        }
      }
    }
    .assetPriceWrapper {
      flex: 0 0 40%;
      display: flex;
      flex-direction: column;
      .tab-inrest-asset {
        display: flex;
        border: 1px $vault-border-color;
        border-style: solid none none none;
        height: 65px;
        .tab-itm {
          position: relative;
          cursor: pointer;
          width: 0;
          flex: 1;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 14px 6px;
          border-bottom: 2px solid $vault-border-color;
          font-size: 14px;
          .backIcon {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 30px;
            margin: auto;
            font-size: 18px;
          }
          &.title {
            justify-content: start;
            padding-left: 50px;
            font-size: 20px;
            font-weight: 600;
          }
          &.settings {
            opacity: 1;
            width: 54px;
            min-width: 54px;
            max-width: 54px;
            border-left: 1px solid #e7e7e740;
            border-color: #e7e7e7;
            &.true {
              background: $primary-color;
              img {
                filter: brightness(0) invert(1);
              }
            }
          }
          &.false {
            opacity: 0.25;
          }
          &.true {
            opacity: 1;
            border-color: $primary-color;
          }
        }
      }
      .rate-list-wrapper {
        height: 0 !important;
        flex-grow: 1;
        .rates-list {
          display: flex;
          flex-direction: column;
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          overflow-y: auto;
          .coin {
            flex: 0 0 33.33%;
            display: flex;
            padding: 35px 50px;
            border-bottom: 0.5px solid $vault-border-color;
            align-items: center;
            .coin-logo {
              margin: auto;
              margin-left: 0;
              height: 20px;
            }
            .coin-name {
              font-size: 17px;
            }
            .rate {
              margin: auto;
              margin-right: 0;
              text-align: center;
              font-size: 17px;
              small {
                margin-left: 8px;
                font-size: 10px;
                color: #3ea154;
                margin-top: auto;
                svg {
                  margin-left: 4px;
                  height: 11px;
                }
                &.true {
                  color: #da3a3a;
                  svg {
                    transform: scale(-1);
                    path {
                      fill: #da3a3a;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    .settings-wrapper {
      .settings-list {
        .settingsMenu {
          cursor: pointer;
          position: relative;
          flex: 0 0 33.33%;
          padding: 0 50px;
          border-bottom: 1px solid $vault-border-color;
          display: flex;
          color: $primary-color;
          align-items: center;
          font-size: 18px;
          font-weight: bold;
          justify-content: space-between;
          img {
            height: 20px;
            width: 20px;
            margin: auto;
            position: absolute;
            top: 0;
            bottom: 0;
            right: 40px;
          }
        }
      }
    }
    .setNewCurency {
      margin-top: 50px;
      border: 1px $vault-border-color;
      border-style: solid none solid none;
      padding: 10px 50px;
      font-weight: bold;
    }
    .defCoinList {
      height: 320px;
      max-height: 320px;
      .coins-list {
        display: flex;
        flex-direction: column;
        .coin {
          flex: 0 0 33.33%;
          position: relative;
          cursor: pointer;
          display: flex;
          padding: 35px 50px;
          border-bottom: 0.5px solid $vault-border-color;
          align-items: center;
          .coin-logo {
            margin: auto;
            margin-left: 0;
            height: 20px;
          }
          .coin-name {
            font-size: 17px;
          }
          .select {
            position: absolute;
            top: 0;
            bottom: 0;
            right: 20px;
            margin: auto;
            height: 25px;
            width: 25px;
          }
        }
      }
    }
    .logout {
      height: 55px;
      background: #8b8b8b;
      padding: 0 15px;
      display: flex;
      align-items: center;
      h5 {
        color: white;
        font-size: 22px;
        line-height: 1;
        font-weight: 600;
        margin: auto;
        text-align: center;
      }
    }
    &.collapse {
      z-index: 3;
      width: 105px;
      min-width: 105px;
      max-width: 105px;
      .toolTip {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 85%;
        margin: auto;
        height: 30px;
        width: 140px;
        background: white;
        border: 1px solid $vault-border-color;
        font-size: 17px;
        justify-content: center;
        align-items: center;
        color: $primary-color;
        z-index: 10;
        font-size: 12px;
        font-weight: 600;
      }
      .profile {
        padding: 0;
        // margin-top: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        img {
          width: 50px;
          margin: auto;
        }
        h5,
        .getStartedBtn,
        .col {
          display: none;
        }
        &:hover {
          .toolTip {
            display: none;
          }
        }
      }
      .menu-scrl {
        margin: 0;
        margin-top: 32px;
        .menu-side {
          position: relative;
          padding: 0 5px;
          .menu-itm {
            padding: 0 20px;
            height: 35px;
            position: relative;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin: 2rem 0 2rem 0;
            width: unset;
            border: unset;

            h5 {
              img {
                height: auto;
                width: 30px;
                opacity: 0.25;
                margin-top: 18px;
                transform-origin: center;
              }
              span {
                display: none;
              }
            }
            &:hover,
            &.active {
              opacity: 1;
              h5 {
                img {
                  transform: scale(1.2);
                  opacity: 1;
                }
              }
            }
            &:hover {
              .toolTip {
                display: none;
              }
            }
          }
          .bottomboxx {
            display: none;
          }
        }
      }
      .assetPriceWrapper {
        flex: unset;
        display: flex;
        flex-direction: column;
        .tab-inrest-asset {
          display: flex;
          flex-direction: column;
          height: unset;
          .tab-itm {
            display: none;
            // &.settings {
            //   background: #e7e7e7;
            //   max-width: unset;
            //   width: unset;
            //   opacity: 1;
            //   display: flex;
            //   justify-content: center;
            //   align-items: center;
            //   border-color: #e7e7e740;
            //   img {
            //     height: 30px;
            //     width: 30px;
            //   }
            // }
          }
        }
        .rate-list-wrapper {
          display: none;
        }
      }
      .logoutBtn {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 24px 6px;
        color: $primary-color;
        svg {
          height: 30px;
          width: 30px;
        }
        span {
          display: none;
        }
      }
    }
    &.admin {
      .userFind {
        flex: 1;
        padding: 30px;
        display: flex;
        flex-direction: column;
        .searchWrap {
          height: 60px;
          width: 100%;
          position: relative;
          border: 1px solid $vault-border-color;
          display: flex;
          .searchInp {
            width: 0;
            flex: 1;
            background: none;
            border: none;
            padding: 0 30px;
          }
          .box {
            position: absolute;
            right: 15px;
            top: 0;
            bottom: 0;
            margin: auto;
            height: 35px;
            width: 35px;
            display: flex;
            align-items: center;
            justify-content: center;
            img {
              height: 80%;
              width: 80%;
            }
          }
        }
        .userList {
          margin: 30px 0;
          > div {
            padding-right: 1px;
            .viewAsUserItem {
              height: 90px;
              padding: 0 20px;
              border: 1px solid $border-color;
              display: flex;
              align-items: center;
              margin-bottom: 20px;
              transition: all 400ms ease;
              cursor: pointer;
              > div,
              img {
                transition: all 400ms ease;
              }
              .profileImg {
                height: 60px;
                width: 60px;
                border-radius: 30px;
                margin-right: 15px;
              }
              .nameEmail {
                .name {
                  font-size: 17px;
                  font-weight: 600;
                  display: flex;
                  align-items: center;
                }
                .email {
                  font-size: 13px;
                  display: flex;
                  align-items: center;
                }
                svg {
                  margin-left: 5px;
                }
              }
              &:hover {
                background: whitesmoke;
                > div,
                img {
                  transform: scale(1.05);
                }
              }
            }
          }
        }
      }
    }

    .menu-bottom-item {
      border: 0.5px solid #e7e7e7;
      height: 6rem;
      font-size: 13px;
      border-top: none;
      h5 {
        font-size: 18px;
        font-weight: 700;
        margin-bottom: 0;
      }
      img {
        cursor: pointer;
        transition: all 400ms ease;
        &:hover {
          transform: scale(1.1);
        }
      }
    }

    .profile-icon {
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        height: 33px;
        width: 33px;
        transition: all 400ms ease;
        border-radius: 25px;
        margin-left: 10px;
      }
      &:hover {
        img {
          transform: scale(1.1);
        }
      }
    }

    .sidebar-menu {
      height: 100%;
      overflow-y: scroll;
      border-bottom: 1px solid #e7e7e7;
      padding-bottom: 20px;
      .breadCrumbs {
        display: flex;
        align-items: center;
        margin-top: 16px;
        margin-left: 25px;
        padding: 0 20px;
        height: 5%;
        span {
          font-size: 11px;
          line-height: 1;
          cursor: pointer;
          &.gt {
            margin: 0 4px;
          }
          &:last-child {
            font-weight: 600;
            text-decoration: underline;
          }
          &:hover {
            font-weight: 700;
          }
        }
      }

      .newBreadcrumb {
        display: flex;
        width: 100%;
        height: 4.3%;
        align-items: center;
        justify-content: flex-start;
        gap: 10px;
        p {
          border: 0.5px solid #e5e5e5;
          border-radius: 5px;
          font-size: 0.8rem !important;
          font-weight: 600 !important;
          padding: 5px 15px;
          transition: all ease 0.2s;
          &:hover {
            transform: scale(1.04);
          }
        }
      }

      .selectt1 {
        padding-left: 1.85rem;
        font-size: 1.1rem;
        font-weight: 600;
        height: 4.5rem;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        border-bottom: 0.5px solid #e5e5e5;
      }

      .selectt {
        padding-left: 1.85rem;
        font-size: 1.1rem;
        font-weight: 600;
        height: 4.5rem;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        border-bottom: 0.5px solid #e5e5e5;
        transition: all ease 0.2s;
        &:hover {
          transform: scale(1.07);
        }
      }

      .assets-div {
        width: 100%;
        height: calc(100% - 4.5rem);
        overflow-y: scroll;

        .each-asset {
          display: flex;
          border-bottom: 0.5px solid #e5e5e5;
          padding-left: 1.85rem;
          height: 4.5rem;
          width: 100%;
          gap: 10px;
          align-items: center;
          justify-content: flex-start;
          transition: all ease 0.2s;
          cursor: pointer;
          img {
            width: 25px;
            height: 25px;
          }
          p {
            margin-bottom: 0;
          }
          &:hover {
            background: #e5e5e5;
          }
        }
      }

      .enter-div {
        height: 4.5rem;
        width: 100%;
        display: flex;
        border-bottom: 0.5px solid #e5e5e5;
        img {
          width: 30px;
          height: 30px;
        }
        align-items: center;
        justify-content: space-between;
        padding-left: 1.85rem;
        padding-right: 1.5rem;
        input {
          border: none;
          background: transparent;
        }
      }

      .fee-details {
        width: 100%;
        padding: 0 1.85rem;
        .fee-txt {
          padding: 2.5rem 0 1.5rem;
          border-bottom: 0.5px solid #e5e5e5;
          font-size: 1.15rem;
          width: 100%;
          display: flex;
          justify-content: space-between;
          font-weight: 400;
        }
        .fee-percentage {
          width: 100%;
          padding: 2rem 0 1rem 0;
          display: flex;
          justify-content: space-between;
          align-items: center;
          font-size: 0.9rem;
          font-weight: 100;
        }
      }

      .congts {
        padding: 2.5rem 1.85rem 3rem;
        line-height: 2;
        font-weight: 100;
      }

      .new-txt {
        font-size: 1.1rem;
        font-weight: 400;
        padding-left: 1.85rem;
        padding-bottom: 0.5rem;
      }

      .new-bal {
        width: 100%;
        padding: 0 1.85rem;
        height: 4.5rem;
        margin-bottom: 3rem;
        .inp {
          width: 100%;
          height: 100%;
          border: 0.5px solid #e5e5e5;
          border-radius: 15px;
          padding-left: 1.5rem;
          font-size: 1.15rem;
          display: flex;
          align-items: center;
        }
      }

      .newbtns-div1 {
        width: 100%;
        height: 10rem;
        padding: 1.5rem;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        position: absolute;
        bottom: 0;
        border-top: 0.5px solid #e5e5e5;

        .top-btn1 {
          display: flex;
          width: 100%;
          color: #ffffff;
          background: #9fd6df;
          font-size: 1.1rem;
          font-weight: 700;
          padding: 0.75rem 2.2rem;
          border-radius: 25px;
          justify-content: center;
          cursor: pointer;
          transition: all ease 0.2s;
          &:hover {
            transform: scale(1.05);
          }
        }

        .bottom-btn1 {
          display: flex;
          width: 100%;
          padding: 0.75rem 2.2rem;
          color: #ffffff;
          background: #7899cf;
          justify-content: center;
          border-radius: 25px;
          font-weight: 700;
          cursor: pointer;
          transition: all ease 0.2s;
          &:hover {
            transform: scale(1.05);
          }
        }
      }

      .qr-code-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 5rem;
        position: absolute;
        top: 40%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        img {
          margin-top: -0.5rem;
        }
        h2 {
          font-weight: 600;
        }
      }

      .add-content {
        line-height: 3rem;
        font-weight: 400;
        font-size: 18px;
      }

      .address-input {
        width: 22rem;
        height: 4rem;
        overflow: hidden;
        text-overflow: ellipsis;
        background-color: white;
        padding: 2rem;
        border: 0.5px solid #ebebeb;
        border-radius: 10px;
        font-weight: 400;
      }

      .coin-address-loading {
        display: flex;
        margin: 0 auto;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }

      .generate-btn {
        width: 18rem;
        height: 4rem;
        background-color: white;
        border: 0.5px solid #ebebeb;
        border-radius: 10px;
        &:hover {
          transform: scale(1.1);
          transition: all 300ms ease-in-out;
        }
      }

      .manual-entry {
        width: 90%;
        display: flex;
        flex-direction: column;
        margin-left: 30px;
        padding: 1rem;
        h4 {
          margin-top: 2rem;
          margin-bottom: 2rem;
          font-weight: 700;
        }
      }
      .enter-hash {
        word-break: break-word;
        font-weight: 300;
        font-size: 13px;
        line-height: 2rem;
        b {
          font-weight: 700;
        }
      }

      .sidebar-container {
        cursor: pointer;
        height: 10rem;
        font-size: 13px;
        h5 {
          font-size: 15px;
          font-weight: 700;
        }
        .sidebar-content-icon {
          border-radius: 50%;
          padding: 1rem;
        }
        .action-label {
          font-size: 20px;
          margin-bottom: 0px;
          color: #18191d;
        }
        .action-sublabel {
          font-size: 11px;
          width: 90%;
          color: #18191d;
        }
      }
      .sidebar-spacer {
        border-bottom: 0.5px solid #e5e5e5;
        width: 80%;
        margin: 0 auto;
      }
    }

    .tabsss {
      width: 100%;
      height: 7rem;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 1.85rem;

      .close-button {
        background: #7899cf;
        color: #ffffff;
        height: 3.65rem;
        font-size: 1.1rem;
        font-weight: 700;
        width: 100%;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 25px;
        transition: all 0.2s ease;

        &:hover {
          transform: scale(1.07);
        }
      }

      // .tabss1 {
      //   width: 85%;
      //   height: 100%;
      //   display: flex;
      //   border-right: 0.5px solid #e7e7e7;
      //   justify-content: space-around;
      //   & > div {
      //     width: 40%;
      //     display: flex;
      //     align-items: center;
      //     justify-content: center;
      //     font-size: 1.1rem;
      //     font-weight: 100;
      //     cursor: not-allowed;
      //     opacity: 0.5;
      //   }
      // }

      // .filterrr {
      //   width: 15%;
      //   height: 100%;
      //   display: flex;
      //   justify-content: center;
      //   align-items: center;
      //   cursor: pointer;
      //   transition: all ease 0.2s;
      //   img {
      //     scale: 1.5;
      //   }
      //   &:hover {
      //     scale: 1.2;
      //   }
      // }
    }

    .share-copy-wrapper {
      height: 6rem;
    }

    .share-copy-div {
      border: 0.5px solid #ebebeb;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }

    .close-control-panel {
      height: 4rem;
      border: 0.5px solid #ebebeb;
      h5 {
        margin-top: 0.5rem;
        color: #ffffff;
        font-weight: 600;
        &:hover {
          transition: all 300ms ease-in-out;
          transform: scale(1.1);
        }
      }
    }
  }

  .verificationStatus {
    background: #6e45b7;
    padding: 11px 25px;
    font-weight: 700;
    font-size: 12px;
    color: white;
    border-radius: 5px;
    // display: flex;
    transition: all ease 0.2s;
    cursor: pointer;

    &:hover {
      transform: scale(1.1);
    }
  }

  .clickable {
    h5,
    img {
      &:hover {
        cursor: pointer;
        transition: all 400ms ease;
        transform: scale(1.1);
      }
    }
  }

  .clickable-div {
    cursor: pointer;
    &:hover {
      transition: all 400ms ease;
      transform: scale(1.1);
    }
  }

  .coin-value {
    min-width: 150px;
  }

  .add-nextbtn {
    height: 4rem;
    width: 50%;
    padding: 20px;
    text-align: center;
    cursor: not-allowed;
    opacity: 0.3;

    .hoverrr {
      width: 80%;
      height: 100%;
      transition: all 0.3s ease-in-out;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 10px;
      h6 {
        color: white;
      }
      &:hover {
        transform: scale(1.1);
      }
    }
  }

  .transfer-footer {
    background-color: white;
    border-top: 1px solid #e7e7e7;
    border-right: 1px solid #e7e7e7;
    position: fixed;
    bottom: 0;
    height: 4rem;
    width: 22%;
    display: flex;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    .back1 {
      height: 4rem;
      width: 50%;
      padding: 20px;
      color: #18191d;
      background-color: white;
      text-align: center;
      cursor: pointer;
      h6 {
        &:hover {
          transform: scale(1.3);
          transition: all 0.3s ease-in-out;
        }
      }
    }

    .nextbtn {
      height: 4rem;
      width: 50%;
      padding: 20px;
      color: rgba(209, 206, 206, 0.782);
      text-align: center;
      cursor: not-allowed;
      opacity: 0.3;
      h6 {
        color: white;
        &:hover {
          transform: scale(1.3);
          transition: all 0.3s ease-in-out;
        }
      }
    }

    .nextbtn1 {
      height: 4rem;
      width: 50%;
      padding: 20px;
      color: rgba(209, 206, 206, 0.782);
      text-align: center;
      cursor: not-allowed;
      opacity: 0.3;
    }
  }

  @media (max-height: 1050px) {
    zoom: 0.95;
  }
  @include xxxl {
    zoom: 0.8;
  }
  @include xxl {
    zoom: 0.7;
  }
  @include xl {
    zoom: 0.6;
  }
  @include lg {
    zoom: 0.5;
  }
  @include mdlscape {
    zoom: 0.35;
  }
  @include smlscape {
    zoom: 0.25;
  }
  &.true {
    @media (max-height: 1050px) {
      zoom: 0.85;
    }
    @include xxxl {
      zoom: 0.7;
    }
    @include xxl {
      zoom: 0.6;
    }
    @include xl {
      zoom: 0.5;
    }
    @include lg {
      zoom: 0.4;
    }
    @include mdlscape {
      zoom: 0.28;
    }
    @include smlscape {
      zoom: 0.2;
    }
  }
}
