@import 'leaderboard-drawer-create-profile';
@import 'leaderboard-drawer-fund-profiles';
$bg-color: #0f0f14;
$trans-time: 300ms;
$width: 100%;
.leaderboard-drawer {
  width: 450px;
  animation: icceSideAnimate 800ms;
  background-color: $bg-color;
  padding: 0;
  display: flex;
  flex-direction: column;
  .leaderboard-view {
    display: flex;
    flex-direction: column;
    height: 100%;
    .opt-tab {
      color: #18191d;
      text-align: center;
      border-bottom: 1px solid $border-color;
      .tab-itm {
        border-color: $border-color !important;
        position: relative;
        cursor: pointer;
        &:hover {
          font-weight: bold;
          color: #ffffff;
        }
        &.active {
          font-weight: bold;
          color: #ffffff;
          &::after {
            content: '';
            bottom: -1px;
            left: calc(50% - 8px);
            position: absolute;
            display: block;
            width: 0;
            height: 0;
            border-left: 8px solid transparent;
            border-right: 8px solid transparent;
            border-bottom: 8px solid $bg-color;
          }
          &::before {
            content: '';
            bottom: -1px;
            left: calc(50% - 10px);
            position: absolute;
            display: block;
            width: 0;
            height: 0;
            border-left: 10px solid transparent;
            border-right: 10px solid transparent;
            border-bottom: 10px solid $border-color;
          }
        }
      }
    }
    .tab-btn {
      border-bottom: 1px solid $border-color;
      padding: 8px 0;
      margin-bottom: 2px;
      .tab-btn-itm {
        min-width: 25%;
        text-align: center;
        color: #18191d;
        padding: 4px 8px 4px 8px;
        border-radius: 4px;
        cursor: pointer;
        &:hover {
          background: #47465480;
          color: white;
        }
        &.active {
          background: $border-color;
          color: white;
        }
      }
    }
    .card-price {
      padding-bottom: 0;
      border: none;
      overflow: hidden;
      background: linear-gradient(
        90deg,
        #8d8d8d 50%,
        #b8b8b8 72.4%,
        #d4d4d4 98.44%,
        #ffffff 100%
      );
      border-radius: 10px;
      .profile {
        padding: 16px;
        img {
          width: 70px;
          height: 70px;
          border-radius: 5px;
        }
        h3 {
          font-size: 1.6rem;
          margin-left: 10px;
          font-weight: bold;
        }
        h2 {
          font-size: 1.7rem;
        }
        .count {
          font-size: 1.1rem;
          position: relative;
          font-weight: bold;
          margin-left: 37px;
          &::before {
            content: '';
            // background: url('../image/users.svg') no-repeat center;
            background-size: cover;
            width: 20px;
            height: 20px;
            position: absolute;
            left: -27px;
            top: 0;
            bottom: 0;
            margin-top: auto;
            margin-bottom: auto;
          }
        }
        .pos {
          height: 32px;
          width: 32px;
          // background: url('../image/cup-gold.svg') no-repeat center;
          background-size: cover;
          border: none;
          position: absolute;
          top: -10px;
          left: -10px;
        }
      }
      .trades-profit {
        .border {
          border: 1px solid $border-color;
        }
        h6 {
          margin: 0;
          padding-top: 5px;
          padding-bottom: 5px;
          background-color: white;
          text-align: center;
          span {
            font-weight: bold;
          }
        }
      }
    }
    .profile-list {
      .card-profile {
        margin-top: 8px;
        margin-bottom: 8px;
        display: flex;
        flex-direction: row;
        background: #f9f9f9;
        border-radius: 10px;
        padding: 13px;
        img {
          border-radius: 5px;
          width: 60px;
          height: 60px;
        }
        h5 {
          margin-left: 10px;
          font-weight: bold;
        }
        .count {
          position: relative;
          font-weight: bold;
          margin-left: 40px;
          line-height: 1;
          &::before {
            content: '';
            // background: url('../image/users.svg') no-repeat center;
            background-size: cover;
            width: 18px;
            height: 18px;
            position: absolute;
            left: -25px;
            top: 0;
            bottom: 0;
            margin-top: auto;
            margin-bottom: auto;
          }
        }
        .pos {
          height: 30px;
          min-width: 30px;
          color: white;
          position: absolute;
          top: -10px;
          left: -10px;
          border-radius: 15px;
          background: #f37401;
          color: none;
          border: 4px solid whitesmoke;
          display: flex;
          span {
            margin-top: auto;
            margin-left: auto;
            margin-right: auto;
            color: white;
            font-size: 14px;
            line-height: 1;
          }
        }
      }
      .card-profile:nth-child(1) {
        background: linear-gradient(90deg, #ffd949 50%, #ffec7b 100%);
        .pos {
          height: 32px;
          width: 32px;
          // background: url('../image/cup-gold.svg') no-repeat center;
          background-size: cover;
          border: none;
          span {
            display: none;
          }
        }
      }
      .card-profile:nth-child(2) {
        background: linear-gradient(90deg, #d8d8c7 50%, #e0e0d0 100%);
        .pos {
          height: 32px;
          width: 32px;
          // background: url('../image/cup-silver.svg') no-repeat center;
          background-size: cover;
          border: none;
          span {
            display: none;
          }
        }
      }
      .card-profile:nth-child(3) {
        background: linear-gradient(90deg, #f4aa6a 50%, #f5b47c 100%);
        .pos {
          height: 32px;
          width: 32px;
          // background: url('../image/cup-bronze.svg') no-repeat center;
          background-size: cover;
          border: none;
          span {
            display: none;
          }
        }
      }
    }
    .profile-me {
      background: #8d8e9b;
      box-shadow: 0px -5px 14px rgba(144, 144, 144, 0.6);
      .card-profile {
        display: flex;
        flex-direction: row;
        background: #f9f9f9;
        border-radius: 10px;
        padding: 13px;
        img {
          border-radius: 5px;
        }
        .coin {
          min-width: 60px;
          position: relative;
          &::before {
            content: '';
            // background: url('../image/coin-crown.svg') no-repeat center;
            background-size: cover;
            width: 25px;
            height: 25px;
            position: absolute;
            left: -30px;
            top: 0;
            bottom: 0;
            margin-top: auto;
            margin-bottom: auto;
          }
        }
        .pos {
          height: 30px;
          min-width: 30px;
          color: white;
          position: absolute;
          top: -10px;
          left: -10px;
          border-radius: 15px;
          background: #f37401;
          color: none;
          border: 4px solid whitesmoke;
          display: flex;
          span {
            margin-top: auto;
            margin-left: auto;
            margin-right: auto;
            color: white;
            font-size: 14px;
            line-height: 1;
          }
        }
      }
    }
  }
  .footer-btn {
    background: #8d8e9b;
    color: white;
    font-weight: bold;
    box-shadow: 0px 5px 14px rgba(144, 144, 144, 0.6);
    border-radius: 0;
    &:disabled {
      cursor: default;
    }
  }
  .flex-scroll {
    flex-grow: 1;
    height: 0;
  }
  @include md {
    width: 90% !important;
    max-width: 500px;
    .ant-drawer-body {
      .leaderboard-view {
        .mx-5 {
          margin-left: 1rem !important;
          margin-right: 1rem !important;
        }

        .card-price {
          .profile {
            img {
              height: 60px !important;
              width: 60px !important;
            }
            h2 {
              font-size: 20px !important;
            }
            h3 {
              font-size: 19px !important;
            }
            .pos {
              height: 27px !important;
              width: 27px !important;
              top: -6px !important;
              left: -6px !important;
            }
            .count {
              font-size: 15px;
              margin-left: 30px !important;
              &::before {
                width: 14px !important;
                height: 14px !important;
                left: -16px !important;
              }
            }
          }
          .trades-profit {
            h6 {
              font-size: 14px;
            }
          }
        }

        .profile-list {
          .card-profile {
            margin-top: 4px !important;
            margin-bottom: 4px !important;
            padding: 8px !important;
            img {
              border-radius: 5px !important;
              height: 40px !important;
              width: 40px !important;
            }
            h5 {
              font-size: 16px !important;
              margin-left: 10px !important;
            }
            .count {
              font-size: 12px;
              margin-left: 28px !important;
              &::before {
                width: 12px !important;
                height: 12px !important;
                left: -14px !important;
              }
            }
            .pos {
              height: 22px !important;
              min-width: 22px !important;
              top: -8px !important;
              left: -8px !important;
              border-radius: 12px !important;
              span {
                font-size: 10px !important;
              }
            }
          }
          .card-profile:nth-child(1),
          .card-profile:nth-child(2),
          .card-profile:nth-child(3) {
            .pos {
              height: 22px !important;
              width: 22px !important;
              border: none !important;
              span {
                display: none !important;
              }
            }
          }
        }
      }
    }
  }
}
@keyframes lbSideAnimate {
  from {
    max-width: 0%;
  }
  to {
    max-width: 450px;
  }
}
