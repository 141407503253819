#hiding-div {
  display: none;
  background-color: #1c1d1f;
  width: 100%;
  padding: 1% 2%;
  color: white;
  position: sticky;
  top: -0.1%;
  z-index: 2;
  box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px;
  transition: all linear 0.3s;

  &::before {
    content: '';
    position: absolute;
    inset: 0;
    width: calc(100% + 6rem);
    left: -3rem;
    z-index: 0;
    background: inherit;
  }

  & > p {
    position: relative;
    z-index: 2;
    display: flex;
    gap: 0.5rem;
    font-weight: 550;
    margin: 0;
  }
}

#breadcrumb-text {
  color: white;
  white-space: nowrap;
  // font-weight: 700;
  // line-height: 1.2;
  // font-size: 1.2rem;
  display: flex;
}

#video-section {
  //   height: 200px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid whitesmoke;
  background-color: #e7e7e7;
}

#malls-sidebar-container {
  width: 35%;
  border: 1px solid whitesmoke;
  height: fit-content;
  animation: malls-sidebar-container--fade-in--fi1Gt 0.4s linear 0.4s forwards;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  transition: all linear 0.2s;
}

#malls-sidebar-container-left {
  transition: all linear 0.2s;
}

@keyframes malls-sidebar-container--fade-in--fi1Gt {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
