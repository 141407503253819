.settings {
  width: 100%;
  overflow-y: hidden;
  position: absolute;
  inset: 0;
  overflow-x: hidden;

  .settings-navbar {
    width: 100%;
    height: 8%;
    border-bottom: 0.5px solid #e7e7e7;
    display: flex;
    align-items: center;
    // justify-content: flex-start;
    padding: 1rem 3rem;
    position: relative;

    .settings-icon {
      max-width: 15%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 5px;
      font-size: 1.35rem;
      font-weight: 650;
      color: #18191d;
      margin-right: 1rem;
      img {
        width: 80%;
        height: 80%;
        min-width: 160px;
        min-height: 40px;
      }
    }

    .search-settings {
      width: 43%;
      height: calc(100% - 2rem);
      border: 0.5px solid #e7e7e7;
      background: white;
      border-radius: 25px;
      padding: 0.5rem 2rem;
      display: flex;
      align-items: center;
      position: absolute;
      transform: translate(-50%, -50%);
      top: 50%;
      left: 50%;

      .all {
        width: 32%;
        height: 100%;
        border-right: 3px solid #e7e7e7;
        display: flex;
        align-items: center;
        font-size: 1rem;
        color: #18191d;
        font-weight: 700;
        opacity: 0.25;
        padding-right: 20px;
        justify-content: space-between;
        p {
          margin-bottom: 0;
        }
        .arroww {
          display: flex;
          align-items: center;
          img {
            width: 15px;
            height: 15px;
            cursor: pointer;
          }
        }
      }
      .im {
        width: 100%;
        height: 100%;
        // padding-left: 1rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 1rem;
        color: #18191d;
        font-weight: 700;
        // opacity: 0.25;
        gap: 1rem;
        input {
          border: none;
          font-size: 1rem;
          color: #18191d;
          font-weight: 700;
          background: transparent;
        }
      }
    }

    .settings-listBtn {
      padding: 0.5rem 1.5rem;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #9fd6df;
      border: 0.5px solid #9fd6df;
      border-radius: 25px;
      cursor: pointer;
      font-weight: 700;
      color: white;
      font-size: 1rem;
      margin-left: 20vw;
      white-space: nowrap;
    }
  }

  .w-space {
    width: 100%;
    height: 5.5%;
    padding: 0 3rem;
    border-bottom: 0.5px solid #e7e7e7;
    .optionss {
      width: 30%;
      height: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 5px 0;
      .each-option-selected {
        background: rgba(229, 229, 229, 0.25);
        font-weight: 600;
        padding: 5px 1.75rem;
        border-radius: 25px;
      }
      .each-option {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        // pointer-events: none;
        p {
          margin-bottom: 0;
          color: #18191d;
          font-size: 0.9rem;
        }
        div {
          display: flex;
          gap: 5px;
          transition: all ease 0.3s;
          cursor: pointer;
          &:hover {
            scale: 1.1;
          }
        }
      }
      .top-icons {
        display: flex;
        align-items: center;
        height: 100%;
        div {
          height: 100%;
          padding: 0 2rem;
          display: flex;
          border-right: 0.25px solid #e7e7e7;
          align-items: center;
          img {
            height: 50%;
          }
        }
      }
    }
  }

  .settings-content {
    width: 100%;
    height: calc(100% - 13.5%);
    display: flex;

    .left-ai-container {
      width: 70%;
      height: 100%;
      background: rgba(237, 237, 237, 0.37);
      padding: 2.5rem 2.5rem;

      .left-ai-div {
        width: 100%;
        height: 100%;
        overflow-y: scroll;
        display: flex;
        flex-direction: column;
        gap: 3rem;
        background: white;
        border: 0.5px solid #ebebeb;
        border-radius: 15px;
        padding: 2rem;

        .heading {
          font-size: 1.3rem;
          font-weight: 700;
        }

        .editIconn {
          position: absolute;
          border: 0.5px solid #e7e7e7;
          right: 12px;
          top: -5px;
          width: 25px;
          height: 25px;
          display: flex;
          align-items: center;
          justify-content: center;
          background: #ffffff;
          border-radius: 50%;
          cursor: pointer;
        }

        .split-divs {
          display: flex;
          justify-content: space-between;
          gap: 1.5rem;

          & > div {
            width: 50%;
          }
        }

        .dateDiv {
          & > * {
            font-size: 1.1rem !important;
            font-weight: 550 !important;
          }
        }
      }

      .ai-card {
        width: 100%;
        height: 19rem;
        background: #ffffff;
        border-radius: 15px;
        display: flex;
        flex-direction: column;
        padding: 1.5rem 2.25rem 2.25rem;
        color: #18191d;
        gap: 2rem;
        margin-top: 2rem;
        background: #ffffff;
        border: 0.5px solid #e7e7e7;
        border-radius: 15px;
        transition: all ease-in 0.2s;

        &:hover {
          transform: translateX(5px);
          box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
        }

        .top-data {
          width: 100%;
          height: 70%;
          min-height: 70%;
          max-height: 70%;
          display: flex;

          .imgg-div {
            width: 18%;
            height: 92%;
            background: #ffffff;
            border: 0.5px solid #e7e7e7;
            border-radius: 15px;
            display: flex;
            justify-content: center;
            align-items: center;
            // border: 1px solid;

            & > img {
              width: 50%;
              max-height: 100%;
              object-fit: contain;
            }
          }

          .top-rightt-divv {
            width: 82%;
            padding-left: 1.5rem;
            display: flex;
            flex-direction: column;
            gap: 1.5rem;
            // justify-content: space-between;

            .top-txt {
              display: flex;
              width: 100%;
              justify-content: space-between;

              .txtt1 {
                font-size: 1.3rem;
                font-weight: 600;
              }

              .top-right-txt {
                display: flex;
                width: 43%;
                justify-content: space-between;
                align-items: center;
                position: relative;
                gap: 5px;

                // & > :nth-child(1) {
                //   // padding-right: 1.3rem;
                //   // border-right: 1px solid #e7e7e7;
                // }

                & > div {
                  width: 50%;
                  white-space: nowrap;
                }

                & > .center-line {
                  width: 1px;
                  height: 100%;
                  background: #e7e7e7;
                  position: absolute;
                  top: 50%;
                  left: 41.5%;
                  transform: translate(-50%, -50%);
                }

                .gs-btn {
                  color: #ffffff;
                  background: #9fd6df;
                  padding: 0.7rem 2rem;
                  border-radius: 10px;
                  height: 2.75rem;
                  font-size: 0.8rem;
                  font-weight: 600;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  cursor: pointer;
                  transition: all ease 0.3s;

                  &:hover {
                    scale: 1.07;
                  }
                }
              }
            }

            .bottom-txt {
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 3; /* number of lines to show */
              line-clamp: 3;
              -webkit-box-orient: vertical;
            }
          }
        }

        .bottom-data {
          width: 100%;
          height: 30%;
          display: flex;
          align-items: flex-end;
          justify-content: space-between;
          font-size: 0.8rem;
          font-weight: 300;
          padding-right: 2rem;

          .txtt2 {
            color: #04af76;
            font-size: 1.25rem;
            font-weight: 650;
          }

          .txtt3 {
            font-size: 1.35rem;
            font-weight: 650;
          }
        }
      }
    }

    .right-ai-container {
      width: 30%;
      height: 100%;
      background: rgba(237, 237, 237, 0.37);
      padding: 2.5rem 2.5rem 0;
      // padding-top: 4rem;

      .change-pass {
        background: #ffffff;
        border: 0.5px solid #ebebeb;
        border-radius: 15px;
        padding: 1.5rem;
        color: #18191d;
        display: flex;
        flex-direction: column;
        gap: 1.5rem;
        margin-bottom: 2rem;

        .title {
          font-size: 1.3rem;
          font-weight: 700;
        }
        .para {
          font-size: 1.05rem;
          line-height: 167%;
        }
        .btn {
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 1rem;
          font-weight: 650;
          border: 0.5px solid #ebebeb;
          border-radius: 5px;
          padding: 1rem;
          cursor: pointer;
          transition: all ease-in 0.2s;
          color: #18191d !important;

          &:hover {
            box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
          }
        }
      }
    }

    .createBtnn {
      width: 100%;
      background: #ffffff;
      border-radius: 15px;
      height: 7rem;
      display: flex;
      align-items: center;
      font-size: 1.15rem;
      font-weight: 500;
      padding-left: 3rem;
      border: 0.5px solid #ebebeb;
      cursor: pointer;
      transition: all ease 0.3s;

      &:hover {
        box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px,
          rgba(17, 17, 26, 0.1) 0px 0px 8px;
      }
    }
  }

  .popularS {
    position: fixed;
    height: calc(100% - 13.5%);
    top: 13.5%;
    border-left: 0.5px solid #e5e5e5;
    // border-top: 0.5px solid #e5e5e5;
    width: 30%;
    max-width: 550px;
    background: white;
    z-index: 3;
    padding: 1rem 3rem 2rem;
    transition: all ease-in 0.4s;
    right: 0;

    .head-txt {
      font-size: 1.75rem;
      font-weight: 550;
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;
      height: 6rem;

      .close-div {
        cursor: pointer;
        transition: all ease 0.2s;

        &:hover {
          transform: scale(1.07);
        }
      }
    }

    .overall-div {
      width: 100%;
      height: calc(100% - 11rem);
      display: flex;
      flex-direction: column;
      overflow-y: scroll;

      .line-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 3.5rem;
        margin-bottom: 2.5rem;

        .linee {
          width: 43%;
          height: 1px;
          background: #e5e5e5;
        }

        .new-txt {
          font-weight: 600;
          font-size: 1.15rem;
        }
      }

      .upload {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        padding: 2rem 0rem;
        margin-top: 1rem;

        .imgUpload {
          border: 0.5px solid #e7e7e7;
          padding: 1rem;
          border-radius: 5px;
          cursor: pointer;
          transition: all ease 0.2s;
          &:hover {
            transform: scale(1.05);
          }
        }
      }

      .subbTxt {
        font-size: 1.2rem;
        font-weight: 500;
        margin-top: 1rem;
      }

      .each-action1 {
        display: flex;
        gap: 10px;
        align-items: center;
        border: 1px solid #e5e5e5;
        border-radius: 15px;
        cursor: pointer;
        padding: 2rem 1.5rem;
        margin-top: 1rem;
        transition: all ease 0.2s;

        &:hover {
          background: #e7e7e7;
        }

        .partition {
          width: 100%;
          height: 100%;
          display: flex;

          div {
            width: 30%;
            height: 100%;
          }

          input {
            width: 70%;
            height: 100%;
            border: none;
            background: transparent;
            text-align: end;
            &::placeholder {
              font-weight: 550;
            }
          }
        }

        div {
          font-weight: 500;
        }

        img {
          width: 25px;
          height: 25px;
        }

        input {
          width: 100%;
          height: 100%;
          border: none;
          background: transparent;
          &::placeholder {
            font-weight: 550;
          }
        }

        .bgColorDiv {
          position: absolute;
          top: 50%;
          right: 0;
          transform: translate(-50%, -50%);
          border: 0.5px solid #e5e5e5;
          width: 12%;
          height: 60%;
          border-radius: 5px;
        }
      }

      .each-action2 {
        display: flex;
        gap: 10px;
        align-items: center;
        border: 1px solid #e5e5e5;
        border-radius: 15px;
        cursor: pointer;
        margin-top: 1rem;
        transition: all ease 0.2s;

        &:hover {
          background: #e7e7e7;
        }

        input {
          width: 80%;
          height: 100%;
          border: none;
          padding: 2rem 1.5rem;
          background: transparent;
          &::placeholder {
            font-weight: 550;
          }
        }

        .currencyDiv {
          width: 20%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          border-left: 0.5px solid #e5e5e5;
          font-weight: 700;
        }
      }

      .successMsg {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 600;
        font-size: 1.5rem;
        text-align: center;
      }

      .coverPic-container {
        width: 100%;
        height: 19rem;
        position: relative;

        .coverPicDiv {
          width: 100%;
          height: 15rem;
          border-bottom: 0.5px solid #e5e5e5;
          border-top: 0.5px solid #e5e5e5;
          z-index: 1;
        }

        .logoDiv {
          width: 140px;
          height: 140px;
          position: absolute;
          left: 12.5rem;
          top: 11rem;
          z-index: 2;
          background: #ffffff;
          border-radius: 50%;
          border: 1px solid #e5e5e5;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }

      .inputs-container {
        width: 100%;
        padding: 0 3rem;
        margin-top: 3rem;
      }
    }

    .stepBtns {
      width: 100%;
      height: 5rem;
      display: flex;
      justify-content: space-between;
      padding: 0.5rem;

      div {
        background: #18191d;
        width: 100%;
        border-radius: 35px;
        color: #ffffff;
        font-weight: 700;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        transition: all ease 0.2s;
        &:hover {
          transform: scale(1.05);
        }
      }
    }

    .successMsg {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 600;
      font-size: 1.5rem;
      text-align: center;
    }
  }
}

.imageUploadDiv {
  display: flex;
  // gap: 2rem;
  align-items: center;

  .imageDiv {
    margin-right: 2rem;
    .profileImg {
      width: 120px;
      height: 120px;
      border-radius: 50%;
    }
  }

  .uploadFileDiv {
    .uploadNewPicPlanB {
      display: none;
    }

    & > div {
      border: 0.5px solid #ebebeb;
      border-radius: 5px;
      padding: 1rem;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 700;
      font-size: 1rem;
      cursor: pointer;
      transition: all ease-in 0.2s;

      &:hover {
        box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
      }
    }
  }
}

.settingsSidebar {
  position: fixed;
  inset: 0;
  z-index: 2;
  background: rgb(0, 0, 0, 0.2);

  & > :nth-child(1) {
    width: -webkit-fill-available;
    height: 100%;
    background: transparent;
  }

  & > .sidebarContainer {
    position: absolute;
    padding: 3rem 2rem;
    overflow-y: scroll;
    right: 0;
    top: 0;
    bottom: 0;
    max-width: 600px;
    width: 50%;
    background: white;
    z-index: 3;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-bottom: 2rem;

    & > .contentDiv {
      color: #18191d;
      height: -webkit-fill-available;
      // border: 1px solid red;

      & > div {
        margin-bottom: 1.5rem;
      }

      & > .title {
        font-size: 2.2rem;
        font-weight: 700;
      }

      & > .content {
        font-size: 1.1rem;
        font-weight: 400;
      }

      & > .password {
        background: #ffffff;
        border: 0.5px solid #e5e5e5;
        border-radius: 10px;
        margin-top: 2rem;
        & > input {
          padding: 1rem;
          border: none;
          outline: none;
          border-radius: 10px;
          width: 100%;
        }
      }

      & > .qRContainer {
        margin-top: 2rem;

        & > .qRsingleDiv {
          display: flex;
          justify-content: center;
          align-items: center;
        }
        & > .seperateBorder {
          width: 100%;
          font-size: 1.2rem;
          font-weight: 600;
          display: flex;
          justify-content: center;
          align-items: center;
          position: relative;
          margin: 2.5rem 0;

          &::before {
            content: '';
            position: absolute;
            height: 1px;
            background: #e5e5e5;
            top: 45%;
            left: 0;
            width: 45%;
            // transform: translate(-50%, -50%);
          }
          &::after {
            content: '';
            position: absolute;
            top: 45%;
            height: 1px;
            background: #e5e5e5;
            right: 0;
            width: 45%;
            // transform: translate(-50%, -50%);
          }
        }
        .content {
          font-size: 1.1rem;
          font-weight: 400;
        }

        .qrcodes {
          margin-top: 1.5rem;
          font-size: 1.1rem;
          font-weight: 600;
          background: #ffffff;
          border: 0.5px solid #e5e5e5;
          border-radius: 10px;
          padding: 1rem;
          overflow-x: scroll;
        }
      }
    }

    & > .messageDiv {
      font-size: 1.2rem;
      font-weight: 600;
      color: #18191d;
      padding: 1rem;
      border: 0.5px solid #e7e7e7;
      border-radius: 10px;
      margin-bottom: 1rem;
    }

    & > .btn {
      color: white;
      padding: 0;
      // border: 1px solid blue;

      & > div {
        padding: 1rem;
        border-radius: 10px;
        font-size: 1.2rem;
        font-weight: 550;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        transition: all ease-in 0.2s;

        &:hover {
          box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
        }
      }

      & > .topBtn {
        background: #9fd6df;
        display: flex;
        gap: 1.5rem;

        .loader-container {
          // width: 100%;
          // height: 100vh;
          // position: fixed;
          background: transparent
            url('https://media.giphy.com/media/8agqybiK5LW8qrG3vJ/giphy.gif')
            center no-repeat;
          z-index: 1;

          .spinner {
            width: 24px;
            height: 24px;
            border: 2px solid;
            border-color: white transparent white transparent;
            border-radius: 50%;
            animation: spin-anim 1.2s linear infinite;
          }

          @keyframes spin-anim {
            0% {
              transform: rotate(0deg);
            }
            100% {
              transform: rotate(360deg);
            }
          }
        }
      }

      & > .bottomBtn {
        margin-top: 1rem;
        background: #9a4c97;
      }
    }
  }
}

.otpInputWrapper {
  width: 80%;
  justify-content: space-between;
  > div {
    flex: 0 0 15%;
    height: 70px;
    .otpInput {
      width: 100% !important;
      height: 100%;
      border: 2px solid #e7e7e7;
      border-radius: 8px;
      font-size: 28px;
    }
  }
}
