.container2{
  background: linear-gradient(270deg, #8EA5D5 34.82%, #ECB5CC 161.19%);
  height: 87%;
  display: flex;
  // justify-content: space-between;
  // padding-left: 40px;
  // padding-right: 27px;
}
.treeleftSide{
  // background-color: red;
  width: 15%;
  background-color: #8EA5D5;
}
.treeCenter{
  // background-color: red;
  width: 70%;
  // display: flex;
  // justify-content: center;
  // align-items: center;
}
.treerightSide{
  background-color: rgba(255, 255, 255, 0.50);
  width: 15%;
}
  .innerRight{
      margin: 20px;
      height: 95%;
      background: linear-gradient(90deg, #8EA5D5 34.82%, #ECB5CC 161.19%);
      border-radius: 15px;
      padding: 20px;
  }
  .row0{
      padding-top: 20px;
  }
.row1{
  // background-color: blue;
  height: 30%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.row2{
  // background-color: pink;
  height: 30%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  width: 100%;
  align-items: center;
  justify-content: center;
  padding: 0px 5vw;
  .itemWrapper{
      display: flex;
      justify-content: center;
      align-items: center;
  }
}
.row3{
  // background-color: gray;
  height: 30%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  padding: 0px 2vw;
  width: 100%;
  align-items: center;
  justify-content: center;
  
  .itemWrapper{
      display: flex;
      justify-content: center;
      align-items: center;
  }
}

.treeUserCard{
  // background-color: rgba(255, 255, 255, 0.5);
  background-image: url("./assets/cloud.png");
  background-repeat: no-repeat;
  background-size: contain; /* Adjust as needed */
  background-position: center; /* Adjust as needed */
  width: 15.5rem;
  height: 12.5rem;
  border-radius: 15px;
  padding: 18px;
  z-index: 0;
  &:hover{
      transform: scale(1.2);
      transition: transform .2s;
  }
}

//   .my-background {
//     background-image: url("./assets/cloud.svg");
//     background-repeat: no-repeat;
//     background-size: contain; /* Adjust as needed */
//     background-position: center; /* Adjust as needed */
//     width: 100%; /* Set width as needed */
//     // height: 300px; /* Set height as needed */
//     display: flex;
//     flex-direction: column;
//     justify-content: center;
//     align-items: center;
//   }

.treeUserCard1{
  // background-color: rgba(255, 255, 255, 0.5);
  background-image: url("./assets/cloud.png");
  background-repeat: no-repeat;
  background-size: contain; /* Adjust as needed */
  background-position: center;
  width: 12rem;
  height: 11rem;
  border-radius: 15px;
  padding: 17px;
  z-index: 0;
  .profileIcon{
      width: 60px;
      height: 60px;
      cursor: pointer;
      border-radius: 50%;
      background-color: white;
      // margin-left: 10px;
      margin-top: -5px;
  }
  &:hover{
      transform: scale(1.2);
      transition: transform .2s;
  }
}
.treeUserCard2{
  // background-color: rgba(255, 255, 255, 0.5);
  background-image: url("./assets/cloud.png");
  background-repeat: no-repeat;
  background-size: contain; /* Adjust as needed */
  background-position: center;
  width: 10.25rem;
  height: 10rem;
  border-radius: 15px;
  padding: 14px;
  z-index: 0;
  .profileIcon{
      width: 45px;
      height: 45px;
      cursor: pointer;
      border-radius: 50%;
      background-color: white;
  }
  &:hover{
      transform: scale(1.2);
      transition: transform .2s;
  }
}
.rowDivider{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  padding: 0px 5vw;
  border: 0.5px solid rgba(88, 87, 87, 0.15);
  background-color: rgba(240, 240, 240, 0.25);
  div{
      display: flex;
      justify-content: center;
      align-items: center;
  }
}
.rowDivider1{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  padding: 0px 2vw;
  border: 0.5px solid rgba(88, 87, 87, 0.15);
  background-color: rgba(240, 240, 240, 0.25);
  div{
      display: flex;
      justify-content: center;
      align-items: center;
  }
}
.rowDivider1Left{
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 33%;
}
.rowDivider1Right{

}
.countText{
  font-size: 12px;
  font-weight: 700;
}
.lText1{
  font-size: 12px;
  font-weight: 700;
  // width: 100px;
  margin: 0px 24%;
  height: 1.7rem;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}
.lText2{
  font-size: 12px;
  font-weight: 700;
  // width: 100px;
  margin: 0px 10%;
  height: 1.7rem;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}
.cardTitle{
  font-size: 14px;
  font-weight: 500;
}
.cardSubTitle{
  font-size: 10px;
  font-weight: 500;
}

// on click of image
.container2Expanded{
  background-color: rgba(0, 0, 0, 0.5);
  // background: linear-gradient(270deg, #8EA5D5 34.82%, #ECB5CC 161.19%);
  // height: 87%;
  // display: flex;
  position: fixed;
  left: 0;
  right: 0;
  top:0;
  bottom: 0;
  cursor: pointer;
  z-index: 1;
}
.treeUserCardExpanded{
  background-color: white;
  z-index: 1;
  // background-color: rgba(255, 255, 255, 0.5);
  width: 15rem;
  min-height: 15rem;
  border-radius: 15px;
  padding: 18px;
  .profileIcon{
    width: 60px;
    height: 60px;
    cursor: pointer;
    border-radius: 50%;
    background-color: white;
}
}
.leftRightRow{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 25px;
  padding-bottom: 15px;
}
.lable{
  font-size: 9px;
  font-weight: 600;
}
.countTextExpanded{
  background: linear-gradient(90deg, #8EA5D5 34.82%, #ECB5CC 161.19%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 20px;
  font-weight: 600;
  padding-top: 6px;
}
.totalRow{
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  font-weight: 600;
}
.totalText{
  background: linear-gradient(90deg, #8EA5D5 34.82%, #ECB5CC 161.19%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  // font-size: 20px;
  font-weight: 600;
  padding-left: 10px;
}
.phoneBtn{
  border-radius: 25px;
  background: linear-gradient(90deg, #8EA5D5 34.82%, #ECB5CC 161.19%);
  font-size: 9px;
  font-weight: 700;
  color: white;
  margin-top: 7px;
  height: 26px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.rightcardWrapper{
  text-align: center;
  overflow-y: scroll;
  height: 83%;
  position: relative;
}
.rightCard{
  margin-bottom: 20px;
  background-color: rgba(255, 255, 255, 0.5);
  // width: 100%;
  // height: 12.5rem;
  border-radius: 15px;
  padding: 15px;
  &:hover{
      transform: scale(0.95);
      // box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
      border-width: 2px;
      border-color: "white";
      transition: transform .2s;
  }
}
.rightTitle{
  padding-top: 10px;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
}
.rightCardExpanded{
  position:fixed;
  right: 0;
  margin-right: 40px;
  // width: 300px;
  // height: 200px;
  background-color: #fff;
  // border-radius: 10px;
  // box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  // transition: all 0.3s ease-in-out;
  // cursor: pointer;
  margin-bottom: 20px;
  // background: rgba(255, 255, 255, 0.50);
  // width: 25vw;
  // height: 12.5rem;
  border-radius: 15px;
  padding: 15px;
  display: flex;
  z-index: 2;
  
}
.expandedRightWrapper{
  border-radius: 5px;
  background: linear-gradient(90deg, #8EA5D5 34.82%, #ECB5CC 161.19%);
  width: 270px;
  margin-right: 20px;
  padding: 10px;
  z-index: 9999;
}
.expandedRightPhone{
  height: 28.347px;
  border-radius: 25px;
  background: rgba(255, 255, 255, 0.27);
  margin-bottom: 10px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}
.expandedRightTitle{
  font-size: 15px;
  font-weight: 700;
  text-align: left;
  color: #fff;
  padding-bottom: 10px;
}


/* App.css */

// .App {
//     text-align: center;
//   }




.popular {
position: fixed;
height: calc(100% - 14%);
top: 14%;
border-left: 0.5px solid #e5e5e5;
// border-top: 0.5px solid #e5e5e5;
width: 30%;
max-width: 550px;
background: white;
z-index: 3;
padding: 1rem 3rem 2rem;
transition: all ease-in 0.4s;
right: 0;

.head-txt {
  font-size: 1.75rem;
  font-weight: 550;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  height: 6rem;

  .close-div {
    cursor: pointer;
    transition: all ease 0.2s;

    &:hover {
      transform: scale(1.07);
    }
  }
}

.overall-div {
  width: 100%;
  height: calc(100% - 11rem);
  display: flex;
  flex-direction: column;
  overflow-y: scroll;

  .subbTxt {
    font-size: 1.2rem;
    font-weight: 550;
  }

  .upload {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 2rem 0rem;
    margin-top: 1rem;

    .imgUpload {
      border: 0.5px solid #e7e7e7;
      padding: 1rem;
      border-radius: 5px;
      cursor: pointer;
      transition: all ease 0.2s;
      &:hover {
        transform: scale(1.05);
      }
    }
  }

  .each-action1 {
    display: flex;
    gap: 10px;
    align-items: center;
    border: 1px solid #e5e5e5;
    border-radius: 15px;
    cursor: pointer;
    padding: 2rem 1.5rem;
    margin-top: 1rem;
    transition: all ease 0.2s;

    &:hover {
      background: #e7e7e7;
    }

    div {
      font-weight: 500;
    }

    img {
      width: 25px;
      height: 25px;
    }

    input {
      width: 100%;
      height: 100%;
      border: none;
      background: transparent;
      &::placeholder {
        font-weight: 550;
      }
    }

    .separatorDiv {
      display: flex;
      justify-content: space-between;
      width: 100%;
      height: 100%;
    }

    .hiddenDIv {
      margin-top: 2rem;
      display: flex;
      flex-direction: column;
      width: 100%;
    }
  }

  .each-action2 {
    display: flex;
    gap: 10px;
    align-items: center;
    border: 1px solid #e5e5e5;
    border-radius: 15px;
    cursor: pointer;
    margin-top: 1rem;
    transition: all ease 0.2s;

    &:hover {
      background: #e7e7e7;
    }

    input {
      width: 80%;
      height: 100%;
      border: none;
      padding: 2rem 1.5rem;
      background: transparent;
      &::placeholder {
        font-weight: 550;
      }
    }

    .currencyDiv {
      width: 20%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      border-left: 0.5px solid #e5e5e5;
      font-weight: 700;
    }
  }

  .successMsg {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    font-size: 1.5rem;
    text-align: center;
  }
}

.stepBtns {
  width: 100%;
  height: 5rem;
  display: flex;
  justify-content: space-between;
  padding: 0.5rem;

  div {
    width: 49%;
    border-radius: 35px;
    color: #ffffff;
    font-weight: 700;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: all ease 0.2s;
    &:hover {
      transform: scale(1.05);
    }
  }
}
}
.branchLeft{
position: absolute;
top:0;
margin-left: 1.5vw;
margin-top: -11vh;
font-size: 25px;
font-style: normal;
font-weight: 700;
color: white;
}
.branchRight{
position: absolute;
top:0;
margin-left: 2.5vw;
margin-top: -11vh;
font-size: 25px;
font-style: normal;
font-weight: 700;
color: white;
}
.branchLeft1{
position: absolute;
// top:0;
margin-left: 1.5vw;
margin-top: -35vh;
font-size: 20px;
font-style: normal;
font-weight: 700;
color: white;
}
.branchRight1{
position: absolute;
// margin-left: 0vw;
margin-top: -35vh;
font-size: 20px;
font-style: normal;
font-weight: 700;
color: white;
}

.buttonGroup{
position: absolute;
width: 100%;
bottom: 0;
left: 0;
// margin-bottom: 30px;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
button{
  background-color: rgba(255, 255, 255, 0.5);
  height: 39px;
  width: 139px;
  color: rgb(142, 165, 213);
  border: none;
  border-radius: 5px;
  font-size: 11px;
  font-weight: 700;
  margin-bottom: 10px;
}
}