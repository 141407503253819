.retailer {
  width: 100%;
  overflow-y: hidden;
  position: absolute;
  inset: 0;
  overflow-x: hidden;

  .retailer-navbar {
    width: 100%;
    height: 8%;
    border-bottom: 0.5px solid #e7e7e7;
    display: flex;
    align-items: center;
    padding: 1rem 3rem;
    justify-content: space-between;
    position: relative;

    .retailer-hamburger {
      width: 30px;
      height: 30px;
      margin-right: 1.25rem;
      cursor: pointer;
      transition: all ease 0.2s;

      &:hover {
        transform: scale(1.1);
      }
    }

    .retailer-icon {
      width: 10%;
      height: 100%;
      display: flex;
      align-items: center;
      gap: 5px;
      font-size: 1.35rem;
      font-weight: 650;
      color: #18191d;
      img {
        width: 80%;
        height: 80%;
      }
    }

    .search-retailer {
      width: 43%;
      height: calc(100% - 2rem);
      border: 0.5px solid #e7e7e7;
      background: white;
      border-radius: 25px;
      padding: 0.5rem 2rem;
      display: flex;
      align-items: center;
      position: absolute;
      transform: translate(-50%, -50%);
      top: 50%;
      left: 50%;

      .all {
        width: 32%;
        height: 100%;
        border-right: 3px solid #e7e7e7;
        display: flex;
        align-items: center;
        font-size: 1rem;
        color: #18191d;
        font-weight: 700;
        opacity: 0.25;
        padding-right: 20px;
        justify-content: space-between;
        p {
          margin-bottom: 0;
        }
        .arroww {
          display: flex;
          align-items: center;
          img {
            width: 15px;
            height: 15px;
            cursor: pointer;
          }
        }
      }
      .im {
        width: 100%;
        height: 100%;
        // padding-left: 1rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 1rem;
        color: #18191d;
        font-weight: 700;
        // opacity: 0.25;
        gap: 1rem;
        input {
          border: none;
          font-size: 1rem;
          color: #18191d;
          font-weight: 700;
          background: transparent;
        }
      }
    }

    .w-btn {
      padding: 0.65rem 1.75rem;
      color: #ffffff;
      background: #18191d;
      font-size: 0.75rem;
      font-weight: 520;
      border-radius: 25px;
      cursor: pointer;
      transition: all ease 0.2s;
      height: 100%;
      display: flex;
      align-items: center;
      &:hover {
        transform: scale(1.07);
      }
    }
  }

  .w-space {
    width: 100%;
    height: 5.5%;
    padding: 0 3rem;
    .optionss {
      width: 30%;
      height: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 5px 0;
      .each-option-selected {
        background: rgba(229, 229, 229, 0.25);
        font-weight: 600;
        padding: 5px 1.75rem;
        border-radius: 25px;
      }
      .each-option {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        p {
          margin-bottom: 0;
          color: #18191d;
          font-size: 0.9rem;
        }
        div {
          display: flex;
          gap: 5px;
          transition: all ease 0.3s;
          cursor: pointer;
          &:hover {
            scale: 1.1;
          }
        }
      }
      .hamburger {
        color: #18191d;
        font-weight: 700;
        display: flex;
        p {
          margin-bottom: 0;
        }
        .hov-animate {
          transition: all ease 0.3s;
          &:hover {
            scale: 1.08;
          }
        }
      }
      .top-icons {
        display: flex;
        align-items: center;
        height: 100%;
        div {
          height: 100%;
          padding: 0 2rem;
          display: flex;
          border-right: 0.25px solid #e7e7e7;
          align-items: center;
          img {
            height: 50%;
          }
        }
      }
    }
  }

  .retailer-content {
    width: 100%;
    height: calc(100% - 13.5%);
    // padding: 2% 4% 0;
    background: rgba(237, 237, 237, 0.37);
    display: flex;
    flex-direction: row-reverse;

    .bs-products {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      column-gap: 2.75%;

      .each-productt {
        width: 31.5%;
        background: white;
        border-radius: 25px;
        padding: 2rem;

        cursor: pointer;
        transition: all ease 0.4s;
        margin-top: 2rem;

        .top-sec {
          width: 100%;
          height: 10rem;
          display: flex;
          align-items: center;
          justify-content: space-between;

          img {
            width: 100%;
            height: 100%;
          }

          button {
            border: none;
            cursor: pointer;
            background: transparent;
            display: flex;
            align-self: flex-start;
            transition: all ease 0.2s;
            &:hover {
              transform: scale(1.1);
            }
          }
        }

        .mid-sec {
          width: 100%;
          display: flex;
          color: #18191d;
          font-size: 1.5rem;
          font-weight: 700;
          margin-top: 2rem;
        }

        .bottom-sec {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-top: 3rem;

          .amnt {
            color: #18191d;
            font-size: 1.5rem;
            font-weight: 600;
          }

          .first {
            color: #18191d;
            opacity: 0.5;
            font-weight: 700;
          }
        }

        &:hover {
          box-shadow: 0 12px 28px 0 rgb(0 0 0 / 20%),
            0 2px 4px 0 rgb(0 0 0 / 10%), inset 0 0 0 1px hsl(0deg 0% 100% / 5%);
        }
      }
    }

    .content-area {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: space-between;
      .left-divv {
        width: 63%;
        height: 100%;
        background: #ffffff;
        .productt-det {
          width: 100%;
          display: flex;
          gap: 2rem;
          padding: 2rem 3rem;
          align-items: center;
          .pro-name {
            p {
              margin-bottom: 0;
            }
          }
        }
        .about-section {
          width: 88%;
          padding: 2rem 0 3rem;
          border-bottom: 0.5px solid #e5e5e5;
          margin: auto;
        }
        .price-section {
          width: 100%;
          height: 78%;
          overflow-y: scroll;
          padding: 2rem 0;
          .price-divv {
            width: 88%;
            margin: auto;
            padding-top: 2rem;
            padding-bottom: 2rem;
            border-bottom: 0.5px solid #e5e5e5;
            .pricing {
              display: flex;
              width: 100%;
              // border: 1px solid;
              padding: 1rem 0;
              align-items: center;
              .billing-txt {
                width: 58%;
              }
              .pricing-btns {
                width: 42%;
                display: flex;
                justify-content: space-between;
                .onetym-btn {
                  width: 48.5%;
                  border: none;
                  border-radius: 10px;
                  padding: 1rem 0;
                  color: #18191d;
                  font-weight: 700;
                }
              }
            }
          }
        }
      }
      .right-divv {
        width: 35%;
        height: 97%;
        background: #ffffff;
        position: relative;
        .amount-details {
          width: 100%;
          display: flex;
          padding: 2.5rem 2rem;
          border-bottom: 1px solid #e5e5e5;
          .left-amnt {
            width: 50%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            p {
              margin-bottom: 0;
            }
            .amnt-font {
              font-weight: 700;
              color: #18191d;
              font-size: 2rem;
            }
            .text-font {
              font-weight: 700;
              color: #18191d;
              font-size: 1rem;
              opacity: 0.5;
            }
          }
          .left-amnt1 {
            width: 50%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-end;
            p {
              margin-bottom: 0;
            }
            .amnt-font {
              font-weight: 700;
              color: #18191d;
              font-size: 2rem;
            }
            .text-font {
              font-weight: 700;
              color: #18191d;
              font-size: 1rem;
              opacity: 0.5;
            }
          }
        }
        .billing {
          width: 100%;
          padding: 2rem;
          display: flex;
          justify-content: space-between;
          border-bottom: 1px solid #e5e5e5;
          div {
            font-weight: 700;
            color: #18191d;
            font-size: 1rem;
            opacity: 0.5;
          }
        }
        .buttonss {
          width: 100%;
          display: flex;
          position: absolute;
          bottom: 5%;
          left: 0;
          justify-content: space-between;
          padding: 0 2rem;
          .buy-btn {
            color: #ffffff;
            font-weight: 700;
            font-size: 1.25rem;
            background: #78d0b1;
            border: none;
            border-radius: 10px;
            width: 45%;
            padding: 1rem 2rem;
            transition: all ease 0.3s;
            &:hover {
              scale: 1.1;
            }
          }
          .share-btn {
            color: #18191d;
            font-weight: 700;
            font-size: 1.25rem;
            background: #e5e5e5;
            border: none;
            border-radius: 10px;
            width: 45%;
            padding: 1rem 2rem;
            transition: all ease 0.3s;
            &:hover {
              scale: 1.1;
            }
          }
        }
        .buy-step1 {
          width: 100%;
          height: 100%;
          padding: 2rem;
          .searchh {
            width: 100%;
            height: 5rem;
            margin-top: 3rem;
            border: 0.5px solid #e5e5e5;
            border-radius: 15px;
            input {
              border: none;
              font-size: 1rem;
              color: #18191d;
              font-weight: 700;
              width: 100%;
              padding: 1rem;
              height: 100%;
              opacity: 0.25;
            }
          }
          .coin-options {
            width: 100%;
            height: calc(100% - 8.5rem);
            overflow-y: scroll;
            padding: 1rem 0;
            .coin-comp {
              margin-top: 1rem;
              width: 100%;
              height: 5rem;
              border: 0.5px solid #e5e5e5;
              border-radius: 15px;
              padding: 1rem;
              display: flex;
              align-items: center;
              cursor: pointer;
              .coinnamee {
                width: 50%;
                padding-left: 10px;
                font-size: 1rem;
                color: #18191d;
                font-weight: 700;
              }
              .coinvaluee {
                width: 44%;
                display: flex;
                justify-content: flex-end;
                font-size: 1rem;
                color: #18191d;
                font-weight: 700;
              }
            }
          }
          .boxx {
            width: 100%;
            height: 5rem;
            margin-top: 3rem;
            border: 0.5px solid #e5e5e5;
            border-radius: 15px;
            font-size: 0.95rem;
            font-weight: 650;
            padding-left: 1.5rem;
            color: #18191d;
            display: flex;
            align-items: center;
            transition: all ease 0.3s;
            cursor: pointer;
            &:hover {
              scale: 1.08;
            }
          }
          .loader-anim {
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            .bottom-textt {
              position: absolute;
              transform: translate(-50%, -50%);
              left: 47%;
              bottom: 0%;
              color: #18191d;
              font-size: 1.25rem;
              font-weight: 700;
            }
          }
          .success-text {
            color: #18191d;
            font-size: 1.25rem;
            font-weight: 650;
            text-align: center;
            margin-top: 2rem;
          }
        }
      }
    }

    .right-ai-container {
      width: 30%;
      // max-width: 550px;
      height: 100%;
      border-right: 0.5px solid #e7e7e7;
      background: #ffffff;

      .right-ai-items {
        height: 100%;
        padding: 1.5rem 0;
        overflow-y: scroll;
        padding-top: 0;

        .filterItemR {
          padding: 2rem 3.5rem;
          font-size: 1rem;
          font-weight: 500;
          color: #18191d;
          border-bottom: 0.5px solid #ebebeb;

          & > div {
            display: flex;
            justify-content: space-between;
            align-items: center;
            // cursor: pointer;

            .btn {
              padding: 0.6rem 0rem;
              background: rgba(237, 237, 237, 0.37);
              border: 0.5px solid rgba(237, 237, 237, 0.37);
              border-radius: 35px;
              font-weight: 500;
              cursor: pointer;
              transition: all ease-in 0.4s;
              min-width: 35%;
              width: max-content;
              max-width: 50%;

              &:hover {
                font-weight: 700;
              }
            }
          }

          .filterItemOptions {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 1.5rem;
            margin-top: 1.5rem;

            & > div {
              background: rgba(255, 255, 255, 0.37);
              border: 1px solid #ededed;
              border-radius: 5px;
              color: #18191d;
              padding: 0.5rem;
              cursor: pointer;
              transition: all ease-in 0.4s;
              overflow-x: hidden;
              text-align: center;

              &:hover {
                scale: 1.1;
              }
            }
          }
        }
      }

      .filterOption {
        padding: 2rem;
        width: 100%;
        height: 100%;

        .headerTxt {
          font-size: 1.5rem;
          font-weight: 500;
          width: 100%;
          height: 3rem;
          display: flex;
          align-items: center;
        }

        .scrollableDiv {
          padding: 2rem 0 0;
          display: flex;
          flex-direction: column;
          grid-gap: 1rem;
          gap: 1rem;
          width: 100%;
          height: calc(100% - 11.5rem);

          .eachDiv {
            width: 100%;
            border: 0.5px solid #e5e5e5;
            border-radius: 10px;
            height: 4rem;
            font-weight: 500;
            display: flex;
            align-items: center;
            padding: 0 1.5rem;
            cursor: pointer;
            transition: all ease 0.2s;

            &:hover {
              transform: scale(1.05);
            }
          }
        }

        .BtnsDiv {
          width: 100%;
          height: 8.5rem;
          display: flex;
          flex-direction: column;
          justify-content: space-between;

          div {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 25px;
            cursor: pointer;
            transition: all ease 0.2s;
            color: #ffffff;
            font-weight: 700;
            padding: 1.15rem 0;

            &:hover {
              transform: scale(1.05);
            }
          }
        }
      }
    }
  }

  .retailer-content1 {
    width: 100%;
    height: calc(100% - 13.5%);
    display: flex;
    flex-direction: column;

    .license-header {
      width: 100%;
      padding: 0.5rem 0 0.5rem 3rem;
      height: 3rem;
      background: rgba(237, 237, 237, 0.37);
      display: flex;
      justify-content: space-between;
      font-size: 0.9rem;
      font-weight: 450;
      align-items: center;
      border-bottom: 0.5px solid #eeeeee;

      div {
        width: 25%;
      }
    }

    .license-content {
      width: 100%;
      height: calc(100% - 3rem);
      overflow-y: scroll;
      .each-license {
        width: 100%;
        padding: 1.5rem 0 1.5rem 3rem;
        display: flex;
        justify-content: space-between;
        border-bottom: 0.5px solid #eeeeee;
        align-items: center;
        transition: all ease 0.2s;
        cursor: pointer;

        &:hover {
          padding-bottom: 2rem;
        }
        .user-div {
          width: 22%;
          display: flex;
          align-items: center;
        }
        .ProductName-div {
          width: 25%;
          display: flex;
          flex-direction: column;
          img {
            width: 40px;
            height: 40px;
            border-radius: 5px;
          }
        }
        .ProductName-div1 {
          width: 12.5%;
          font-size: 1.2rem;
          font-weight: 600;
          display: flex;
          align-items: center;
        }
      }
    }
  }

  .popular {
    position: fixed;
    height: calc(100% - 13.5%);
    top: 13.5%;
    border-left: 0.5px solid #e5e5e5;
    // border-top: 0.5px solid #e5e5e5;
    width: 30%;
    max-width: 550px;
    background: white;
    z-index: 3;
    padding: 1rem 3rem 2rem;
    transition: all ease-in 0.4s;
    right: 0;

    .head-txt {
      font-size: 1.75rem;
      font-weight: 550;
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;
      height: 6rem;

      .close-div {
        cursor: pointer;
        transition: all ease 0.2s;

        &:hover {
          transform: scale(1.07);
        }
      }
    }

    .each-action {
      display: flex;
      gap: 10px;
      align-items: center;
      border: 1px solid #e5e5e5;
      border-radius: 15px;
      cursor: pointer;
      padding: 2rem 1.5rem;
      margin-top: 1rem;
      transition: all ease 0.2s;

      &:hover {
        transform: scale(1.07);
      }

      div {
        font-weight: 500;
      }
    }

    .overall-div {
      width: 100%;
      height: calc(100% - 11rem);
      display: flex;
      flex-direction: column;
      overflow-y: scroll;

      .upload {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        padding: 2rem 0rem;
        margin-top: 1rem;

        .imgUpload {
          border: 0.5px solid #e7e7e7;
          padding: 1rem;
          border-radius: 5px;
          cursor: pointer;
          transition: all ease 0.2s;
          &:hover {
            transform: scale(1.05);
          }
        }
      }

      .subbTxt {
        font-size: 1.2rem;
        font-weight: 500;
        margin-top: 1rem;
      }

      .each-action1 {
        display: flex;
        gap: 10px;
        align-items: center;
        border: 1px solid #e5e5e5;
        border-radius: 15px;
        cursor: pointer;
        padding: 2rem 1.5rem;
        margin-top: 1rem;
        transition: all ease 0.2s;

        &:hover {
          background: #e7e7e7;
        }

        .partition {
          width: 100%;
          height: 100%;
          display: flex;

          div {
            width: 30%;
            height: 100%;
          }

          input {
            width: 70%;
            height: 100%;
            border: none;
            background: transparent;
            text-align: end;
            &::placeholder {
              font-weight: 550;
            }
          }
        }

        div {
          font-weight: 500;
        }

        img {
          width: 25px;
          height: 25px;
        }

        input {
          width: 100%;
          height: 100%;
          border: none;
          background: transparent;
          &::placeholder {
            font-weight: 550;
          }
        }
      }

      .each-action2 {
        display: flex;
        gap: 10px;
        align-items: center;
        border: 1px solid #e5e5e5;
        border-radius: 15px;
        cursor: pointer;
        margin-top: 1rem;
        transition: all ease 0.2s;

        &:hover {
          background: #e7e7e7;
        }

        input {
          width: 80%;
          height: 100%;
          border: none;
          padding: 2rem 1.5rem;
          background: transparent;
          &::placeholder {
            font-weight: 550;
          }
        }

        .currencyDiv {
          width: 20%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          border-left: 0.5px solid #e5e5e5;
          font-weight: 700;
        }
      }

      .successMsg {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 600;
        font-size: 1.5rem;
        text-align: center;
      }
    }

    .stepBtns {
      width: 100%;
      height: 5rem;
      display: flex;
      justify-content: space-between;
      padding: 0.5rem;

      div {
        width: 49%;
        border-radius: 35px;
        color: #ffffff;
        font-weight: 700;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        transition: all ease 0.2s;
        &:hover {
          transform: scale(1.05);
        }
      }
    }
  }
}
