.portfolioAssets {
  flex-grow: 1;
  display: flex;
  .assetsText,
  .assetsContent {
    flex: 0 0 50%;
  }
  .assetsText {
    padding: 0 120px;
    color: $primary-color;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .title {
      font-size: 42px;
      font-weight: bold;
    }
    .detail {
      font-size: 16px;
      font-weight: normal;
      padding-top: 22px;
      line-height: 2.2;
    }
    .bondTypes {
      display: flex;
      align-items: center;
      padding-top: 25px;
      .bondTypesText {
        font-size: 20px;
        font-weight: 600;
        margin-right: 22px;
      }
      .drop-select {
        cursor: pointer;
        position: relative;
        padding: 0 20px;
        display: flex;
        flex-direction: column;
        border: 1px solid $vault-border-color;
        color: $primary-color;
        border-radius: 6px;
        margin: 0;
        .content {
          height: 38px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 200px;
          &.bond {
            min-width: 260px;
          }
        }
        .menu {
          z-index: 5;
          position: absolute;
          top: calc(100% - 2px);
          margin-left: -2px;
          margin-right: -2px;
          left: 0;
          right: 0;
          border-radius: 0 0 6px 6px;
          border: 1px solid $vault-border-color;
          border-top: none;
          background-color: white;
          display: flex;
          flex-direction: column;
          .menuItem {
            cursor: pointer;
            height: 39px;
            display: flex;
            align-items: center;
            padding: 0 20px;
            &:hover {
              font-weight: 600;
            }
          }
        }
        input {
          width: 50px;
          border: none;
          background: none;
          outline: none;
          text-align: center;
          margin: auto 0;
        }
      }
    }
  }
  .assetsContent {
    display: flex;
    .bondsListScroll {
      .bondsList {
        display: flex;
        flex-direction: column;
        border-left: 1px solid $vault-border-color;
        .bondsItem {
          flex: 0 0 40%;
          cursor: pointer;
          border-bottom: 1px solid $vault-border-color;
          padding: 4% 6%;
          display: flex;
          flex-direction: column;
          .coinPrice,
          .labels,
          .rates {
            display: flex;
            justify-content: space-between;
            color: $primary-color;
          }
          .coinPrice {
            .img {
              height: 44px;
              display: flex;
              align-items: center;
              font-size: 30px;
              font-weight: bold;
              img {
                height: 80%;
                margin-right: 10px;
              }
            }
            .title {
              font-size: 30px;
              font-weight: bold;
            }
          }
          .labels {
            font-size: 14px;
          }
          .rates {
            margin-top: auto;
            .ratesItem {
              .value {
                font-size: 22px;
                font-weight: bold;
              }
              .label {
                font-size: 14px;
              }
            }
          }
          &:hover {
            background: #00000006;
          }
        }
      }
    }
  }
  &.time {
    position: relative;
    background-image: url('../images/graphTriangle.svg');
    background-repeat: no-repeat;
    background-position-y: bottom;
    background-position-x: 600px;
    background-size: cover;
    max-width: 1580px;
    margin-left: auto;
    .range {
      z-index: 2;
      position: absolute;
      left: 600px;
      bottom: -72px;
      width: calc((100% - 800px));
      transform: skewY(-21deg);
      transform-origin: 0;
      -webkit-appearance: none;
      height: 140px;
      border-radius: 5px;
      background: transparent;
      outline: none;
      -webkit-transition: 0.2s;
      transition: opacity 0.2s;
      &::-webkit-slider-thumb {
        -webkit-appearance: none;
        appearance: none;
        width: 25px;
        height: 25px;
        border-radius: 50%;
        background: #fff;
        transform: skewY(21deg);
        cursor: pointer;
        border: 1px solid $primary-color;
      }
      &::-moz-range-thumb {
        width: 25px;
        height: 25px;
        border-radius: 50%;
        background: #fff;
        border: 1px solid $primary-color;
        transform: skewY(21deg);
        cursor: pointer;
      }
    }
    .timeDetail {
      background: white;
      border-radius: 8px;
      overflow: hidden;
      position: absolute;
      display: flex;
      flex-direction: column;
      bottom: 20px;
      left: 612px;
      height: 220px;
      width: 350px;
      transform: translateX(-50%);
      border-radius: 8px;
      background-color: $vault-border-color;
      border: 1px solid $vault-border-color;
      .head {
        background: $primary-color;
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        font-weight: 600;
        font-size: 20px;
        .days {
          flex-grow: 1;
          text-align: center;
        }
        .confirm {
          cursor: pointer;
          padding: 4px 16px;
          align-self: flex-end;
          background: white;
          color: $primary-color;
          height: 100%;
          text-align: center;
          border-bottom: 1px solid $vault-border-color;
          animation: blinker 2s linear infinite;
        }
      }
      .content {
        flex-grow: 1;
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr 1fr;
        gap: 0 1px;
        .contentIn {
          background-color: white;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          .value {
            font-size: 30px;
          }
          .label {
            font-size: 13px;
          }
        }
      }
    }
    .daysSwitcher {
      position: absolute;
      bottom: 20px;
      right: 10px;
      display: flex;
      .option {
        cursor: pointer;
        margin: 0 10px;
        font-size: 18px;
        color: $primary-color;
        &.true {
          font-weight: bold;
        }
      }
    }
    .today,
    .nextDay {
      position: absolute;
      height: 64px;
      bottom: -64px;
      color: white;
      display: flex;
      align-items: center;
      font-size: 19px;
      font-weight: 600;
      left: 612px;
      transform: translateX(-50%);
    }
    .sliderMobile {
      display: none;
    }
  }
  @include mdportrait {
    flex-direction: column;
    .assetsText,
    .assetsContent {
      flex: unset;
    }
    .assetsText {
      padding: 0 20px;
      .title {
        padding-top: 20px;
        font-size: 35px;
      }
      .detail {
        font-size: 14px;
        font-weight: normal;
        padding-top: 22px;
        line-height: 2.2;
      }
      .bondTypes {
        display: flex;
        align-items: center;
        padding: 15px 0;
        .bondTypesText {
          font-size: 15px;
          margin-right: 8px;
        }
        .drop-select {
          padding: 0 10px;
          .content.bond {
            min-width: unset;
            font-size: 11px;
          }
          .menu {
            margin: 0 -1px;
            .menuItem {
              font-size: 11px;
            }
          }
        }
      }
    }
    .assetsContent {
      .bondsListScroll {
        .bondsList {
          position: relative !important;
          .bondsItem {
            flex: unset;
            .coinPrice {
              .img {
                height: unset;
                font-size: 20px;
                img {
                  height: 20px;
                  margin-right: 6px;
                }
              }
              .title {
                display: flex;
                font-size: 20px;
                align-items: center;
              }
            }
            .labels {
              font-size: 11px;
            }
            .rates {
              margin-top: 10px;
              .ratesItem {
                .value {
                  font-size: 18px;
                }
                .label {
                  font-size: 11px;
                }
              }
            }
            &:hover {
              background: #00000006;
            }
          }
        }
      }
    }
    .range,
    .daysSwitcher,
    .today,
    .nextDay {
      display: none !important;
    }

    &.time {
      .sliderMobile {
        display: flex;
        position: relative;
        margin-top: 20px;
        svg {
          width: 100%;
          height: auto;
        }
        .range-mobile {
          width: 100%;
          z-index: 2;
          position: absolute;
          left: 0;
          bottom: -70px;
          transform: skewY(-21deg);
          transform-origin: 0;
          -webkit-appearance: none;
          height: 140px;
          border-radius: 5px;
          background: transparent;
          outline: none;
          -webkit-transition: 0.2s;
          transition: opacity 0.2s;
          &::-webkit-slider-thumb {
            -webkit-appearance: none;
            appearance: none;
            width: 25px;
            height: 25px;
            border-radius: 50%;
            background: #fff;
            transform: skewY(21deg);
            cursor: pointer;
            border: 1px solid $primary-color;
          }
          &::-moz-range-thumb {
            width: 25px;
            height: 25px;
            border-radius: 50%;
            background: #fff;
            border: 1px solid $primary-color;
            transform: skewY(21deg);
            cursor: pointer;
          }
        }
        .daysSwitcher {
          bottom: 10px;
          right: 10px;
          display: flex !important;
          .option {
            z-index: 6;
            margin: 0 5px;
            font-size: 11px;
            color: $primary-color;
            &.true {
              font-weight: bold;
            }
          }
        }
      }
      .timeDetail {
        position: relative !important;
        top: 0 !important;
        left: 0 !important;
        bottom: 0 !important;
        right: 0 !important;
        transform: unset;
        width: 100%;
        border-radius: 0;
      }
    }
  }
}

@keyframes blinker {
  50% {
    color: white;
  }
}
