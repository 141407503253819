.learnComponent {
  height: 88%;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  padding: 0rem 3rem;
  background: rgba(245, 245, 245, 0.5);

  & > .allCurriculum {
    margin-top: 3rem;

    & > .heading {
      font-size: 1.6rem;
      font-weight: 500;
      padding-bottom: 2rem;
    }

    & > .curriculum {
      display: flex;
      gap: 1.5rem;

      & > div {
        width: 25%;
        border-radius: 20px;
        cursor: pointer;
        transition: all linear 0.3s;

        &:hover {
          box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
        }

        & > .topDiv {
          padding: 2rem 1.5rem;
          display: flex;
          gap: 1.2rem;
          align-items: center;

          & > .imageDiv {
            background: white;
            border-radius: 10px;
            aspect-ratio: 1/1;
            padding: 10px;

            & > img {
              aspect-ratio: 1/1;
            }
          }

          & > .contentDiv {
            & > .count {
              font-size: 1.3rem;
              font-weight: 550;
            }

            & > .title {
              font-size: 1rem;
              padding: 5px 0;
            }
          }
        }

        & > .bottomDiv {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 1rem 1.5rem;
          font-size: 0.9rem;
          font-weight: 550;
          cursor: pointer;

          & > svg {
          }
        }
      }
    }
  }

  & > .allCourses {
    width: 100%;
    height: max-content;
    padding: 2rem;
    background: white;
    border-radius: 35px;
    margin-top: 3rem;
    padding-bottom: 3rem;

    & > .heading {
      font-size: 1.3rem;
      font-weight: 550;
      padding-bottom: 2rem;
    }

    & > .courses {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 4rem;
      overflow-y: scroll;
    }
  }

  & > .allStreams {
    width: 100%;
    height: max-content;
    padding: 2rem;
    background: white;
    border-radius: 35px;
    margin: 3rem 0;
    padding-bottom: 3rem;

    & > .heading {
      font-size: 1.3rem;
      font-weight: 550;
      padding-bottom: 2rem;
    }

    & > .streams {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 2rem;
      overflow-y: scroll;
    }
  }
}

.learnCard {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 1rem;
  gap: 1.5rem;
  // height: max-content;
  min-height: 300px;
  // background: rgba(229, 229, 229, 0.3);
  border-radius: 25px;
  border: 1px solid rgba(237, 237, 237, 1);
  cursor: pointer;
  transition: all linear 0.3s;

  &:hover {
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
      rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
  }

  & > .imageContainer {
    width: 100%;
    // height: 100% !important;
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    position: relative;
    border-radius: 25px;

    & > :nth-child(1) {
      width: 20%;
      // height: 100%;
    }

    & > :nth-child(2) {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      height: 135%;
      width: 80%;
      border-radius: 25px;

      //   &::after {
      //     position: absolute;
      //     content: '';
      //     background-image: url('../../static/images/academy/playlogo.svg');
      //     inset: 0;
      //     background: rgba(0, 0, 0, 0.8);
      //   }
    }

    & > .learnImage {
      display: block;
      border-radius: 25px;
    }

    & > .playImage {
      display: none;
      left: 42%;
    }

    // &:hover .learnImage {
    //   display: none;
    // }

    // &:hover .playImage {
    //   display: block;
    // }
  }
  & > .contentContainer {
    width: 100%;
    // height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 1.5rem;
    gap: 1rem;
    padding-left: 0;
    padding-top: 0.5rem;

    & > .title {
      font-size: 1.5rem;
      font-weight: 550;
    }
    & > .description {
      font-size: 1.1rem;
      font-weight: 400;
      line-height: 1.65;
      height: max-content;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    & > .amount {
      display: flex;
      justify-content: space-between;
      padding-right: 6rem;

      & > div {
        white-space: nowrap;
      }

      & > :nth-child(1) {
        font-size: 1.1rem;
        font-weight: 550;
      }

      & > :nth-child(2) {
        color: #7899CF;
        font-size: 1.35rem;
        font-weight: 600;
      }
    }

    & > .btns {
      display: flex;
      justify-content: flex-end;
      gap: 1.5rem;
      margin-top: 2rem;

      & > div {
        display: flex;
        justify-content: center;
        align-items: center;
        // width: max-content;
        padding: 0.6rem 2rem;
        cursor: pointer;
        border-radius: 35px;
        font-weight: 500;
        font-size: 1.1rem;
        width: 100%;
      }

      & > :nth-child(1) {
        color: white;
        background: #7899CF;
        transition: all ease 0.2s;

        &:hover {
          background: white;
          color: var(--font-color);
          border: 0.5px solid #e7e7e7;
        }
      }
      & > :nth-child(2) {
        color: var(--font-color);
        transition: all ease 0.2s;

        &:hover {
          background: white;
          color: var(--font-color);
          border: 0.5px solid #e7e7e7;
        }
      }
    }
  }
}

.streamCard {
  display: flex;
  // flex-direction: column;
  // align-items: center;
  width: 100%;
  padding: 1rem;
  gap: 1rem;
  // height: 300px;
  // background: rgba(229, 229, 229, 0.3);
  border-radius: 25px;
  border: 1px solid rgba(237, 237, 237, 1);
  transition: all linear 0.3s;

  &:hover {
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
      rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
  }

  & > .imageContainer {
    // width: ;
    height: 100% !important;
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    position: relative;
    border-radius: 25px;
    cursor: pointer;

    & > .learnImage {
      display: block;
      border-radius: 25px;
      height: 100%;
      max-height: 150px;
      aspect-ratio: 1/1;
    }
  }
  & > .contentContainer {
    width: 100%;
    // height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    // padding: 1.5rem;
    gap: 1rem;
    overflow: scroll;
    // padding-left: 0;
    // padding-top: 0.5rem;

    & > .author {
      font-size: 0.9rem;
      display: flex;
      gap: 10px;

      & > :first-child {
        color: #7899CF;
        font-weight: 550;
        cursor: pointer;
        transition: all linear 0.3s;

        &:hover {
          scale: 1.05;
        }
      }
    }

    & > .title {
      font-size: 1.2rem;
      font-weight: 550;
    }
    & > .date {
      font-size: 0.85rem;
      font-weight: 400;
      line-height: 1.65;
      height: max-content;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 1rem;

      & > span {
        white-space: nowrap;
        & > img {
          height: 20px;
          width: 20px;
          padding-right: 5px;
        }
      }
    }

    & > .amount {
      display: flex;
      justify-content: space-between;
      padding-right: 6rem;

      & > div {
        white-space: nowrap;
      }

      & > :nth-child(1) {
        font-size: 1.1rem;
        font-weight: 550;
      }

      & > :nth-child(2) {
        color: #7899CF;
        font-size: 1.35rem;
        font-weight: 600;
      }
    }
  }

  & > .btns {
    display: flex;
    justify-content: flex-end;
    gap: 1.5rem;
    // margin-top: 2rem;
    width: max-content;
    height: max-content;

    & > div {
      display: flex;
      justify-content: center;
      align-items: center;
      width: max-content;
      padding: 0.6rem 2rem;
      cursor: pointer;
      border-radius: 35px;
      font-weight: 500;
      font-size: 0.9rem;
      // width: 100%;
    }

    & > :nth-child(1) {
      color: white;
      background: #7899CF;
      transition: all ease 0.2s;

      // &:hover {
      //   background: white;
      //   color: var(--font-color);
      //   border: 0.5px solid #e7e7e7;
      // }
    }
    & > :nth-child(2) {
      color: var(--font-color);
      transition: all ease 0.2s;

      // &:hover {
      //   background: white;
      //   color: var(--font-color);
      //   border: 0.5px solid #e7e7e7;
      // }
    }
  }
}

.detailedCourse {
  width: 100%;
  height: 88%;
  padding: 0rem 3rem;
  background: rgba(245, 245, 245, 0.5);
  overflow-y: scroll;
  transition: all linear 0.3s;

  & > .breadCrumb {
    font-size: 0.9rem;
    font-weight: 500;
    display: flex;
    gap: 0.5rem;
    padding: 1.5rem 0;
    background: red;
    position: relative;
    color: white !important;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      bottom: -12rem;
      width: calc(100% + 6rem);
      left: -3rem;
      z-index: 0;
      background: inherit;
    }

    & > span {
      cursor: pointer;
      position: relative;
      z-index: 1;
      transition: all linear 0.3s;

      &:hover {
        text-decoration: underline;
      }
    }

    & > :last-child {
      font-weight: 600;
    }
  }

  & > .contentContainer {
    border-radius: 15px;
    display: flex;
    gap: 1.5rem;
    // height: calc(100% - 4.8rem);
    // overflow-y: scroll;

    & > .leftContainer {
      width: 70%;
      padding: 2rem;
      padding-bottom: 1rem;
      background: white;
      overflow-y: scroll;
      border-radius: 25px 25px 0 0;
      position: relative;
      z-index: 1;

      & > .fullVideo {
        width: 100%;
        position: relative;
        margin-bottom: 2rem;

        & > .thumbnail {
          width: 100%;
          border-radius: 25px;
          object-fit: contain;
        }

        & > .playBtn {
          width: 60px;
          height: 60px;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          cursor: pointer;
        }
      }

      & > .title {
        font-size: 1.8rem;
        font-weight: 600;
      }

      & > .author {
        font-size: 1.1rem;
        padding: 0.8rem 0;
        padding-bottom: 2rem;

        & > span {
          font-weight: 550;
          text-decoration: underline;
          cursor: pointer;
          transition: all linear 0.3s;

          &:hover {
            font-size: 1.2rem;
          }
        }
      }

      & > .courseDesc {
        font-size: 1.1rem;
        padding: 0.8rem 0;
        padding-bottom: 2rem;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      & > .courseRatingDate {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-top: 2rem;

        & > .rating,
        .studentCount {
          display: flex;
          gap: 10px;
          align-items: center;
          color: rgba(var(--font-color, 0.5));

          & > .points {
            display: flex;
            align-items: center;
            font-size: 1.1rem;
            font-weight: 550;
            color: rgba(255, 66, 29, 1);

            & > span {
              display: flex;
              gap: 5px;
              padding-left: 5px;

              & > img {
                width: 20px;
                height: 20px;
              }
            }
          }
        }

        & > .datetime {
          font-weight: 500;
        }
      }

      & > .courseRatingDateOnly {
        padding-bottom: 2rem;
        border-bottom: 1px solid #e5e5e5;
      }

      & > .instructor {
        margin-top: 1rem;
        padding-bottom: 2rem;
        border-bottom: 1px solid #e5e5e5;

        & > .heading {
          font-size: 1.2rem;
          font-weight: 550;
          padding: 1rem 0;
        }

        & > .content {
          display: flex;
          align-items: center;
          gap: 15px;
          margin-top: 0.5rem;

          & > img {
            height: 100%;
            object-fit: contain;
            border-radius: 15px;
          }

          & > .authorDetails {
            width: -webkit-fill-available;
            display: flex;
            flex-direction: column;
            gap: 10px;

            & > .name {
              font-size: 1.3rem;
              font-weight: 550;
            }

            & > .desc {
              display: -webkit-box;
              -webkit-line-clamp: 3;
              -webkit-box-orient: vertical;
              overflow: hidden;
              text-overflow: ellipsis;
              font-size: 1.1rem;
              padding: 0.8rem 0;

              & > .readMoreBtn {
                color: #7899CF;
                font-weight: 550;
                cursor: pointer;
                transition: all linear 0.3s;

                &:hover {
                  text-decoration: underline;
                }
              }
            }
          }
        }
      }

      & > .context {
        margin-top: 1rem;
        padding-bottom: 2rem;
        border-bottom: 1px solid #e5e5e5;

        & > .heading {
          font-size: 1.2rem;
          font-weight: 550;
          padding: 1rem 0;
        }

        & > .allContext {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          gap: 2rem;
          margin-top: 2rem;

          & > .eachContext {
            display: flex;
            gap: 15px;
            align-items: center;

            & > svg {
              width: 25px;
              height: 25px;
              fill: #7899CF;
            }

            & > span {
              font-size: 1rem;
            }
          }

          & > .eachContextIncludes {
            font-weight: 550;
          }
        }
      }

      & > .buttonDiv {
        display: flex;
        gap: 1rem;
        padding: 1rem 0;

        & > div {
          padding: 0.5rem 3rem;
          font-size: 1rem;
          font-weight: 500;
          cursor: pointer;
          color: white;
          border-radius: 35px;
          border: 1px solid #7899CF;
          transition: all linear 0.3s;

          &:hover {
            background: white;
            color: #7899CF;
          }
        }

        & > :nth-child(1) {
          background: #7899CF;
        }

        // & > :nth-child(2) {
        //   background: rgba(33, 37, 41, 1);
        // }
      }

      & > .aboutCourse {
        margin-top: 0.8rem;
        padding-bottom: 2rem;
        border-bottom: 1px solid #e5e5e5;

        & > .heading {
          font-size: 1.2rem;
          font-weight: 550;
          padding: 1rem 0;
        }

        & > .para {
          font-size: 1.1rem;
          line-height: 1.7;
          margin-top: 1rem;
          padding-left: 0;
          margin-bottom: 0;
        }

        & > .dotList {
          margin-top: 1rem;
          font-size: 1.1rem;
          line-height: 1.9;
          margin-bottom: 0;
        }

        & > .orderList {
          margin-top: 1rem;
          font-size: 1.1rem;
          line-height: 1.9;
          margin-bottom: 0;
        }

        & > .courses {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          gap: 4rem;
          overflow-y: scroll;

          .title {
            font-size: 1.2rem;
          }

          .description {
            font-size: 1rem;
          }

          .btns {
            & > div {
              font-size: 1rem;
            }
          }
        }

        & > .allReviews {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          gap: 4rem;
          overflow-y: scroll;

          & > .eachReview {
            display: flex;
            flex-direction: column;
            width: 100%;
            padding: 1.2rem;
            gap: 1.5rem;
            height: max-content;
            min-height: 300px;
            // background: rgba(229, 229, 229, 0.3);
            border-radius: 25px;
            border: 1px solid rgba(237, 237, 237, 1);
            transition: all linear 0.3s;

            & > .profile {
              display: flex;
              align-items: center;
              gap: 15px;

              & > img {
                height: 60px;
                width: 60px;
                border-radius: 50%;
              }

              & > .details {
                & > .name {
                  font-size: 1.2rem;
                  font-weight: 550;
                }

                & > .rating {
                  padding-top: 2px;
                  font-size: 1.1rem;
                  font-weight: 550;
                  color: rgba(255, 66, 29, 1);
                  display: flex;
                  align-items: center;

                  & > span {
                    display: flex;
                    gap: 5px;
                    padding-left: 5px;

                    & > img {
                      width: 17px;
                      height: 17px;
                    }
                  }
                }
              }
            }

            & > .para {
              display: -webkit-box;
              -webkit-line-clamp: 6;
              -webkit-box-orient: vertical;
              overflow: hidden;
              text-overflow: ellipsis;
              font-size: 1rem;
              padding: 1.2rem 0;

              & > .btn {
                color: #7899CF;
                font-weight: 550;
                cursor: pointer;
                transition: all linear 0.3s;

                &:hover {
                  text-decoration: underline;
                }
              }
            }

            & > .date {
              width: 100%;
              display: flex;
              justify-content: flex-end;
              font-size: 1rem;
              font-weight: 550;
            }
          }
        }
      }

      & > .allSections {
        margin-top: 1rem;
        padding-bottom: 2rem;
        border-bottom: 1px solid #e5e5e5;
        & > .heading {
          font-size: 1.2rem;
          padding: 1rem 0;
          font-weight: 550;
        }

        & > .sectionContainer {
          margin: 1rem 0;
          margin-bottom: 0;

          & > .eachSection {
            border: 1px solid #e5e5e5;
            padding: 1.25rem 2rem;
            border-radius: 35px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-weight: 550;
            margin-bottom: 1.25rem;
            font-size: 1.1rem;
            cursor: pointer;
            transition: all linear 0.3s;

            &:hover {
              background: rgba(245, 245, 245, 0.5);
            }

            & > .title {
              display: flex;
              gap: 10px;
              align-items: center;

              & > img {
                width: 25px;
                height: 25px;
              }
            }

            & > .videoCount {
            }
          }
        }
      }
    }

    & > .rightContainer {
      padding: 2rem 1.5rem;
      background: white;
      // overflow-y: scroll;
      border-radius: 25px;
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      width: 30%;
      height: max-content;
      position: relative;
      z-index: 1;

      & > .eachSection {
        border: 1px solid #e5e5e5;
        padding: 1.25rem 2rem;
        border-radius: 35px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-weight: 550;
        margin-bottom: 1.25rem;
        font-size: 1.1rem;
        cursor: pointer;
        transition: all linear 0.3s;

        &:hover {
          background: rgba(245, 245, 245, 0.5);
        }

        & > .title {
          display: flex;
          gap: 15px;
          align-items: center;
          width: 70%;
          transition: all linear 0.3s;

          &:hover {
            background: rgba(245, 245, 245, 0.5);
          }

          & > svg {
            width: 30px !important;
            height: 30px !important;
            fill: #7899CF;
          }

          & > span {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            width: -webkit-fill-available;
          }
        }

        & > .videoCount {
        }
      }

      & > img {
        width: 100%;
        object-fit: contain;
        border-radius: 25px;
      }

      & > .toggle {
        width: 100%;
        padding: 0.5rem;
        border-radius: 35px;
        border: 1px solid #e5e5e5;
        display: flex;
        margin: 1.5rem 0;

        & > div {
          font-size: 1.1rem;
          width: 50%;
          padding: 1rem;
          text-align: center;
          border-radius: 35px;
          font-weight: 400;
          cursor: pointer;
          transition: all linear 0.2s;
        }

        & > .selectedToggle {
          font-weight: 550;
          background: rgba(241, 244, 246, 1);
        }
      }

      & > .priceDiv {
        display: flex;
        align-items: center;
        gap: 1rem;
        margin: 1.5rem 0;
        & > :nth-child(1) {
          font-size: 1.3rem;
          font-weight: 600;
        }

        & > :nth-child(2) {
          font-size: 1.1rem;
          font-weight: 500;
          text-decoration: line-through;
          opacity: 0.4;
        }
        & > :nth-child(2) {
          font-size: 0.9rem;
          font-weight: 500;
        }
      }

      & > .buyBtn {
        width: 100%;
        text-align: center;
        padding: 1rem;
        font-size: 1.2rem;
        font-weight: 600;
        color: white;
        background: #7899CF;
        border-radius: 35px;
        cursor: pointer;
        transition: all linear 0.3s;

        &:hover {
          scale: 1.05;
        }
      }

      & > .otherDetails {
        display: flex;
        flex-direction: column;
        gap: 1.6rem;
        font-size: 1.1rem;
        font-weight: 500;
        margin: 1.5rem 0;

        & > div {
          display: flex;
          align-items: center;
          justify-content: space-between;
        }

        & > .guarentee {
          justify-content: center;
        }

        & > .access {
          justify-content: center;
        }

        & > .offer {
          & > div {
            text-decoration: underline;
            cursor: pointer;
            transition: all linear 0.4s;

            &:hover {
              font-weight: 550;
            }
          }
        }
      }

      & > .seperator {
        position: relative;
        margin: 2rem 0;

        & > span {
          background: white;
          // padding: 5rem;
          z-index: 2;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          font-size: 1.1rem;
          font-weight: 500;
          padding: 10px;
        }

        &:before {
          content: '';
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          height: 1px;
          width: 100%;
          background: rgba(229, 229, 229, 1);
        }
      }

      & > .subscribe {
        & > .title {
          font-size: 1.3rem;
          font-weight: 600;
          padding: 1.2rem 0;
        }

        & > .para {
          font-size: 1.1rem;
          font-weight: 400;
        }

        & > .btn {
          width: 100%;
          text-align: center;
          padding: 1rem;
          font-size: 1.1rem;
          font-weight: 550;
          margin-top: 1.5rem;
          background: white;
          border-radius: 35px;
          cursor: pointer;
          border: 1px solid #e5e5e5;
          transition: all linear 0.3s;

          &:hover {
            scale: 1.05;
          }
        }
      }
    }
  }
}
