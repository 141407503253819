.collapsedonly {
  width: 20%;
  height: 100%;

  padding-top: 57px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-right: 0.5px solid #e7e7e7;
  z-index: 1;
  min-width: 20%;
  background: white;

  .collapsedheader {
    width: 100%;
    height: 10%;
    display: flex;
    justify-content: space-between;
    cursor: pointer;

    & > div {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    & > :first-child {
      align-items: center;
      width: 20%;
      border-radius: 50%;
      position: relative;

      & > img {
        // border-radius: 50%;
        // max-width: 100%;
        // height: 50px;
        transition: all ease 0.5s;
        width: 100%;
        position: absolute;
        border-radius: 50%;

        &:hover {
          scale: 1.05;
        }

        &::after {
          content: '';
          display: block;
          padding-top: 100%;
        }
      }
    }
    & > :nth-child(2) {
      width: 65%;

      & > :first-child {
        font-size: 1.5rem;
        font-weight: 700;
        color: #18191d;
      }

      & > :nth-child(2) {
        font-size: 1rem;
        font-weight: 400;
        color: #18191d;
      }
    }
    & > :nth-child(3) {
      width: 5%;
      cursor: pointer;
    }
  }
  .collapsedbody {
    width: 100%;
    height: 100%;

    & > div {
      width: 100%;
      height: 50%;
      border-bottom: 0.5px solid #e7e7e7;
      // padding: 5% 0%;
      font-size: 1.2rem;
      font-weight: 400;
      color: #18191d;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      // cursor: pointer;
      // padding-bottom: 3rem;
    }

    > :nth-child(2) {
      border-bottom: 0;
    }

    .collapsedbodytop {
      padding: 0% 4%;
      padding-bottom: 1.5rem;
      & > a {
        width: 100%;
        padding: 5%;
        border-radius: 15px;
        display: block;
        text-decoration: none;
        color: inherit;
        transition: all ease 0.2s;
        display: flex;
        gap: 1rem;
        color: #18191d;
        font-weight: 800;
        cursor: pointer;

        & > img {
          max-height: 30px;
          max-width: 30px;
        }

        // &:hover {
        //   background: #44c2f440;
        //   font-weight: 600;
        //   // font-size: 1.4rem;
        //   // border: 2px solid #44c2f4;
        // }
      }
    }

    & > .collapsedbodybottom {
      // border: 1px solid red;
      // padding: 2% 9%;
      // margin-top: 1rem;
      // margin-bottom: 1rem;

      // & > .roiDiv {
      //   width: 100%;
      //   height: 100%;
      //   background: linear-gradient(180deg, #44c2f4 0%, #b3e0f7 55.03%);
      //   border-radius: 20px;
      //   padding: 1.5rem 2rem;
      //   display: flex;
      //   flex-direction: column;
      //   justify-content: space-between;
      //   max-height: 420px;

      //   & > :nth-child(1) {
      //     & > .totalValue {
      //       font-size: 3.5rem;
      //       font-weight: 800;
      //       color: white;
      //     }

      //     & > :nth-child(2) {
      //       font-size: 1.5rem;
      //       font-weight: 600;
      //       color: white;
      //     }
      //   }

      //   & > :nth-child(2) {
      //     color: white;
      //     font-size: 1.7rem;
      //     font-weight: 600;
      //     display: flex;
      //     flex-direction: column;
      //     gap: 1rem;

      //     & > div {
      //       border: 1.5px solid #ffffff;
      //       border-radius: 15px;
      //       width: 100%;
      //       padding: 0.5rem;
      //       text-align: center;
      //       cursor: pointer;
      //     }
      //   }
      // }

      & > .leftBottom {
        height: 100%;
        width: 100%;
        border-radius: 20px;

        & > .pointsDiv {
          height: calc(100% - 4rem);
          width: 100%;
          display: flex;
          justify-content: center;
          // align-items: center;
          flex-direction: column;
          gap: 1.7rem;
          padding: 1% 7%;
          // padding: 1.5rem 2rem;

          & > :nth-child(1) {
            display: flex;
            gap: 0.5rem;
            font-size: 2rem;
            font-weight: 700;
            color: #44c2f4;
            line-height: 1.1;

            & > img {
              max-height: 100%;
              max-width: 30%;
              height: 100%;
            }

            & > div {
              & > .subTitle {
                font-size: 1.1rem;
                color: #18191d;
              }
            }
          }

          & > :nth-child(2) {
            font-size: 1.1rem;
            font-weight: 400;
            line-height: 1.7;
            color: #18191d;
          }

          & > :nth-child(3) {
            display: flex;
            justify-content: space-between;
            padding: 1.2rem;
            border: 0.5px solid #e5e5e5;
            border-radius: 16px;
            font-size: 1.2rem;
            font-weight: 700;
            color: #18191d;
          }
        }
        & > .btnsDiv {
          height: 4rem;
          width: 100%;
          // border: 1px solid blue;
          font-size: 1.2rem;
          font-weight: 700;
          display: flex;

          & > div {
            display: flex;
            justify-content: center;
            align-items: center;
            background: #7d7d7d;
            color: white;
            width: 50%;
            cursor: pointer;
          }

          & > :nth-child(1) {
            background: #44c2f4;
          }
        }
      }
    }
  }
}

.selectedTopSidebar {
  background: #44c2f4;
  color: white;
}

.planBContainer {
  display: flex;
  flex-direction: column;
  gap: 15px;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  padding: 2%;
  position: relative;
  & > .header {
    display: flex;
    height: 15%;
    width: 100%;
    align-items: center;
    justify-content: space-between;

    & > .profile {
      width: 32%;
      height: 100%;
      display: flex;
      gap: 4%;
      // justify-content: space-between;

      & > div {
        display: flex;
        flex-direction: column;
        justify-content: center;
      }

      & > :first-child {
        align-items: center;
        width: 20%;
        border-radius: 50%;
        position: relative;

        & > img {
          // border-radius: 50%;
          // max-width: 100%;
          // height: 50px;
          transition: all ease 0.5s;
          width: 100%;
          position: absolute;
          border-radius: 50%;
          width: 4.5vw;
          height: 4.5vw;
          min-height: 5.5rem;
          min-width: 5.5rem;

          &:hover {
            scale: 1.05;
          }

          &::after {
            content: '';
            display: block;
            padding-top: 100%;
          }
        }
      }
      & > :nth-child(2) {
        width: 62%;
        line-height: 1.6;
        position: relative;

        & > .editProfileBtn {
          transition: all ease-in 0.4s;
          &:hover {
            font-size: 1.2rem;
            font-weight: 500;
          }
        }

        & > :first-child {
          font-size: 1.6rem;
          font-weight: 700;
          color: #18191d;
        }

        & > :nth-child(2) {
          font-size: 1.1rem;
          font-weight: 400;
          color: #18191d;
          cursor: pointer;
        }

        & > :nth-child(3) {
          width: 25%;
          height: 1.4rem;
          position: absolute;
          cursor: pointer;
          // right: -3%;
          top: 50%;
          right: -25%;
          transform: translate(-50%, -50%);

          & img {
            max-height: 100%;
            height: 100%;
          }
        }
      }
      // & > :nth-child(3) {
      //   width: 5%;
      //   cursor: pointer;
      // }
    }

    & > .search {
      width: 30%;
      height: 5rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border: 0.5px solid #e5e5e5;
      border-radius: 10px;
      transition: all ease-in 0.4s;

      &:hover {
        border: 3px solid #e5e5e5;
      }

      & > :first-child {
        width: 80%;
        padding: 2% 5%;
        border: none;
        font-size: 1.1rem;
        font-weight: 600;
        transition: all ease-in 0.4s;
        // opacity: 0.25;

        &::placeholder {
          color: #d6d3d3;
        }
      }
      & > :last-child {
        width: 15%;
        height: 40%;
        cursor: pointer;
        // display: flex;
        // align-items: center;
        // justify-content: center;

        & img {
          height: 100%;
        }
      }
    }
  }
  & > .content {
    height: 80%;
    min-width: 100%;
    display: flex;
    // justify-content: space-between;
    gap: 3%;
    overflow-x: scroll;
  }

  & > .pinValidate {
    position: fixed;
    inset: 0;
    width: 100%;
    height: 100%;
    z-index: 8;

    & > .overlay {
      position: absolute;
      inset: 0;
      background: #000000;
      opacity: 0.5;
      pointer-events: none;
    }
  }

  & > .noAccount {
    // border: 1px solid red;
    height: 100%;
    width: 60%;
    display: flex;
    justify-content: center;
    font-size: 2.2rem;
    font-weight: 800;
    color: #18191d;
    align-items: center;

    & > div {
      display: flex;
      gap: 1rem;
      height: max-content;
      width: 100%;
      padding: 3rem;
      cursor: pointer;
      transition: all ease-in-out 0.5s;

      &:hover {
        scale: 1.1;
      }

      & > .arrowDiv {
        & > svg {
          height: 2rem;
          width: 2rem;

          & > .dottedarrowgroup {
            fill: #44c2f4;
            animation: blinkArrow 0.5s linear infinite;
            animation-direction: alternate;

            @keyframes blinkArrow {
              0% {
                fill: #44c2f4;
              }
              100% {
                fill: white;
              }
            }
          }
        }
      }
    }
  }
}

.drawerItem {
  border: 0.5px solid #e5e5e5;
  border-radius: 20px;
  width: 35%;
  // min-width: 35%;
  min-width: 450px;
  height: 100%;
  padding: 0 2%;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 500px;

  & > :first-child {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  & > .addPlan {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2.5rem;
    height: 40%;
    font-weight: 700;
    font-size: 1.7rem;
    color: #18191d;
    opacity: 0.25;
    cursor: pointer;
    transition: all ease-in 0.4s;

    & img {
      height: 50%;
    }
    &:hover {
      scale: 1.1;
    }
  }

  & > div {
    width: 100%;
    height: 100%;
    padding-bottom: 2rem;

    & > .openPlanBtn {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 50%;
      min-height: 8%;
      max-height: 5rem;
      min-height: max-content;
      background: #44c2f4;
      border-radius: 25px;
      font-weight: 700;
      font-size: 1.5rem;
      color: white;
      padding: 3% 0;
      cursor: pointer;
      transition: all ease-in 0.4s;
      text-decoration: none;

      &:hover {
        box-shadow: 0 7px #6ccdf3;
      }
    }
  }

  & .content {
    cursor: pointer;
    padding: 2rem 0;
    border-bottom: 0.5px solid #e5e5e5;
    width: 100%;
    height: 17%;
    display: flex;
    gap: 1rem;
    position: relative;
    // justify-content: space-between;

    & > div {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    & > :first-child {
      align-items: center;
      width: 20%;
      border-radius: 50%;
      position: relative;

      & > img {
        // border-radius: 50%;
        // max-width: 100%;
        // height: 50px;
        transition: all ease 0.5s;
        width: 100%;
        position: absolute;
        border-radius: 50%;
        width: 4vw;
        height: 4vw;
        min-width: 4rem;
        min-height: 4rem;

        &:hover {
          scale: 1.05;
        }

        &::after {
          content: '';
          display: block;
          padding-top: 100%;
        }
      }
    }
    & > :nth-child(2) {
      width: 65%;

      & > :first-child {
        font-size: 1.5rem;
        font-weight: 700;
        color: #18191d;
      }

      & > :nth-child(2) {
        font-size: 1rem;
        font-weight: 400;
        color: #18191d;
      }
    }
    & > :nth-child(3) {
      width: 5%;
      cursor: pointer;
      position: relative;

      & > svg {
        font-size: 2rem;
      }

      & > .dotsdropdown {
        border: 0.5px solid #e5e5e5;
        border-radius: 15px;
        background: white;
        position: absolute;
        right: -8rem;
        bottom: -4.5rem;
        z-index: 1;

        & > div {
          white-space: nowrap;
          cursor: pointer;
          padding: 1rem 1.5rem;
          border-radius: 15px;
          transition: all ease-in 0.4s;
          font-weight: 700;
          color: #18191d;

          &:hover {
            background: rgba(125, 125, 125, 0.3);
          }
        }
      }
    }
  }
}
