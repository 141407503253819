.ads {
  position: relative;
  display: flex;
  width: 100%;
  padding-bottom: 9rem;
  // display: flex;
  flex-wrap: wrap;
  overflow: scroll;

  height: 100%;

  // base
  .flip {
    position: relative;
    > .front,
    > .back {
      display: block;
      transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
      transition-duration: 0.5s;
      transition-property: transform, opacity;
    }
    > .front {
      transform: rotateY(0deg);
    }
    > .back {
      position: absolute;
      opacity: 0;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%;
      transform: rotateY(-180deg);
    }
    &:hover {
      > .front {
        transform: rotateY(180deg);
        display: none;
      }
      > .back {
        opacity: 1;
        transform: rotateY(0deg);
      }
    }
    &.flip-vertical {
      > .back {
        transform: rotateX(-180deg);
      }
      &:hover {
        > .front {
          transform: rotateX(180deg);
        }
        > .back {
          transform: rotateX(0deg);
        }
      }
    }
  }

  // custom
  .flip {
    position: relative;
    display: inline-block;
    width: 33.333%;
    height: 231px;
    border: 1px solid #ebebeb;
    border-top: 0px solid;
    border-left: 0px solid;

    img {
      height: 57.56px;
    }
    > .front {
      display: block;

      color: white;
      width: inherit;
      background-size: cover !important;
      background-position: center !important;
      width: 100%;
      height: 100%;

      // background: #ffff;

      display: flex;
      align-items: center;
      justify-content: center;
    }
    > .back {
      display: block;
      padding-top: 2rem;
      color: white;
      width: inherit;
      background-size: cover !important;
      background-position: center !important;
      width: 100%;
      height: 100%;

      //   background: #ffff;

      display: flex;
      align-items: center;
      justify-content: space-around;
      flex-direction: column;

      p {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        display: flex;
        align-items: center;
        text-align: center;

        color: #212529;
      }
    }
  }
}

.innerAdsDash {
    height: 100vh;
    // padding-bottom: 11rem;
    overflow: scroll;
    .headerDash{
        padding: 2rem;
        padding-top: 0;
        padding-bottom: 0;
    }
    .contnetaddtop
    {
        padding: 2rem;
        height: 100%;
        padding-top: 0;
        // overflow: scroll;
    height: calc(100% - 49%);
    position: relative;
    top: -2.4rem;
    padding-top: 3px;

    }
  .cardDash {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .lefsiteadd {
      background: #ffffff;
      border: 0.5px solid #e7e7e7;
      border-radius:  15px;
      width: 49%;
      padding: 2rem;
    }
    .rightsiteadd {
      background: #ffffff;
      border: 0.5px solid #e7e7e7;
      border-radius: 15px;
      width: 49%;
      padding: 2rem;
      padding: 2rem;
      height: 62.7rem;
    }
    .labelSection {
      display: flex;
      align-items: center;
      margin-top:2.4rem;
      .payout {
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 17px;
        display: flex;
        align-items: center;

        color: #212529;
        width: 170px;
        height: 42px;

        background: rgba(237, 237, 237, 0.45);
        border: 0.5px solid #e7e7e7;
        border-radius: 25px;
        margin-right: 10px;
        justify-content: center;
        &:hover{
            transform: scale(1.1);
        }
      }
      .payoutLeft {
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 17px;
        display: flex;
        align-items: center;

        color: #ffffff;
        width: 170px;
        height: 42px;

        background: rgba(237, 237, 237, 0.45);
        border: 0.5px solid #e7e7e7;
        border-radius: 25px;
        justify-content: center;
        &:hover{
            transform: scale(1.1);
        }
      }
    }
    .borderCard {
      width: 100%;
      height: 300px;
      left: 847px;
      top: 526px;
      margin-top: 2.5rem;
      margin-bottom: 2rem;
      background-size: 100% 100% !important;
      background-repeat: no-repeat !important;
      background: #ffffff;
      border: 0.5px solid #e7e7e7;
      border-radius: 15px;
    }
    .innerContent {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 15px;
    }
    h4 {
      font-style: normal;
      font-weight: 600;
      font-size: 22px;
      line-height: 27px;
      display: flex;
      align-items: center;

      color: #212529;
      margin-bottom: 0;
    }
    h5 {
      font-style: normal;
      font-weight: 400;
      margin-bottom: 0;
      font-size: 16px;
      line-height: 20px;
      display: flex;
      align-items: center;

      color: #212529;
    }
  }
  .topheaderadd {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #ffffff;
    border: 0.5px solid #e7e7e7;
    border-radius:  15px;
    position: relative;
    position: relative;
    height: 9rem;
    padding: 0rem 5rem;
    top: -4.3rem;
  }
  .cardAdds {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    // width: 20%;
  }
  .website {
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 19px;
    /* or 127% */

    display: flex;
    margin-bottom: 0;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: #212529;
    width: 156px;
    height: 41.25px;
    background: rgba(237, 237, 237, 0.5);
    border-radius: 35px;
    &:hover{
        transform: scale(1.1);
    }
  }
  .signup {
    margin-bottom: 10px;
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 19px;
    /* or 127% */
    justify-content: center;
    display: flex;
    align-items: center;
    text-align: center;

    color: #ffffff;
    width: 156px;
    height: 41.25px;

    background: #063765;
    border-radius: 35px;
    &:hover{
        transform: scale(1.1);
    }
  }
  h1 {
    font-style: normal;
    font-weight: 500;
    font-size: 35px;
    line-height: 43px;
    display: flex;
    align-items: center;
    text-align: center;

    color: #212529;
  }
  h2 {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    display: flex;
    align-items: center;
    text-align: center;

    color: #212529;
    margin-bottom: 0;
  }
  .imageSectionadds {
    padding-bottom: 4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 20rem;
    position: relative;
    background-color: #063765;
    img {
      height: 99px;

    }
    .allbrands {
      position: absolute;
      top: 2rem;
      left:2rem;
      font-style: normal;
      font-weight: 700;
      font-size: 15px;
      line-height: 18px;
      display: flex;
      align-items: center;
      text-align: center;

      color: #ffffff;
      &:hover{
        transform: scale(1.1);
    }
      img{
        height: 10px;
        margin-right: 8px;
      }
    }
  }
}



.drawerAds{
  position: absolute;
  /* top: 0; */
  width: 40%;
  height: calc(100% - 112px);
  background-color: white;
  z-index: 99;
  right: 0;
  border-left: 0.5px solid #E7E7E7;
  border-top: 0.3px solid #E7E7E7;
  padding: 3rem;
  bottom: 0;
  .submit{
    font-style: normal;
font-weight: 700;
font-size: 20px;
line-height: 24px;
display: flex;
align-items: center;
text-align: center;

color: #FFFFFF;width: 100%;
height: 68px;
margin-bottom: 1rem;
justify-content: center;
background: #4B2A91;
border-radius: 10px;
&:hover{
  transform: scale(1.1);
}
  }
.labelbottom{
  position: absolute;
  bottom: 1rem;
  left: 2rem;
  width: calc(100% - 4rem)
}
  .back{
    font-style: normal;
font-weight: 700;
font-size: 20px;
line-height: 24px;
display: flex;
align-items: center;
text-align: center;
margin-bottom: 0;
color: #FFFFFF;width: 100%;
height: 68px;
justify-content: center;

background: #212224;
border-radius: 10px;
&:hover{
  transform: scale(1.1);
}
  }

  input{
    width: 100%;
height: 83px;
padding-left: 1rem;font-style: normal;
font-weight: 600;
font-size: 18px;
line-height: 22px;
display: flex;
align-items: center;

color: #212224;

// opacity: 0.25;

background: #FFFFFF;
border: 0.5px solid #E7E7E7;
border-radius: 10px;
  }
.leftcardlistdrawer
  {
    width: 100%;
height: 83px;

padding-left: 1rem;
padding-right: 1rem;
display: flex;
align-items: center;
justify-content: space-between;
background: #FFFFFF;
border: 0.5px solid #E7E7E7;
border-radius: 10px;
margin-bottom: 1rem;
cursor: pointer;
.textinside{
  font-style: normal;
font-weight: 500;
font-size: 15px;
line-height: 18px;
display: flex;
align-items: center;

color: #212224;

}
  }
  h1{
    font-style: normal;
font-weight: 600;
font-size: 24px;
line-height: 29px;
display: flex;
align-items: center;

color: #212224;
  }
  .topHeaderDrawerAds{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 3rem;
  }
}