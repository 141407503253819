.chatComponentSettings {
  background: white;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  border-top: 1px solid $vault-border-color;
  display: flex;
  flex-direction: column;
  z-index: 1;
  .myAccountContent {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 40px 40px 0 40px;
    position: relative;
    .settingTitle {
      font-size: 36px;
      font-weight: 600;
    }
    .subtext {
      font-size: 19px;
      margin-bottom: 30px;
      font-weight: 600;
      span {
        font-weight: 400;
        cursor: pointer;
        &:hover {
          font-weight: 600;
        }
      }
    }
    .settingsList {
      height: 0;
      flex: 1;
      > div {
        display: flex;
        flex-direction: column;
        .listItem {
          cursor: pointer;
          flex: 0 0 110px;
          border: 1px solid $vault-border-color;
          padding: 30px 40px;
          position: relative;
          display: flex;
          flex-direction: column;
          justify-content: center;
          margin-bottom: 20px;
          .icon {
            height: 24px;
            width: 24px;
            object-fit: contain;
            margin-right: 6px;
          }
          .mainText {
            font-size: 24px;
            font-weight: 600;
          }
          .subText {
            font-size: 17px;
          }
          .angle {
            position: absolute;
            right: 50px;
            font-size: 24px;
          }
          &.app {
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
            .icon {
              height: 50px;
              width: 50px;
              object-fit: contain;
              margin-right: 20px;
            }
            span {
              font-size: 24px;
              font-weight: 500;
            }
          }
          &:hover {
            background-color: whitesmoke;
          }
        }
        .earningDetail {
          border: 1px solid $vault-border-color;
          position: relative;
          display: flex;
          flex-direction: column;
          margin-bottom: 20px;
          .earningTitle {
            display: flex;
            padding: 10px 40px;
            border-bottom: 1px solid $vault-border-color;
            align-items: center;
            .icn {
              height: 30px;
              width: 30px;
            }
            span {
              font-size: 24px;
              font-weight: 500;
              margin-left: 10px;
            }
          }
          .earningContent {
            padding: 30px 40px;
            display: flex;
            flex-direction: column;
            .item {
              display: flex;
              justify-content: space-between;
              .label,
              .value {
                align-items: center;
                font-size: 20px;
                .icon {
                  height: 25px;
                  width: 25px;
                  margin-right: 5px;
                }
              }
              .value {
                font-weight: 600;
                text-transform: capitalize;
              }
              &:first-child {
                margin-bottom: 10px;
              }
            }
          }
        }
      }
    }
    .twofa {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 3% 0 12% 0;
      margin: auto 0;
      .twofaTitle {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 60px;
        .icon {
          width: 60px;
          height: 60px;
          margin-right: 20px;
        }
        span {
          font-size: 40px;
          font-weight: 600;
          color: $primary-color;
        }
      }
      .twofaSubtitle {
        text-align: center;
        margin: -30px 0 50px 0;
      }
      .qr-svg {
        width: 300px;
        height: 300px;
        margin: auto;
        margin-bottom: 60px;
      }
      .otp-input-wrapper {
        width: 70%;
        margin: 0 auto;
        height: 80px;
        display: flex;
        justify-content: space-between;
        margin-bottom: 60px;
        > div {
          flex: 0 0 15%;
          .otp-input {
            width: 100% !important;
            height: 100%;
            font-size: 20px;
          }
        }
      }

      .otp-input-wrapper-onhold {
        width: 100%;
        justify-content: space-between;
        > div {
          width: 20%;
          height: 80px;
          .otp-input {
            width: 100% !important;
            height: 100%;
            border: none;
            background: #ffffff;
            box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
            border-radius: 5px;
            font-size: 28px;
          }
        }
      }
      .inpBox {
        height: 70px;
        border: 1px solid $vault-border-color;
        display: flex;
        margin-bottom: 60px;
        .textVal {
          width: 0;
          flex: 1;
          padding: 0 30px;
          display: flex;
          align-items: center;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
        .btCopy {
          width: 69px;
          background: $vault-border-color;
          display: flex;
          justify-content: center;
          align-items: center;
          .copy {
            font-size: 24px;
          }
        }
      }
      .btns {
        height: 60px;
        width: 70%;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        .btnGoBack,
        .imDone {
          cursor: pointer;
          flex: 0 0 48%;
          border: 1px solid $primary-color;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 22px;
          font-weight: 600;
          position: relative;
          overflow: hidden;
          &::before {
            content: '';
            background-color: white;
            width: 100%;
            height: 40px;
            position: absolute;
            left: -100%;
            transform: rotateZ(45deg);
          }
          &:hover {
            border: 1px solid $primary-color;
            background: $primary-color;
            color: white;
            &::before {
              transition: all 0.4s ease;
              left: 100%;
            }
          }
        }
        .imDone {
          background: $primary-color;
          color: white;
        }
      }
    }
    .loadingAnim {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      display: flex;
      background: white;
    }
    .loading {
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      background: rgba(255, 255, 255, 0.562);
      display: flex;
    }
  }
  .tabs {
    height: 60px;
    border-top: 1px solid $vault-border-color;
    display: flex;
    margin-top: auto;
    .tabItm {
      flex: 0 0 25%;
      color: $primary-color;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 500;
      font-size: 18px;
      opacity: 0.7;
      &.true {
        border-top: 3px solid #000;
        font-size: 19px;
        font-weight: 600;
        opacity: 1;
      }
    }
  }
  .resetPassword {
    flex: 1;
    padding: 0 10%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    .resetTitle {
      font-size: 40px;
      font-weight: 600;
    }
    .subTitle {
      font-size: 20px;
      line-height: 2;
      margin: 20px 0;
    }
    .otp-input-wrapper {
      margin: 40px 0;
      height: 90px;
      display: flex;
      justify-content: space-between;
      > div {
        flex: 0 0 15%;
        .otp-input {
          width: 100% !important;
          height: 100%;
          font-size: 20px;
        }
      }
    }

    .otp-input-wrapper-onhold {
      width: 100%;
      justify-content: space-between;
      > div {
        width: 20%;
        height: 80px;
        .otp-input {
          width: 100% !important;
          height: 100%;
          border: none;
          background: #ffffff;
          box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
          border-radius: 5px;
          font-size: 28px;
        }
      }
    }
    .password {
      width: 80%;
      height: 70px;
      margin: 20px 0;
      border-radius: 0;
      border: 1px solid $vault-border-color;
      display: flex;
      position: relative;
      input {
        width: 100%;
        height: 100%;
        background: none;
        border: none;
        padding: 0 20px;
        font-size: 20px;
      }
      .eye {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 50px;
        height: 40%;
      }
    }
    .btnInit {
      width: 40%;
      height: 65px;
      border: 1px solid $vault-border-color;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 22px;
      position: relative;
      overflow: hidden;
      &::before {
        content: '';
        background-color: white;
        width: 100%;
        height: 40px;
        position: absolute;
        left: -100%;
        transform: rotateZ(45deg);
      }
      &:hover {
        border: 1px solid $primary-color;
        background: $primary-color;
        color: white;
        &::before {
          transition: all 0.4s ease;
          left: 100%;
        }
      }
    }
    .loading {
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      background: rgba(255, 255, 255, 0.562);
      display: flex;
    }
  }
}
