.chatLearn {
  flex: 1;
  z-index: 2;
  background: white;
  display: flex;
  flex-direction: column;
  .coinHead {
    cursor: pointer;
    margin: 20px 40px 20px 40px;
    border: 1px solid $border-color;
    display: flex;
    height: 100px;
    align-items: center;
    padding: 0 10px;
    .thumb {
      height: 80px;
      width: 80px;
    }
    .textContent {
      padding: 10px 15px;
      .pubTitle {
        font-size: 22px;
        font-weight: 600;
        text-transform: capitalize;
      }
      .pubDesc {
        font-size: 16px;
      }
    }
  }
  .bondsItemHead {
    margin: 10px 40px;
    cursor: pointer;
    border: 1px solid $vault-border-color;
    padding: 3% 4%;
    display: flex;
    flex-direction: column;
    .coinPrice,
    .labels,
    .rates {
      display: flex;
      justify-content: space-between;
      color: $primary-color;
    }
    .coinPrice {
      .img {
        height: 44px;
        display: flex;
        align-items: center;
        font-size: 23px;
        font-weight: bold;
        img {
          height: 80%;
          margin-right: 10px;
        }
      }
      .bondTitle {
        font-size: 23px;
        font-weight: bold;
      }
    }
    .labels {
      font-size: 14px;
    }
    .rates {
      margin-top: 15px;
      .ratesItem {
        .value {
          font-size: 21px;
          font-weight: bold;
        }
        .label {
          font-size: 14px;
        }
      }
    }
    &:hover {
      background: #00000006;
    }
  }
  .whatToDo {
    margin-top: 10px;
    border-top: 1px solid $border-color;
    flex: 1;
    padding: 20px 40px;
    display: flex;
    flex-direction: column;
    .whatDoTitle {
      font-size: 20px;
      font-weight: 600;
      color: $primary-color;
    }
    .appList {
      margin-top: 20px;
      height: 120px !important;
      > div {
        display: flex;
        .app {
          height: 65px;
          width: 65px;
          flex: 0 0 65px;
          display: flex;
          border-radius: 4px;
          margin-right: 20px;
          position: relative;
          img {
            height: 100%;
            width: 100%;
          }
          .label {
            position: absolute;
            display: none;
            top: 105%;
            width: 100%;
            text-align: center;
            justify-content: center;
          }
          &:hover {
            .label {
              display: flex;
            }
          }
        }
      }
    }
    .actions {
      margin-top: 20px;
      height: 50px !important;
      > div {
        display: flex;
        align-items: center;
        .btnAction {
          cursor: pointer;
          flex: 0 0 160px;
          height: 85%;
          border: 1px solid $border-color;
          margin-right: 20px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-weight: 500;
        }
      }
    }
    .actionsList {
      margin-top: 20px;
      height: 0 !important;
      flex: 1;
      > div {
        display: flex;
        flex-direction: column;
        .btnAction {
          cursor: pointer;
          flex: 0 0 85px;
          border: 1px solid $vault-border-color;
          padding: 30px 40px;
          position: relative;
          display: flex;
          flex-direction: column;
          justify-content: center;
          margin-bottom: 20px;
          font-size: 20px;
          font-weight: 600;
          .angle {
            position: absolute;
            right: 50px;
            font-size: 24px;
          }
        }
      }
    }
    .btnGoBack {
      cursor: pointer;
      margin-top: auto;
      margin-bottom: 30px;
      border: 1px solid $border-color;
      height: 65px;
      width: 32%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 22px;
      font-weight: 600;
      &:hover {
        background: whitesmoke;
      }
    }
  }
  .pubHead {
    margin: 24px 40px;
    font-weight: 500;
    text-decoration: underline;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 21px;
    .seeAll {
      font-size: 17px;
      text-decoration: none;
    }
  }
  .pubTabs {
    height: 45px;
    border-bottom: 1px solid $border-color;
    border-top: 1px solid $border-color;
    margin-top: 25px;
    display: flex;
    .tab {
      cursor: pointer;
      flex: 0 0 33.33%;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      &.true {
        font-weight: 600;
        &::before {
          content: '';
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          height: 2px;
          background: black;
        }
      }
    }
  }
  .categoryList {
    height: 0 !important;
    flex: 1;
    border-top: 1px solid $border-color;
    > div {
      padding-top: 20px;
      .categoryItm {
        cursor: pointer;
        margin: 0 40px 15px 40px;
        border: 1px solid $border-color;
        display: flex;
        height: 100px;
        align-items: center;
        padding: 0 10px;
        .thumb {
          height: 80px;
          width: 80px;
        }
        .textContent {
          padding: 10px 15px;
          .pubTitle {
            font-size: 22px;
            font-weight: 600;
            text-transform: capitalize;
          }
          .pubDesc {
            font-size: 16px;
          }
        }
      }
      .bondsItem {
        cursor: pointer;
        border: 1px solid $vault-border-color;
        margin: 20px 40px;
        padding: 3% 4%;
        display: flex;
        flex-direction: column;
        .coinPrice,
        .labels,
        .rates {
          display: flex;
          justify-content: space-between;
          color: $primary-color;
        }
        .coinPrice {
          .img {
            height: 44px;
            display: flex;
            align-items: center;
            font-size: 23px;
            font-weight: bold;
            img {
              height: 80%;
              margin-right: 10px;
            }
          }
          .bondTitle {
            font-size: 23px;
            font-weight: bold;
          }
        }
        .labels {
          font-size: 14px;
        }
        .rates {
          margin-top: 15px;
          .ratesItem {
            .value {
              font-size: 21px;
              font-weight: bold;
            }
            .label {
              font-size: 14px;
            }
          }
        }
        &:hover {
          background: #00000006;
        }
      }
    }
  }
  .categoryListDetail {
    margin: 10px;
    width: unset !important;
    border: 1px solid $border-color;
    .categoryItm {
      position: sticky;
      top: 10px;
      cursor: pointer;
      margin: 10px;
      margin-bottom: 0;
      border: 1px solid $border-color;
      border-bottom: none;
      display: flex;
      height: 100px;
      align-items: center;
      padding: 0 10px;
      z-index: 2;
      background: white;
      .thumb {
        height: 40px;
        width: 40px;
      }
      .textContent {
        padding: 10px 15px;
        .pubTitle {
          font-size: 20px;
          font-weight: 600;
          text-transform: capitalize;
        }
        .pubDesc {
          font-size: 15px;
        }
      }
    }
    .itmDetail {
      margin: 0 10px;
      padding: 0 15px;
      border: 1px $border-color;
      border-style: none solid;
      .listItem {
        margin-top: 6px;
        display: flex;
        justify-content: space-between;
        .key {
          width: 0;
          flex: 0 0 40%;
        }
        .value {
          width: 0;
          flex: 0 0 55%;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
    .btnsDispute {
      display: flex;
      height: 60px;
      margin: 10px;
      margin-top: 0;
      bottom: 10px;
      position: sticky;
      background: white;
      .btnItm {
        border: 1px solid $border-color;
        flex: 0 0 50%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
  .headCategory {
    margin: 10px;
    border: 1px solid $border-color;
    display: flex;
    flex: 0 0 80px;
    align-items: center;
    padding: 0 10px;
    .thumb {
      height: 60px;
      width: 60px;
    }
    .textContent {
      padding: 10px 15px;
      .pubTitle {
        font-size: 20px;
        font-weight: 600;
        text-transform: capitalize;
      }
      .pubDesc {
        line-height: 1;
        font-size: 14px;
      }
    }
  }
  .contentType {
    display: flex;
    flex-direction: column;
    padding: 0 10px;
    .contentTypeLabel {
      font-size: 14px;
      font-weight: 600;
    }
    .contentBtns {
      height: 40px;
      display: flex;
      justify-content: space-between;
      .btnCntntType {
        cursor: pointer;
        margin-top: 8px;
        border: 1px solid $border-color;
        flex: 0 0 49%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 16px;
        font-weight: 500;
        .cntntIcon {
          height: 17px;
          width: 17px;
          margin-right: 10px;
        }
        &.true {
          border-color: $primary-color;
        }
      }
    }
  }
  .total {
    margin: 15px 10px 15px 10px;
    font-weight: 600;
    font-size: 14px;
  }
  .categoryListVideo {
    margin: 10px 10px 25px 15px;
    width: unset !important;
    height: 0 !important;
    flex: 1;
    > div {
      display: flex;
      padding-bottom: 4px;
      .videoCard {
        border: 1px solid $border-color;
        flex: 0 0 320px;
        margin-right: 50px;
        .videoHolder {
          width: 100%;
          height: fit-content;
          position: relative;
          .videoHolderImg {
            width: 100%;
            height: auto;
          }
          .reactPlayer {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            width: 100% !important;
            height: 100% !important;
          }
        }
        .vidTitle {
          margin: 10px;
          font-size: 19px;
          font-weight: 600;
        }
        .desc {
          margin: 10px;
          font-size: 14px;
        }
      }
    }
  }
}
